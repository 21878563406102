import styled from 'styled-components';
import Input from 'antd/lib/input';
import { Color } from '@app/styles/themes/light/lightTheme';

export const FileUploadInput = styled(Input)``;

export const ImageContainer = styled.img`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid ${Color.gray};
    margin-top: 10px;
    width: 100%;
`;
