import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ItemHistoryTable } from '@app/components/tables/ItemHistoryTable/ItemHistoryTable';
import { getOwnedItemListAndHistory } from '@app/api/pageApi/member.api';
import { ItemCheckTable } from '@app/components/tables/ItemCheckTable/ItemCheckTable';
import { dateToUnixTimeBySeconds } from '@app/utils/utils';

const items = [
    {
        itemId: 1,
        rank: 1,
        count: 1
    },
    {
        itemId: 2,
        rank: 2,
        count: 2
    },
    {
        itemId: 3,
        rank: 3,
        count: 3
    },
    {
        itemId: 5,
        rank: 4,
        count: 4
    },
    {
        itemId: 6,
        rank: 5,
        count: 5
    },
    {
        itemId: 7,
        rank: 6,
        count: 6
    },
    {
        itemId: 8,
        rank: 7,
        count: 7
    },
    {
        itemId: 9,
        rank: 8,
        count: 8
    },
    {
        itemId: 10,
        rank: 3,
        count: 9
    }
];

const logs = [
    {
        id: 1,
        itemId: 1,
        rank: 1,
        count: 1,
        description: 'test',
        date: dateToUnixTimeBySeconds(new Date())
    },
    {
        id: 2,
        itemId: 2,
        rank: 2,
        count: 2,
        description: 'test',
        date: dateToUnixTimeBySeconds(new Date())
    },
    {
        id: 3,
        itemId: 3,
        rank: 3,
        count: 3,
        description: 'test',
        date: dateToUnixTimeBySeconds(new Date())
    }
];

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const ItemManagePage: React.FC = () => {
    const navigate = useNavigate();
    const {
        state: { memberIndex, nickName, phone, eMail }
    } = useLocation();

    const [isViewingItem, setIsViewingItem] = useState(true);

    useEffect(() => {
        const itemList = getOwnedItemListAndHistory(memberIndex);
    }, []);

    return (
        <div>
            <PageTitle>Item Manage</PageTitle>
            <Title>아이템 관리</Title>

            <Card
                style={{
                    width: '60%',
                    minWidth: '600px'
                }}
            >
                {/* 1. 소유내역 / 히스토리 버튼 */}
                <Row>
                    <Button
                        type={isViewingItem ? 'ghost' : 'default'}
                        style={{ marginRight: 5 }}
                        onClick={() => setIsViewingItem(true)}
                    >
                        소유내역
                    </Button>
                    <Button
                        type={isViewingItem ? 'default' : 'ghost'}
                        onClick={() => setIsViewingItem(false)}
                    >
                        히스토리
                    </Button>
                </Row>

                {/* 2. 소유내역 테이블 */}
                <div
                    style={{
                        display: isViewingItem ? 'block' : 'none'
                    }}
                >
                    <ItemCheckTable items={items} />
                </div>

                {/* 3. 히스토리 테이블 */}
                <div
                    style={{
                        display: isViewingItem ? 'none' : 'block'
                    }}
                >
                    <ItemHistoryTable logs={logs} />
                </div>

                {/* 4. 하단 버튼 */}
                <BottomContainer>
                    <Button type="ghost" onClick={() => navigate(-1)}>
                        목록으로
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default ItemManagePage;
