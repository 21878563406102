import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Color } from '@app/styles/themes/light/lightTheme';

export const Clipboard = styled(CopyToClipboard)`
    font-size: ${Color.black};
    font-weight: bold;

    &:hover {
        cursor: pointer; /* 원하는 커서 스타일로 변경하세요 */
    }
`;
