import React from 'react';
import { InputProps } from '../Input/Input';
import * as S from './FileUploadInput.styles';
import { InputRef } from 'antd';

export interface FileUploadInputProps extends InputProps {
    onChange?: any;
    ref?: any;
    url?: string;
    img?: string;
}

export const FileUploadInput = (
    { onChange, url, img, ...props }: any,
    ref: React.Ref<InputRef> | undefined
) => {
    return (
        <>
            <S.FileUploadInput
                type="file"
                accept="image/*"
                onChange={onChange}
                ref={ref}
                {...props}
            />
            {url ? <S.ImageContainer src={url} /> : null}
            {url === '' && img ? <S.ImageContainer src={img} /> : null}
        </>
    );
};
