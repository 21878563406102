import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, RaffleTableRow, RaffleImageTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { unixTimeToString } from '@app/utils/utils';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

const TouchableWrapper = styled.div`
    cursor: pointer;
`;

interface RaffleImageTableProps {
    images: RaffleImageTableRow[];
    onClick: any;
}

export const RaffleImageTable = ({ images, onClick }: RaffleImageTableProps) => {
    const navigate = useNavigate();

    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<RaffleTableRow> = [
        {
            title: '이미지 제목',
            dataIndex: 'title',
            width: '4%'
        },
        {
            title: '이미지 URL',
            dataIndex: 'imgUrl',
            width: '8%',
            render: (value, record) => {
                return <TouchableWrapper onClick={() => onClick(value)}>{value}</TouchableWrapper>;
            }
        },
        {
            title: '등록일',
            dataIndex: 'regTs',
            width: '6%',
            render: (value, record) => {
                return unixTimeToString(value);
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={images}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
