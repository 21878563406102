import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { Severity } from '@app/interfaces/interfaces';
import { defineColorBySeverity } from '@app/utils/utils';
import { Color } from '@app/styles/themes/light/lightTheme';
import { FONT_WEIGHT } from '@app/styles/themes/constants';

interface BtnProps {
    $severity?: Severity;
    $noStyle?: boolean;
}

export const Button = styled(AntButton)<BtnProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.5rem 1rem;

    ${props =>
        props.$noStyle &&
        css`
            width: unset;
            padding: 0;
            height: unset;
        `}

    &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
        color: var(--disabled-color);
    }
    ${props =>
        !props.danger &&
        css`
            ${props.$severity &&
            css`
                box-shadow: none;
                text-shadow: none;
                background: rgba(${defineColorBySeverity(props.$severity, true)}, 0.2);

                border-color: ${defineColorBySeverity(props.$severity)};

                color: ${defineColorBySeverity(props.$severity)};

                &:hover {
                    background: var(--background-color);

                    border-color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);

                    color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
                }

                &:focus {
                    background: var(--background-color);

                    border-color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);

                    color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
                }
            `}

            ${props.type === 'text' &&
            css`
                &:hover {
                    background: transparent;
                    color: var(--secondary-color);
                }
            `}

      ${props.type === 'ghost' &&
            css`
                &:hover {
                    color: var(--secondary-color);

                    border-color: var(--secondary-color);
                }
            `}

      ${props.type === 'primary' &&
            css`
                background: ${Color.tintMint};
                font-weight: bold;
                color: ${Color.white};
                border-color: var(--secondary-color);

                &:hover {
                    background: var(--secondary-color);
                    border-color: var(--secondary-color);
                }
            `}

      ${props.type === 'link' &&
            css`
                & span,
                a {
                    text-decoration: underline;
                }
            `};
        `}
`;

export const Prefix = styled.div`
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;
    width: 150px;
    font-weight: bold;
    margin-right: 1rem;
    padding-left: 0.2rem;
`;

export const Wrapper = styled.div`
    border 1px solid ${Color.gray};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    padding-top : 0.3rem;
    padding-bottom : 0.3rem;
    height: 50px;

    &:hover {
        border-color: ${Color.black};
    }
`;

export const DropdownButton = styled(AntButton)`
    background: transparent;
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
    color: ${Color.black};
    font-weight: ${FONT_WEIGHT.regular};
`;
