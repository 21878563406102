import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Color } from '@app/styles/themes/light/lightTheme';
import { MdOutlineCancel } from 'react-icons/md';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AiFillPlusCircle, AiTwotoneDelete } from 'react-icons/ai';
import { confirmAlert } from '@app/api/pageApi/notificationAlert.api';
import { entryItemsProps } from './RaffleEnrollment';
import { notificationController } from '@app/controllers/notificationController';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
    animalBaseItems,
    animalNumberToString,
    typeItemNumberToString,
    typeItemsMenu,
    typeOfflineRaffleRewardItems,
    typeOfflineRaffleRewardNumberToString,
    typeShoeGradeItems,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { TypeItemKey } from '@app/metaData/enum';

interface EntryEnrollModalProps {
    isModalOpen: boolean;
    onClick?: () => void;
    index: number;
    onClickAddPart?: () => void;
    deletePart?: () => void;
    onClickCancel?: () => void;
    onClickSave?: (EntryList: entryItemsProps[]) => void;
    isEditing?: boolean;
    entryItems?: entryItemsProps[];
}

interface EntryRewardEnrollModalProps {
    isModalOpen: boolean;
    onClick?: () => void;
    index: number;
    onClickAddPart?: () => void;
    deletePart?: () => void;
    onClickCancel?: () => void;
    onClickSave?: (EntryList: entryItemsProps[]) => void;
    isEditing?: boolean;
    entryItems?: entryItemsProps[];
}

interface EntryProps {
    index: number;
    entryItemId: number;
    onChangeEntryItemId: Dispatch<SetStateAction<number>> | undefined;
    entryItemType: number;
    onChangeEntryItemType: Dispatch<SetStateAction<number>> | undefined;
    entryItemCount: string;
    onChangeEntryItemCount: Dispatch<SetStateAction<string>> | undefined;
    deleteEntry: (idx: number) => void;
}

interface AddPartProps {
    onClick?: () => void;
}

const EntryBox = styled.div`
    width: 100%;
    border: 1px solid ${Color.gray};
    border-radius: 10px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const DeleteButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
`;

const ModalContainer = styled.div`
    width: 100%;
    border: 1px solid ${Color.darkGray};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
`;

const AddButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
`;

const Entry = ({
    index,
    entryItemId,
    onChangeEntryItemId,
    entryItemType,
    onChangeEntryItemType,
    entryItemCount,
    onChangeEntryItemCount,
    deleteEntry
}: EntryProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);
    const typeOfflineRaffleReward = useAppSelector(state => state.metaData.typeOfflineRaffleReward);

    return (
        <EntryBox>
            <Dropdown
                menu={{
                    items: typeItemsMenu(typeItem, onChangeEntryItemId as any)
                }}
                placement="bottom"
                arrow
            >
                <PrefixButton prefix="상품 카테고리">
                    {typeItemNumberToString(typeItem, entryItemId)}
                </PrefixButton>
            </Dropdown>

            <div
                style={{
                    display:
                        typeItemNumberToString(typeItem, entryItemId) === TypeItemKey.SHOE ||
                        typeItemNumberToString(typeItem, entryItemId) === TypeItemKey.ANIMAL ||
                        typeItemNumberToString(typeItem, entryItemId) ===
                            TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? 'block'
                            : 'none'
                }}
            >
                <Dropdown
                    menu={{
                        items:
                            typeItemNumberToString(typeItem, entryItemId) === TypeItemKey.SHOE
                                ? typeShoeGradeItems(typeShoeGrade, onChangeEntryItemType as any)
                                : typeItemNumberToString(typeItem, entryItemId) ===
                                  TypeItemKey.ANIMAL
                                ? animalBaseItems(animalBase, onChangeEntryItemType as any)
                                : typeItemNumberToString(typeItem, entryItemId) ===
                                  TypeItemKey.OFFLINE_RAFFLE_REWARD
                                ? typeOfflineRaffleRewardItems(
                                      typeOfflineRaffleReward,
                                      onChangeEntryItemType as any
                                  )
                                : []
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="상품 세부 타입">
                        {typeItemNumberToString(typeItem, entryItemId) === TypeItemKey.SHOE
                            ? typeShoeGradeNumberToString(typeShoeGrade, entryItemType)
                            : typeItemNumberToString(typeItem, entryItemId) === TypeItemKey.ANIMAL
                            ? animalNumberToString(entryItemType)
                            : typeItemNumberToString(typeItem, entryItemId) ===
                              TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? typeOfflineRaffleRewardNumberToString(
                                  typeOfflineRaffleReward,
                                  entryItemType
                              )
                            : null}
                    </PrefixButton>
                </Dropdown>
            </div>
            <PrefixInput
                prefix="상품 수량"
                type="number"
                onChange={onChangeEntryItemCount}
                value={entryItemCount === '0' ? '' : entryItemCount}
            />

            <DeleteButtonContainer>
                <div
                    style={{
                        cursor: 'pointer',
                        borderRadius: 7,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor: Color.pink,
                        padding: 3
                    }}
                    onClick={() => deleteEntry(index)}
                >
                    <AiTwotoneDelete size="23" color={Color.white} />
                </div>
            </DeleteButtonContainer>
        </EntryBox>
    );
};

const SaveButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center;
`;

export const AddPart = ({ onClick }: AddPartProps) => {
    return (
        <AddButtonContainer onClick={onClick}>
            <AiFillPlusCircle size="40" color={Color.mint} />
            <text
                style={{
                    marginLeft: 5,
                    fontSize: 16,
                    fontWeight: 'bold'
                }}
            >
                상품 추가하기
            </text>
        </AddButtonContainer>
    );
};

export const EntryEnrollModal = ({
    isModalOpen,
    index,
    onClickCancel,
    onClickAddPart,
    deletePart,
    onClickSave,
    isEditing,
    entryItems
}: EntryRewardEnrollModalProps) => {
    const [firstItemId, setFirstItemId] = useState<number>(0);
    const [firstItemType, setFirstItemType] = useState<number>(0);
    const [firstItemCount, setFirstItemCount] = useState<string>('0');

    const [secondItemId, setSecondItemId] = useState<number>(0);
    const [secondItemType, setSecondItemType] = useState<number>(0);
    const [secondItemCount, setSecondItemCount] = useState<string>('0');

    const [thirdItemId, setThirdItemId] = useState<number>(0);
    const [thirdItemType, setThirdItemType] = useState<number>(0);
    const [thirdItemCount, setThirdItemCount] = useState<string>('0');

    const [fourthItemId, setFourthItemId] = useState<number>(0);
    const [fourthItemType, setFourthItemType] = useState<number>(0);
    const [fourthItemCount, setFourthItemCount] = useState<string>('0');

    useEffect(() => {
        if (!entryItems || entryItems.length < 1) return;
        setFirstItemId(entryItems[0].id as number);
        setFirstItemType(entryItems[0].type as number);
        setFirstItemCount(entryItems[0].count.toString());

        if (entryItems.length < 2) return;
        setSecondItemId(entryItems[1].id as number);
        setSecondItemType(entryItems[1].type as number);
        setSecondItemCount(entryItems[1].count.toString());

        if (entryItems.length < 3) return;
        setThirdItemId(entryItems[2].id as number);
        setThirdItemType(entryItems[2].type as number);
        setThirdItemCount(entryItems[2].count.toString());

        if (entryItems.length < 4) return;
        setFourthItemId(entryItems[3].id as number);
        setFourthItemType(entryItems[3].type as number);
        setFourthItemCount(entryItems[3].count.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    const indexList = (index: number) => {
        const list = [];

        for (let i = 1; i <= index; i++) {
            list.push(i);
        }

        return list;
    };

    const matchEntryItemId = (index: number): number => {
        switch (index) {
            case 1:
                return firstItemId;
            case 2:
                return secondItemId;
            case 3:
                return thirdItemId;
            case 4:
                return fourthItemId;
        }
        return 0;
    };

    const matchEntryItemType = (index: number): number => {
        switch (index) {
            case 1:
                return firstItemType;
            case 2:
                return secondItemType;
            case 3:
                return thirdItemType;
            case 4:
                return fourthItemType;
        }
        return 0;
    };

    const matchEntryItemCount = (index: number): string => {
        switch (index) {
            case 1:
                return firstItemCount;
            case 2:
                return secondItemCount;
            case 3:
                return thirdItemCount;
            case 4:
                return fourthItemCount;
        }
        return '0';
    };

    const matchSetEntryItemId = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemId;
            case 2:
                return setSecondItemId;
            case 3:
                return setThirdItemId;
            case 4:
                return setFourthItemId;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetEntryItemType = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemType;
            case 2:
                return setSecondItemType;
            case 3:
                return setThirdItemType;
            case 4:
                return setFourthItemType;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetEntryItemCount = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemCount;
            case 2:
                return setSecondItemCount;
            case 3:
                return setThirdItemCount;
            case 4:
                return setFourthItemCount;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const deleteEntry = (idx: number) => {
        if (!deletePart) return;

        for (let i = idx; i < index; i++) {
            matchSetEntryItemCount(i)(matchEntryItemCount(i + 1));
            matchSetEntryItemType(i)(matchEntryItemType(i + 1));
        }
        matchEntryItemType(index) && matchSetEntryItemType(index)(0);
        matchEntryItemCount(index) && matchSetEntryItemCount(index)('0');

        deletePart();
    };

    const onClickCancelButton = async () => {
        if (!(await confirmAlert('래플 상품정보 입력을 정말로 취소하시겠습니까?'))) return;

        if (!isEditing)
            for (let i = 1; i <= index; i++) {
                matchSetEntryItemId(i)(0);
                matchSetEntryItemType(i)(0);
                matchSetEntryItemCount(i)('0');
            }

        onClickCancel && onClickCancel();
    };

    const clickSaveButton = async () => {
        const list = [];
        for (let i = 1; i <= index; i++) {
            if (+matchEntryItemId(i) === 0) {
                notificationController.error({
                    message: '상품 카테고리를 선택해주세요.'
                });
                return;
            }

            if (
                +matchEntryItemType(i) === 0 &&
                (typeItemNumberToString(+matchEntryItemType(i), +matchEntryItemId(i) as number) ===
                    TypeItemKey.SHOE ||
                    typeItemNumberToString(
                        +matchEntryItemType(i),
                        +matchEntryItemId(i) as number
                    ) === TypeItemKey.ANIMAL)
            ) {
                notificationController.error({
                    message: '상품 세부 타입을 선택해주세요.'
                });
                return;
            }

            if (+matchEntryItemCount(i) === 0) {
                notificationController.error({
                    message: '상품 수량을 입력해주세요.'
                });
                return;
            }

            list.push({
                id: matchEntryItemId(i),
                type: matchEntryItemType(i),
                count: Number(matchEntryItemCount(i))
            });
        }

        if (!(await confirmAlert('래플 상품정보를 저장하시겠습니까?'))) return;

        onClickSave && onClickSave(list);
    };

    return (
        <ModalContainer style={{ display: isModalOpen ? 'flex' : 'none' }}>
            <div
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    display: 'flex',
                    width: '100%'
                }}
            >
                <InCardTitle>응모 아이템 정보를 입력해주세요 !</InCardTitle>
                <MdOutlineCancel size="25" onClick={onClickCancelButton} />
            </div>
            <SubTitle>( * 래플 참가 시 필요한 아이템입니다 )</SubTitle>
            {indexList(index).map(i => (
                <Entry
                    key={i}
                    index={i}
                    entryItemId={matchEntryItemId(i)}
                    onChangeEntryItemId={matchSetEntryItemId(i)}
                    entryItemType={matchEntryItemType(i)}
                    onChangeEntryItemType={matchSetEntryItemType(i)}
                    entryItemCount={matchEntryItemCount(i)}
                    onChangeEntryItemCount={matchSetEntryItemCount(i)}
                    deleteEntry={(i: number) => deleteEntry(i)}
                />
            ))}
            <AddPart onClick={onClickAddPart as () => void} />
            <SaveButtonContainer>
                <Button
                    style={{
                        width: 200
                    }}
                    type="ghost"
                    onClick={clickSaveButton}
                >
                    저장
                </Button>
            </SaveButtonContainer>
        </ModalContainer>
    );
};
