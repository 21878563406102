import { Table } from 'components/common/Table/Table';
import {
    Pagination,
    getTableData,
    ItemManageTableRow,
    ItemDistributeInfoTableRow
} from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { distributionReasonToString, typeToString } from '@app/api/pageApi/member.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
    animalNumberToString,
    typeItemNumberToString,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { TypeItemKey } from '@app/metaData/enum';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface ItemDistributionHistoryTableProps {
    logs: ItemDistributeInfoTableRow[];
}

export const ItemDistributeInfoTable = ({ logs }: ItemDistributionHistoryTableProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<ItemDistributeInfoTableRow> = [
        {
            title: '#',
            dataIndex: 'id',
            width: '3%'
        },
        {
            title: '계정 ID',
            dataIndex: 'accountId',
            width: '9%'
        },
        {
            title: '이메일',
            dataIndex: 'email',
            width: '8%'
        },
        {
            title: '지급품목',
            dataIndex: 'itemId',
            width: '5%',
            render: (itemId: number) => {
                return typeItemNumberToString(typeItem, itemId);
            }
        },
        {
            title: '지급등급',
            dataIndex: 'itemType',
            width: '5%',
            render: (itemType: number, record: ItemDistributeInfoTableRow) => {
                switch (typeItemNumberToString(typeItem, record.itemId as number)) {
                    case TypeItemKey.ANIMAL:
                        return animalNumberToString(itemType);
                    case TypeItemKey.SHOE:
                        return typeShoeGradeNumberToString(typeShoeGrade, itemType);
                    default:
                        return 'NONE';
                }
            }
        },
        {
            title: '지급수량',
            dataIndex: 'itemCount',
            width: '5%'
        },
        {
            title: '사유',
            dataIndex: 'reason',
            width: '5%',
            render: (reason: number) => {
                return distributionReasonToString(reason);
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
