import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { distributionReasonToString, distributeItem } from '@app/api/pageApi/member.api';
import { ItemDistributionReason, TypeItemKey } from '@app/metaData/enum';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
    animalBaseItems,
    animalNumberToString,
    typeItemNumberToString,
    typeItemsMenu,
    typeShoeGradeItems,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const IndividualDistributionPage: React.FC = () => {
    const navigate = useNavigate();
    const [accountId, setAccountId] = useState<string>('');
    const gmAccountId = localStorage.getItem('gmAccountId');
    const [reason, setReason] = useState<number>(0);
    const [isPopup, setIsPopup] = useState<number>(0);
    const [itemId, setItemId] = useState<number>(0);
    const [itemType, setItemType] = useState<number>(0);
    const [itemCount, setItemCount] = useState<number>(0);
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);

    useEffect(() => {
        if (
            typeItemNumberToString(typeItem, itemId) !== TypeItemKey.SHOE ||
            typeItemNumberToString(typeItem, itemId) !== TypeItemKey.ANIMAL
        ) {
            setItemType(0);
        }
    }, [itemId, typeItem]);

    const reasonMenu = [
        {
            key: ItemDistributionReason.EVENT_REWARD,
            label: distributionReasonToString(ItemDistributionReason.EVENT_REWARD),
            onClick: () => {
                setReason(ItemDistributionReason.EVENT_REWARD);
            }
        },
        {
            key: ItemDistributionReason.ADMIN_DISTRIBUTION,
            label: distributionReasonToString(ItemDistributionReason.ADMIN_DISTRIBUTION),
            onClick: () => {
                setReason(ItemDistributionReason.ADMIN_DISTRIBUTION);
            }
        },
        {
            key: ItemDistributionReason.GIFT_REWARD,
            label: distributionReasonToString(ItemDistributionReason.GIFT_REWARD),
            onClick: () => {
                setReason(ItemDistributionReason.GIFT_REWARD);
            }
        }
    ];

    const isPopupMenu = [
        {
            key: 1,
            label: '있음',
            onClick: () => {
                setIsPopup(1);
            }
        },
        {
            key: 2,
            label: '없음',
            onClick: () => {
                setIsPopup(0);
            }
        }
    ];

    return (
        <div>
            <PageTitle>Item Distribution Page</PageTitle>
            <Title>개별 지급/회수</Title>
            <Card
                style={{
                    width: '60%',
                    minWidth: 900
                }}
            >
                {/* 1. 기본정보 입력란 */}
                <InCardTitle>1. 기본 정보</InCardTitle>
                <PrefixInput
                    prefix={`계정(account ID)`}
                    onChange={setAccountId}
                    type={`text`}
                    placeholder={`회원 계정을 입력해주세요`}
                    value={accountId}
                />

                <Dropdown
                    menu={{
                        items: reasonMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="지급 사유">
                        {distributionReasonToString(reason)}
                    </PrefixButton>
                </Dropdown>

                <Dropdown
                    menu={{
                        items: isPopupMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="팝업 메시지">{isPopup ? '있음' : '없음'}</PrefixButton>
                </Dropdown>

                <div style={{ height: 25 }} />

                {/* 2. 상품정보 입력란 */}
                <InCardTitle>2. 지급 아이템</InCardTitle>
                <Dropdown
                    menu={{
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        items: typeItemsMenu(typeItem, setItemId as any)
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="품목">
                        {typeItemNumberToString(typeItem, itemId)}
                    </PrefixButton>
                </Dropdown>

                <div
                    style={{
                        display:
                            typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE ||
                            typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL
                                ? 'block'
                                : 'none'
                    }}
                >
                    <Dropdown
                        menu={{
                            items:
                                typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE
                                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      typeShoeGradeItems(typeShoeGrade, setItemType as any)
                                    : typeItemNumberToString(typeItem, itemId) ===
                                      TypeItemKey.ANIMAL
                                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      animalBaseItems(animalBase, setItemType as any)
                                    : []
                        }}
                        placement="bottom"
                        arrow
                    >
                        <PrefixButton prefix="등급">
                            {typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE
                                ? typeShoeGradeNumberToString(typeShoeGrade, itemType)
                                : typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL
                                ? animalNumberToString(itemType)
                                : null}
                        </PrefixButton>
                    </Dropdown>
                </div>

                <PrefixInput
                    prefix={`수량`}
                    onChange={setItemCount}
                    type={`number`}
                    placeholder={`수량을 입력해주세요`}
                    value={itemCount > 0 ? itemCount : ''}
                />

                {/* 3. 등록하기 버튼 */}
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>

                    <Button
                        type="primary"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            distributeItem(
                                typeItem,
                                accountId,
                                gmAccountId as string,
                                reason,
                                isPopup,
                                itemId,
                                itemType,
                                itemCount,
                                navigate
                            );
                        }}
                    >
                        지급
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default IndividualDistributionPage;
