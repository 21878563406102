import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { themeObject } from './styles/themes/themeVariables';
import { S3 } from 'aws-sdk';
import { AWS_ACCESS_KEY, AWS_SECRET_ACCESS_KEY, AWS_REGION } from './consts/consts';
import { downloadJsonFile } from './api/firebaseApi/firebase.api';

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(state => state.theme.theme);
    useThemeWatcher();

    const s3 = new S3({
        accessKeyId: AWS_ACCESS_KEY,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_REGION
    });
    useEffect(() => {
        downloadJsonFile(s3, dispatch);
    }, []);

    return (
        <>
            <meta name="theme-color" content={themeObject[theme].primary} />
            <GlobalStyle />
            <HelmetProvider>
                <ConfigProvider locale={enUS}>
                    <AppRouter />
                </ConfigProvider>
            </HelmetProvider>
        </>
    );
};

export default App;
