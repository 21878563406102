import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'config/config';
import { Provider } from 'react-redux';
import { store } from '@app/store/store';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AWS from 'aws-sdk';

const queryClient = new QueryClient();

interface EventTarget {
    state?: 'activated';
}

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App />
            </Provider>
        </QueryClientProvider>
        ,
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if ((event.target as EventTarget).state === 'activated') window.location.reload();
            });
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
    }
}); // app will reload if new version of app is available

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
