import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';

import { Title } from '@app/components/common/Title/Title';

const VoucherManagePage: React.FC = () => {
    return (
        <div>
            <PageTitle>Voucher Manage</PageTitle>
            <Title>Voucher Manage</Title>
            <Card>개발 중</Card>
        </div>
    );
};
export default VoucherManagePage;
