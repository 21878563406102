import React, { useRef, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { RaffleRewardInfoTable } from '@app/components/tables/RaffleRewardInfoTable/RaffleRewardInfoTable';
import { TextAreaInput } from '@app/components/common/inputs/TextAreaInput/TextAreaInput';
import { FileUploadInput } from '@app/components/common/inputs/FileUploadInput/FileUploadInput';
import { RewardEnrollModal } from './RewardEnrollModal';
import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
import { initRaffle } from '@app/api/pageApi/raffle.api';
import { dateToUnixTimeBySeconds } from '@app/utils/utils';
import { EntryEnrollModal } from './EntryEnrollModal';
import { RaffleEntryInfoTable } from '@app/components/tables/RaffleEntryInfoTable/RaffleEntryInfoTable';
import { typeItemNumberToString } from '@app/metaData/metaData';
import { useAppSelector } from '@app/hooks/reduxHooks';

export interface RewardInfo {
    count?: string;
    id?: number;
    name?: string;
    rank?: number;
    type?: number;
    winningUserCount?: number;
}

export interface entryItemsProps {
    id: number;
    type: number;
    count: number;
}

export interface entryRewardItemsProps {
    id?: number;
    type?: number;
    count: string;
}

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const RaffleEnrollmentPage: React.FC = () => {
    const nowTs = new Date().getTime() / 1000;
    const navigate = useNavigate();
    const gmAccountId = localStorage.getItem('gmAccountId');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [start, setStart] = useState<string>('');
    const [end, setEnd] = useState<string>('');
    const [winningExpose, setWinningExpose] = useState<string>('');
    const imgRef = useRef(null);
    const coverImgRef = useRef(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [coverImageFile, setCoverImageFile] = useState<File | null>(null);
    const [imgUrl, setImgUrl] = useState<string>('');
    const [coverImgUrl, setCoverImgUrl] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEntryModalOpen, setIsEntryModalOpen] = useState<boolean>(false);
    const [i, setI] = useState<number>(1);
    const [i2, setI2] = useState<number>(1);
    const [rewardItems, setRewardItems] = useState<RewardInfo[]>([]);
    const [entryItems, setEntryItems] = useState<entryItemsProps[]>([]);
    const [entryRewardItems, setEntryRewardItems] = useState<entryRewardItemsProps[]>([]);

    const typeItem = useAppSelector(state => state.metaData.typeItem);

    const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setImageFile(fileList[0]);
            setImgUrl(URL.createObjectURL(fileList[0]));
        }

        if (!fileList || fileList.length === 0) {
            notificationController.error({ message: '이미지 파일이 없습니다.' });
            return;
        }
    };
    //
    const uploadCoverImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setCoverImageFile(fileList[0]);
            setCoverImgUrl(URL.createObjectURL(fileList[0]));
        }

        if (!fileList || fileList.length === 0) {
            notificationController.error({ message: '이미지 파일이 없습니다.' });
            return;
        }
    };
    //
    //
    const raiseIndex = (i: number) => {
        if (i < 4) {
            setI(i + 1);
        } else {
            return notificationController.error({
                message: '상품 등록은 최대 4개까지 가능합니다.'
            });
        }
    };
    const raiseIndex2 = (i2: number) => {
        if (i2 < 4) {
            setI2(i2 + 1);
        } else {
            return notificationController.error({
                message: '상품 등록은 최대 4개까지 가능합니다.'
            });
        }
    };
    //
    //
    const lowerIndex = (i: number) => {
        if (i > 1) {
            setI(i - 1);
        } else {
            return notificationController.error({
                message: '상품은 최소 1개 이상이어야 합니다.'
            });
        }
    };
    const lowerIndex2 = (i2: number) => {
        if (i2 > 1) {
            setI2(i2 - 1);
        } else {
            return notificationController.error({
                message: '상품은 최소 1개 이상이어야 합니다.'
            });
        }
    };
    //
    //
    const initIndex = (i: number) => {
        if (i !== 1) {
            setI(1);
        }
    };
    const initIndex2 = (i2: number) => {
        if (i2 !== 1) {
            setI2(1);
        }
    };
    //
    //
    const onClickCancel = (i: number) => {
        setIsModalOpen(false);
        if (rewardItems.length < 1) initIndex(i);
    };
    const onClickEntryCancel = (i2: number) => {
        setIsEntryModalOpen(false);
        if (entryItems.length < 1) initIndex2(i2);
    };
    //
    //
    const onClickRewardInfoSave = (
        rewardsList: RewardInfo[],
        entryRewardItems: entryRewardItemsProps[]
    ) => {
        setIsModalOpen(false);
        setRewardItems(rewardsList);
        setEntryRewardItems(entryRewardItems);
    };
    const onClickEntryInfoSave = (entryList: entryItemsProps[]) => {
        setIsEntryModalOpen(false);
        setEntryItems(entryList);
    };
    //
    //
    const clickSaveButton = async () => {
        if (!title) {
            notificationController.error({ message: '래플명을 입력해주세요!' });
            return;
        }
        if (!title) {
            notificationController.error({ message: '래플명을 입력해주세요!' });
            return;
        }
        if (rewardItems.length < 1) {
            notificationController.error({ message: '경품 아이템을 설정해주세요!' });
            return;
        }
        if (entryItems.length < 1) {
            notificationController.error({ message: '응모 아이템을 설정해주세요!' });
            return;
        }
        if (entryRewardItems.length < 1) {
            notificationController.error({ message: '응모 아이템을 설정해주세요!' });
            return;
        }
        if (!start) {
            notificationController.error({ message: '응모 시작 시간을 입력해주세요!' });
            return;
        }
        if (!end) {
            notificationController.error({ message: '응모 마감 시간을 입력해주세요!' });
            return;
        }
        if (!winningExpose) {
            notificationController.error({ message: '당첨 발표 시간을 입력해주세요! ' });
            return;
        }

        if (
            gmAccountId &&
            title &&
            description &&
            entryItems &&
            rewardItems &&
            entryRewardItems &&
            start &&
            end &&
            winningExpose
        ) {
            const startTs = dateToUnixTimeBySeconds(new Date(start));
            const endTs = dateToUnixTimeBySeconds(new Date(end));
            const winningExposeTs = dateToUnixTimeBySeconds(new Date(winningExpose));

            const dataToSend = {
                gmAccountId,
                title,
                description,
                entryItems,
                entryRewardItems,
                rewardItems,
                startTs,
                endTs,
                winningExposeTs
            };

            if (startTs < nowTs) {
                notificationController.error({
                    message: '래플 시작 시간은 현재보다 미래여야합니다.'
                });
                return;
            }

            if (startTs > endTs) {
                notificationController.error({
                    message: '래플 시작 시간보다 마감 시간이 더 미래여야합니다.'
                });
                return;
            }

            if (nowTs > endTs) {
                notificationController.error({
                    message: '래플 마감 시간은 현재보다 더 미래여야합니다.'
                });
                return;
            }

            const formData = new FormData();
            if (imageFile) formData.append('img', imageFile);
            if (coverImageFile) formData.append('coverImg', coverImageFile);
            formData.append('data', JSON.stringify(dataToSend));
            await initRaffle(navigate, formData);
        }
    };

    return (
        <div>
            <PageTitle>Raffle Manage</PageTitle>
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 10
                }}
            >
                <Title>새 래플 등록하기</Title>
            </div>
            <Card
                style={{
                    width: '60%',
                    minWidth: 900
                }}
            >
                {/* 1. 기본정보 입력란 */}
                <InCardTitle>1. 기본 정보</InCardTitle>
                <PrefixInput
                    prefix={`래플명`}
                    onChange={setTitle}
                    type={`text`}
                    placeholder={`래플명을 입력해주세요`}
                />
                <PrefixInput prefix={`응모 시작`} onChange={setStart} type={`datetime-local`} />
                <PrefixInput prefix={`응모 마감`} onChange={setEnd} type={`datetime-local`} />
                <PrefixInput
                    prefix={`당첨 발표`}
                    onChange={setWinningExpose}
                    type={`datetime-local`}
                />

                <div style={{ height: 25 }} />

                {/* 2. 상품정보 입력란 */}
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}
                >
                    <InCardTitle>2. 상품 정보</InCardTitle>
                    <Button
                        style={{
                            height: 40,
                            display: isModalOpen ? 'none' : 'block'
                        }}
                        onClick={() => setIsModalOpen(true)}
                    >
                        {rewardItems.length > 0 ? `수정하기` : `등록하기`}
                    </Button>
                </div>
                <div style={{ display: isModalOpen ? 'none' : 'block' }}>
                    <PrefixInput
                        prefix={`낙첨자 상품`}
                        value={`${
                            typeItemNumberToString(typeItem, entryRewardItems[0]?.id as number)
                                ? typeItemNumberToString(
                                      typeItem,
                                      entryRewardItems[0]?.id as number
                                  )
                                : ''
                        } ${entryRewardItems[0]?.count ? entryRewardItems[0]?.count : ''}`}
                    />
                    <RaffleRewardInfoTable rewardItems={rewardItems} />
                </div>

                {/* 2.2 상품 정보 등록 모달창 */}
                <RewardEnrollModal
                    isModalOpen={isModalOpen}
                    index={i}
                    onClickCancel={() => onClickCancel(i)}
                    onClickAddPart={() => raiseIndex(i)}
                    deletePart={() => lowerIndex(i)}
                    onClickSave={(rewardsList, entryRewardItems) => {
                        onClickRewardInfoSave(rewardsList, entryRewardItems);
                    }}
                    isEditing={rewardItems.length > 0 ? true : false}
                />

                <div style={{ height: 25 }} />

                {/* 3. 래플 이미지 */}
                <InCardTitle>3. 래플 이미지</InCardTitle>
                <SubTitle>( * 미등록 시, 이미지가 존재하지 않은 상태로 앱에 노출됩니다 )</SubTitle>
                <SubTitle>(1) 래플 메인 이미지</SubTitle>
                <FileUploadInput
                    ref={imgRef}
                    url={imgUrl}
                    onChange={uploadImage}
                    style={{ marginBottom: 10 }}
                />
                <SubTitle>(2) 래플 커버 이미지</SubTitle>
                <FileUploadInput ref={coverImgRef} url={coverImgUrl} onChange={uploadCoverImage} />
                <div style={{ height: 25, borderRadius: 40 }} />

                {/* 4. 상품 설명 */}
                <InCardTitle>4. 상품 설명</InCardTitle>
                <TextAreaInput onChange={e => setDescription(e.target.value)} />

                <div style={{ height: 25 }} />

                {/* 5. 응모 아이템 */}
                <InCardTitle>5. 응모 아이템</InCardTitle>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}
                >
                    <div>
                        <SubTitle> * 응모 아이템은 래플 응모 시, 사용되는 아이템입니다 </SubTitle>
                    </div>
                    <Button
                        style={{
                            height: 40,
                            display: isEntryModalOpen ? 'none' : 'block'
                        }}
                        onClick={() => setIsEntryModalOpen(true)}
                    >
                        {entryItems.length > 0 ? `수정하기` : `등록하기`}
                    </Button>
                </div>

                <div style={{ display: isEntryModalOpen ? 'none' : 'block' }}>
                    <RaffleEntryInfoTable entryItems={entryItems} />
                </div>
                <EntryEnrollModal
                    isModalOpen={isEntryModalOpen}
                    index={i2}
                    onClickCancel={() => onClickEntryCancel(i2)}
                    onClickAddPart={() => raiseIndex2(i2)}
                    deletePart={() => lowerIndex2(i2)}
                    onClickSave={entryList => onClickEntryInfoSave(entryList)}
                    isEditing={entryItems.length > 0 ? true : false}
                />
                <div style={{ height: 25 }} />

                {/* 6. 등록하기 버튼 */}
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => navigate('/raffle-manage/list')}
                    >
                        목록으로
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            width: 120
                        }}
                        onClick={() => clickSaveButton()}
                    >
                        저장
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default RaffleEnrollmentPage;
