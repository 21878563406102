import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, MemberListTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { unixTimeToString } from '@app/utils/utils';
import { Clipboard } from '@app/components/common/Clipboard/Clipboard';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface MemberListTableProps {
    members: MemberListTableRow[];
}

export const MemberListTable = ({ members }: MemberListTableProps) => {
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<MemberListTableRow> = [
        {
            title: '#',
            dataIndex: 'sn',
            width: '3%'
        },
        {
            title: '이메일',
            dataIndex: 'email',
            width: '8%'
        },
        {
            title: 'account ID',
            width: '8%',
            render: record => {
                return <Clipboard>{record.accountId}</Clipboard>;
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={members}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
