export enum TypeItemKey {
    ANIMAL = 'animal',
    AWM = 'awm',
    BUTTON = 'button',
    KLAYTN_USDT_MONEY = 'klaytnUsdtMoney',
    KLAYTN_USDT_POINT = 'klaytnUsdtPoint',
    MARBLE = 'marble',
    OFFLINE_RAFFLE_REWARD = 'offlineRaffleReward',
    POTION = 'potion',
    RAFFLE_TICKET = 'raffleTicket',
    SHOE = 'shoe',
    SHOE_POINT = 'shoePoint',
    ENERGY_DRINK = 'energyDrink',
    SHOE_SOLE = 'shoeSole',
    SPECIAL_RAFFLE_TICKET = 'specialRaffleTicket',
    SPECIAL_BUTTON = 'specialButton'
}

export enum TypeAnimal {
    PANDA = 'Panda',
    POLAR_BEAR = 'PolarBear',
    SEA_TURTLE = 'SeaTurtle',
    BENGAL_TIGER = 'BengalTiger',
    WHALE_SHARK = 'WhaleShark',
    SHABBY_BOX = 'ShabbyBox'
}

export enum DistributionOption {
    INDIVIDUAL = 1,
    GROUP = 2
}

export enum EventStatus {
    SCHEDULED = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    CANCELLED = 4
}

export enum ItemDistributionReason {
    EVENT_REWARD = 1,
    GIFT_REWARD = 2,
    ADMIN_DISTRIBUTION = 3,
    ETC = 4
}

export enum DistributionStatus {
    SCHEDULED = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    CANCELLED = 4
}

export enum KickReason {
    SERVER_UPDATE = 1,
    MAINTENANCE = 2,
    ACCOUNT_BAN = 3
}

export enum KickStatus {
    SCHEDULED = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    CANCELLED = 4
}

export enum KickOption {
    INDIVIDUAL = 1,
    GROUP = 2
}

export enum KickSearchOption {
    EMAIL = `email`,
    MOBILE = `mobile`,
    USER_NAME = `username`,
    EXTRA = `extra`
}
