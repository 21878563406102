import { Card } from '@app/components/common/Card/Card';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { Title } from '@app/components/common/Title/Title';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { TextAreaInput } from '@app/components/common/inputs/TextAreaInput/TextAreaInput';
import { ONE_HOUR } from '@app/consts/consts';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const PushNotificationEnrollPage = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState<number>(Math.floor(new Date().getTime() / 1000));
    const [title, setTitle] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [content, setContent] = useState<string>('');

    return (
        <div>
            <Title>푸시 알림 등록</Title>
            <Card
                style={{
                    width: '60%',
                    minWidth: 500
                }}
            >
                <PrefixInput
                    prefix="발송날짜"
                    type="datetime-local"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDate(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    value={new Date((date + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <PrefixInput prefix="푸시제목" type="text" onChange={setTitle} value={title} />
                <PrefixInput
                    prefix="푸시 클릭 시 이동 URL"
                    type="text"
                    onChange={setUrl}
                    value={url}
                />
                <div
                    style={{
                        height: 25
                    }}
                />
                <InCardTitle>푸시 내용</InCardTitle>
                <TextAreaInput onChange={e => setContent(e.target.value)} value={content} />
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        발송
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default PushNotificationEnrollPage;
