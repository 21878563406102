import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, RaffleEntryInfoTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { entryItemsProps } from '@app/pages/RafflePages/RaffleEnrollment';
import {
    animalNumberToString,
    typeItemNumberToString,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TypeItemKey } from '@app/metaData/enum';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface RaffleEntryInfoTableProps {
    entryItems: entryItemsProps[];
}

export const RaffleEntryInfoTable = ({ entryItems }: RaffleEntryInfoTableProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);

    const [tableData, setTableData] = useState<{
        data: unknown;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<RaffleEntryInfoTableRow> = [
        {
            title: '아이템',
            dataIndex: 'id',
            width: '5%',
            render: id => {
                return typeItemNumberToString(typeItem, id);
            }
        },
        {
            title: '세부 타입',
            dataIndex: 'type',
            width: '5%',
            render: (type, record) => {
                switch (typeItemNumberToString(typeItem, record.id as number)) {
                    case TypeItemKey.SHOE:
                        return typeShoeGradeNumberToString(typeShoeGrade, type as number);
                    case TypeItemKey.ANIMAL:
                        return animalNumberToString(type as number);
                    default:
                        return '-';
                }
            }
        },
        {
            title: '수량',
            dataIndex: 'count',
            width: '5%'
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={entryItems}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            pagination={false}
        />
    );
};
