import { Color } from '@app/styles/themes/light/lightTheme';
import styled from 'styled-components';

interface PrefixProps {
    isOpen: boolean;
}

export const Prefix = styled.div<PrefixProps>`
    transition: all 0.5s ease;
    visibility: visible;
    opacity: 1;
    width: 150px;
    font-weight: bold;

    ${props => !props.isOpen && 'opacity: 0; visibility: hidden'};
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
`;

export const SearchIconWrapper = styled.div`
    color: ${Color.white};
    background-color: ${Color.darkMint};
    border-radius: 10px;
    padding: 1rem;
    height: 3rem;
    width: 3rem;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    &:hover {
        cursor: pointer;
        background-color: ${Color.mint};
    }
`;
