import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, KickHistoryTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { calculateDurationByDays, unixTimeToString } from '@app/utils/utils';
import { Color } from '@app/styles/themes/light/lightTheme';
import { ImCancelCircle } from 'react-icons/im';
import { cancelKick } from '@app/api/pageApi/member.api';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface KickHistoryTableProps {
    logs: KickHistoryTableRow[];
}

export const KickHistoryTable = ({ logs }: KickHistoryTableProps) => {
    const gmAccountId = localStorage.getItem('gmAccountId') || '';
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<KickHistoryTableRow> = [
        {
            title: '#',
            dataIndex: 'sn',
            width: '3%'
        },
        {
            title: '닉네임',
            dataIndex: 'username',
            width: '4%'
        },
        {
            title: '핸드폰번호',
            dataIndex: 'mobile',
            width: '7%'
        },
        {
            title: '계정',
            dataIndex: 'email',
            width: '7%'
        },
        {
            title: '강퇴기간',
            width: `7%`,
            render: record => {
                const end = unixTimeToString(record.expireTs);
                const days = calculateDurationByDays(record.startDate, record.endDate);
                return ` ~ ${end}`;
            }
        },
        {
            title: '',
            width: '1%',
            render: record => {
                return (
                    <ImCancelCircle
                        color={Color.darkMint}
                        size={30}
                        onClick={() => {
                            cancelKick(record.sn, gmAccountId);
                        }}
                        cursor={'pointer'}
                    />
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
