import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { successAlert } from '@app/api/pageApi/notificationAlert.api';
import { notificationController } from '@app/controllers/notificationController';

// 1. 거리 증가
export const modifyRun = async (accountId: string, amount: number) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/runs/energy?accountId=${accountId}&amount=${amount}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        if (data.msg === 'success') {
            successAlert('거리 증가에 성공했습니다.');
        } else {
            notificationController.error({
                message: `거리 증가에 실패했습니다.`
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `거리 증가에 실패했습니다. : ${err.message}`
        });
    }
};

// 2. 아이템 부여
export const addItem = async (
    accountId: string,
    id: number,
    type: number,
    count: string,
    incr: number
) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/items?accountId=${accountId}&id=${id}&type=${type}&count=${count}&incr=${incr}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        if (data?.msg === 'success') {
            successAlert('아이템을 성공적으로 추가하였습니다.');
        } else {
            notificationController.error({
                message: '아이템 추가에 실패했습니다.'
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `아이템 추가에 실패했습니다. : ${err.message}`
        });
    }
};

// 3. 에너지 변경
export const modifyEnergy = async (accountId: string, amount: number) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/users/energy?accountId=${accountId}&amount=${amount}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        if (data.energyAmount) {
            successAlert(`다음만큼 에너지가 증가하였습니다. : ${data.energyAmount}`);
        } else {
            notificationController.error({ message: `에너지 변경에 실패했습니다.` });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `에너지 변경에 실패했습니다. : ${err.message}`
        });
    }
};

// 4. 내구도 조정
export const modifyDurability = async (accountId: string, amount: number) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/shoes/durability?accountId=${accountId}&amount=${amount}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        if (data.msg && data.msg === 'success') {
            successAlert('내구도 조정에 성공했습니다.');
        } else {
            notificationController.error({
                message: `내구도 조정에 실패했습니다.`
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `내구도 조정에 실패했습니다. : ${err.message}`
        });
    }
};

export const recordChange = async (distance: number) => {
    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/record-change`, {
            method: 'PUT',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                distance
            })
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = await res.json();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `기록 변경에 실패했습니다. : ${err.message}`
        });
    }
};

// 5. 운동화 추가
export const newShoe = async (accountId: string, grade: number, count: number) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/shoes?accountId=${accountId}&grade=${grade}&count=${count}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        if (data.msg && data.msg === 'success') {
            successAlert('신규 신발을 생성하였습니다.');
        } else {
            notificationController.error({
                message: `신규 신발 생성에 실패했습니다.`
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `신규 신발 생성에 실패했습니다. : ${err.message}`
        });
    }
};
