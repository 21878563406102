import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { kickReasonToString } from '@app/api/pageApi/member.api';
import { ADMIN_API_BASE_URL, API_KEY, ONE_HOUR } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, errorAlert, successAlert } from '../../api/pageApi/notificationAlert.api';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;
const IndividaulKickPage: React.FC = () => {
    const navigate = useNavigate();
    const [accountId, setAccountId] = useState<string>('');
    const [expireTs, setExpireTs] = useState<number>(Math.floor(new Date().getTime() / 1000));
    const [reason, setReason] = useState<number>(0);
    const gmAccountId = localStorage.getItem('gmAccountId') || '';

    const reasonMenu = [
        {
            key: 1,
            label: '접속 환경 업데이트',
            onClick: () => {
                setReason(1);
            }
        },
        {
            key: 2,
            label: '서버 점검',
            onClick: () => {
                setReason(2);
            }
        },
        {
            key: 3,
            label: '계정 정지',
            onClick: () => {
                setReason(3);
            }
        }
    ];

    const IndividualKick = async () => {
        const nowTs = Math.floor(new Date().getTime() / 1000);
        if (accountId === '') {
            notificationController.error({ message: '이메일을 입력해주세요' });
            return;
        }
        if (expireTs < nowTs) {
            notificationController.error({
                message: '강퇴 종료일을 현재 시간 이후로 설정해주세요'
            });
            return;
        }
        if (reason === 0) {
            notificationController.error({ message: '강퇴 사유를 선택해주세요' });
            return;
        }
        const message = `
        <strong>${accountId}</strong>을 강퇴합니다. <br>
        <strong>✓ 강퇴 종료일</strong>: ${new Date((expireTs + 9 * ONE_HOUR) * 1000)
            .toISOString()
            .slice(0, -8)} <br>
        <strong>✓ 강퇴 사유</strong>: ${kickReasonToString(reason)} <br>
        <strong>정말로 실행하시겠습니까?</strong>
        `;
        if (!(await confirmAlert(message))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/block`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                accountId,
                expireTs,
                cause: reason,
                gmAccountId
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다!');
        }
        if (data.msg) {
            notificationController.error({ message: data.msg });
        } else {
            successAlert('강퇴 등록이 완료되었습니다.');
            navigate(-1);
        }
    };

    return (
        <div>
            <PageTitle>Kick Manage</PageTitle>
            <Title>개별 강퇴</Title>
            <SubTitle>Manage Kick</SubTitle>
            <Card style={{ width: '60%' }}>
                <PrefixInput
                    prefix="계정 (account ID)"
                    placeholder="Account ID를 입력해주세요! "
                    type="text"
                    value={accountId}
                    onChange={setAccountId}
                />
                <PrefixInput
                    prefix="강퇴 종료일"
                    type="datetime-local"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setExpireTs(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    value={new Date((expireTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <Dropdown
                    menu={{
                        items: reasonMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="강퇴 사유">{kickReasonToString(reason)}</PrefixButton>
                </Dropdown>
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            IndividualKick();
                        }}
                    >
                        강퇴 등록
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default IndividaulKickPage;
