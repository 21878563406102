import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { Color } from '@app/styles/themes/light/lightTheme';
import { RaffleTable } from '@app/components/tables/RaffleTable/RaffleTable';
import styled from 'styled-components';
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { expiredAlert } from '@app/api/pageApi/notificationAlert.api';

const FilterButton = ({ menu, child, width }: { menu: any; child: string; width: string }) => {
    return (
        <Dropdown
            menu={{
                items: menu
            }}
        >
            <Button
                style={{
                    width: width,
                    marginRight: 10,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {child}
                </div>
                <FaChevronDown />
            </Button>
        </Dropdown>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SearchButton = styled(FaSearch)`
    color: black;
    size: 48px;
    background-color: ${Color.darkMint};
    padding: 13px;
    width: 75px;
    height: 50px;
    border-radius: 10px;
    color: ${Color.white};
    &:hover {
        cursor: pointer;
        background-color: ${Color.mint};
    }
    &:active {
        background-color: ${Color.darkMint};
    }
`;

const RaffleDrawPage: React.FC = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [raffles, setRaffles] = useState<any[]>([]);

    const getRaffleList = async () => {
        try {
            const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            });
            const data = await res.json();

            if (data.msg) {
                notificationController.error({ message: '정상적으로 조회하지 못 했습니다!' });
                return;
            }

            if (data?.msg === 'Token Expired') {
                await expiredAlert('로그인이 만료되었습니다. 다시 로그인해주세요. ', navigate);
            }

            setRaffles(data);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `래플리스트 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    useEffect(() => {
        getRaffleList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Row>
                <Title>참여﹒추첨 관리</Title>
                {/* <Button
                    type="ghost"
                    style={{ backgroundColor: Color.white, marginLeft: 10, height: 35 }}
                >
                    엑셀 다운로드
                </Button> */}
            </Row>
            <Card style={{ marginTop: 20 }}>
                <RaffleTable raffles={raffles} isDraw={true} />
            </Card>
        </div>
    );
};

export default RaffleDrawPage;
