import { confirmAlert, successAlert } from '@app/api/pageApi/notificationAlert.api';
import { getImgs, uploadImg } from '@app/api/pageApi/raffle.api';
import { Card } from '@app/components/common/Card/Card';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { Title } from '@app/components/common/Title/Title';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ExcelFileUploadInput } from '@app/components/common/inputs/FileUploadInput/ExcelFileUploadInput';
import { FileUploadInput } from '@app/components/common/inputs/FileUploadInput/FileUploadInput';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { RaffleImageTable } from '@app/components/tables/RaffleImageTable/RaffleImageTable';
import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const FlexEndRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
`;

const RaffleMultiEnrollmentPage: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectedExcelFiles, setSelectedExcelFiles] = useState<any>([]);
    const [url, setUrl] = useState<string>('');
    const imgRef = useRef('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imgTitle, setImgTitle] = useState<string>('');
    const [images, setImages] = useState<any>([]);
    const gmAccountId = localStorage.getItem('gmAccountId');
    const [previewImg, setPreviewImg] = useState<string>('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChangeExcelFileChange = (e: any) => {
        setSelectedExcelFiles(e.target.files);
    };

    const onClickExcelUpload = async () => {
        if (!selectedExcelFiles) {
            notificationController.error({ message: '업로드된 엑셀 파일이 없습니다.' });
            return;
        }

        if (!(await confirmAlert('정말로 등록하시겠습니까? '))) {
            return;
        }
        const formData = new FormData();
        formData.append('gmAccountId', gmAccountId as string);
        for (const selectedExcelFile of selectedExcelFiles) {
            formData.append('file', selectedExcelFile);
        }

        try {
            const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/multi`, {
                method: 'POST',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                },
                body: formData
            });
            const data = await res.json();
            if (!data.msg) {
                successAlert('성공적으로 등록했습니다!');
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `래플 업로드 중, 에러가 발생했습니다. : ${err}`
            });
        }
    };

    const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setImageFile(fileList[0]);
            setUrl(URL.createObjectURL(fileList[0]));
        }

        if (!fileList || fileList.length === 0) {
            notificationController.error({ message: '이미지 파일이 없습니다.' });
            return;
        }
    };

    const handleUploadImg = async () => {
        if (!imgTitle) {
            notificationController.error({ message: '이미지 제목을 입력해주세요!' });
            return;
        }
        if (!imageFile) {
            notificationController.error({ message: '이미지를 업로드 해주세요!' });
            return;
        }

        const formData = new FormData();

        formData.append('title', imgTitle);
        formData.append('img', imageFile);
        formData.append('gmAccountId', gmAccountId as string);
        await uploadImg(formData);
    };

    useEffect(() => {
        handleGetImages();
    }, []);

    const handleGetImages = async () => {
        const images = await getImgs();
        setImages(images);
    };

    return (
        <div>
            <Title>래플 2건 이상 등록하기</Title>
            <Card>
                <InCardTitle>엑셀 파일로 업로드하기</InCardTitle>
                <ExcelFileUploadInput
                    onChange={onChangeExcelFileChange}
                    title={'업로드하기'}
                    onClick={onClickExcelUpload}
                />

                <div
                    style={{
                        height: 50
                    }}
                />
                <InCardTitle>래플 이미지 등록하기</InCardTitle>
                <SubTitle>( * 미등록 시, 이미지가 존재하지 않은 상태로 앱에 노출됩니다 )</SubTitle>
                <PrefixInput prefix="이미지 이름" value={imgTitle} onChange={setImgTitle} />
                <FileUploadInput ref={imgRef} url={url} onChange={uploadImage} />
                <FlexEndRow>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 5
                        }}
                        onClick={() => handleUploadImg()}
                    >
                        등록
                    </Button>
                </FlexEndRow>
                <div
                    style={{
                        height: 50
                    }}
                />

                <InCardTitle>래플 업로드 히스토리</InCardTitle>
                <SubTitle>( 이미지 URL을 클릭하면 이미지를 확인할 수 있어요! )</SubTitle>

                <RaffleImageTable images={images} onClick={setPreviewImg} />
                <div style={{ display: previewImg ? 'block' : 'none' }}>
                    <SubTitle>( 미리보기 )</SubTitle>
                </div>
                <img
                    style={{
                        width: '100%',
                        borderRadius: 10
                    }}
                    src={previewImg}
                />
            </Card>
        </div>
    );
};

export default RaffleMultiEnrollmentPage;
