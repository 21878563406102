import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, errorAlert, successAlert } from '@app/api/pageApi/notificationAlert.api';
import { NavigateFunction } from 'react-router-dom';

export const getBannerInfo = async (isOngoing: number) => {
    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/banner?isOngoing=${isOngoing}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            }
        });

        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

export const initBanner = async (formData: FormData, navigate: NavigateFunction) => {
    try {
        if (!(await confirmAlert(`정말로, 배너를 등록하시겠습니까?`))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/banner`, {
            method: 'POST',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: formData
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data && data.message === 'success') {
            await successAlert('배너를 성공적으로 등록하였습니다.');
            navigate(-1);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

export const openBanner = async (bannerId: string, gmAccountId: string) => {
    try {
        if (!(await confirmAlert(`정말로, 배너를 노출하시겠습니까?`))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/banner/open`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                bannerId,
                gmAccountId
            })
        });
        const data = await res.json();
        if (data && data.message === 'success') {
            successAlert('배너를 성공적으로 노출하였습니다.');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

export const deleteBanner = async (
    bannerId: string,
    gmAccountId: string,
    navigate: NavigateFunction
) => {
    try {
        if (!(await confirmAlert(`정말로, 배너를 삭제하시겠습니까?`))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/banner`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                bannerId,
                gmAccountId
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data && data.message === 'success') {
            await successAlert('배너를 성공적으로 삭제하였습니다.');
            navigate(-1);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

export const setBannerPriority = async (
    gmAccountId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logs: any[],
    firstPriority: string,
    secondPriority: string,
    thirdPriority: string
) => {
    try {
        if (firstPriority === '') {
            notificationController.error({ message: '1순위를 선택해주세요.' });
            return;
        }
        if (logs.length >= 2 && secondPriority === '') {
            notificationController.error({ message: '2순위를 선택해주세요.' });
            return;
        }
        if (logs.length >= 3 && thirdPriority === '') {
            notificationController.error({ message: '3순위를 선택해주세요.' });
            return;
        }
        if (logs.length === 1 && (secondPriority !== '' || thirdPriority !== '')) {
            notificationController.error({ message: '1순위만 선택할 수 있습니다.' });
            return;
        }
        if (logs.length === 2 && thirdPriority !== '') {
            notificationController.error({ message: '2순위까지만 선택할 수 있습니다.' });
            return;
        }
        if (firstPriority !== '' && firstPriority === secondPriority) {
            notificationController.error({ message: '1순위와 2순위가 같습니다.' });
            return;
        }
        if (firstPriority !== '' && firstPriority === thirdPriority) {
            notificationController.error({ message: '1순위와 3순위가 같습니다.' });
            return;
        }
        if (secondPriority !== '' && secondPriority === thirdPriority) {
            notificationController.error({ message: '2순위와 3순위가 같습니다.' });
            return;
        }
        if (!(await confirmAlert(`배너 우선순위를 설정하시겠습니까?`))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/banner/priority`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                gmAccountId,
                firstPriority,
                secondPriority,
                thirdPriority
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data.message === 'success') {
            await successAlert('성공적으로 우선순위를 설정하였습니다.');
        }

        return false;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};
