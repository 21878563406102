import React from 'react';
import { Input, InputProps } from '../Input/Input';
import * as S from './PrefixInput.styles';

export interface PrefixInputProps extends InputProps {
    prefix: React.ReactNode;
    onChange?: any;
    isOpenPrefix?: boolean;
    type?: string;
    disabled?: boolean;
}

export const PrefixInput: React.FC<PrefixInputProps> = ({
    prefix,
    isOpenPrefix = true,
    ...props
}) => {
    const handleChange = (value: string) => {
        const valueWithoutSpaces = value.replace(/\s/g, '');
        props.onChange(valueWithoutSpaces);
    };
    return (
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Input
                prefix={<S.Prefix isOpen={isOpenPrefix}>{prefix}</S.Prefix>}
                {...props}
                onChange={e => {
                    if (props.onChange) {
                        handleChange(e.target.value);
                    }
                }}
                type={props.type}
                value={props.value}
            />
        </div>
    );
};
