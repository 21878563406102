import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Title } from '@app/components/common/Title/Title';
import { Card } from '@app/components/common/Card/Card';
import { PushNotificationHistoryTable } from '@app/components/tables/PushNotificationHistoryTable/PushNotificationHistoryTable';
import { useNavigate } from 'react-router-dom';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
`;

const logs = [
    {
        id: 1,
        title: '푸쉬 알림 발송',
        description: '푸쉬 알림 발송',
        date: Math.floor(new Date().getTime() / 1000),
        status: 1
    }
];

export const PushNotificationListPage = () => {
    const navigate = useNavigate();

    return (
        <div>
            <PageTitle>Push Notification</PageTitle>
            <Title>푸쉬 알림 리스트</Title>
            <Card>
                <PushNotificationHistoryTable logs={logs} />
                <BottomContainer>
                    <Button
                        type="ghost"
                        onClick={() => {
                            navigate('/push-notification-manage/enrollment');
                        }}
                    >
                        발송 등록
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default PushNotificationListPage;
