import {
    ADMIN_API_BASE_URL,
    AM_API_BASE_URL,
    API_KEY,
    LAUNCHER_API_BASE_URL
} from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import {
    DistributionOption,
    DistributionStatus,
    EventStatus,
    ItemDistributionReason,
    KickOption,
    KickReason,
    KickSearchOption,
    KickStatus,
    TypeItemKey
} from '@app/metaData/enum';
import { confirmAlert, errorAlert, successAlert } from './notificationAlert.api';
import { NavigateFunction } from 'react-router-dom';
import { typeItemNumberToString } from '@app/metaData/metaData';

/* 멤버리스트 페이지 조회 */
export const getMemberListWithEmail = async (email: string) => {
    try {
        const result = await fetch(`${ADMIN_API_BASE_URL}/admin/member?email=${email}`, {
            method: 'GET',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            }
        });
        const data = await result.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }
        if (data?.msg === 'Not Found [Account]') {
            notificationController.error({ message: '조건에 맞는 유저가 없습니다. ' });
            return;
        }

        return data;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

/* 소유 아이템 및 히스토리 조회*/
export const getOwnedItemListAndHistory = async (memberId: string) => {
    try {
        const result = await fetch(`${AM_API_BASE_URL}/member-list/${memberId}/owned-item-list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await result.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        return data;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

/* 아이템 지급 enum 파싱 */
export const typeToString = (type: DistributionOption) => {
    switch (type) {
        case DistributionOption.INDIVIDUAL:
            return '개별지급';
        case DistributionOption.GROUP:
            return '일괄지급';
        default:
            return '구분';
    }
};

export const statusToString = (status: EventStatus) => {
    switch (status) {
        case EventStatus.SCHEDULED:
            return '예정';
        case EventStatus.IN_PROGRESS:
            return '진행 중';
        case EventStatus.COMPLETED:
            return '완료';
        default:
            return '지급상태';
    }
};

export const namedStatusToString = (status: number, defaultStatus: string) => {
    switch (status) {
        case EventStatus.SCHEDULED:
            return '예정';
        case EventStatus.IN_PROGRESS:
            return '진행 중';
        case EventStatus.COMPLETED:
            return '완료';
        default:
            return defaultStatus;
    }
};

export const itemIdToString = (itemId: number) => {
    switch (itemId) {
        case 1:
            return '아이템1';
        case 2:
            return '아이템2';
        case 3:
            return '아이템3';
        default:
            return '지급품목';
    }
};

export const rankToString = (rank: number) => {
    switch (rank) {
        case 1:
            return '1등급';
        case 2:
            return '2등급';
        case 3:
            return '3등급';
        default:
            return '지급등급';
    }
};

export const distributionReasonToString = (reason: ItemDistributionReason) => {
    switch (reason) {
        case ItemDistributionReason.EVENT_REWARD:
            return '이벤트 리워드';
        case ItemDistributionReason.GIFT_REWARD:
            return '선물 리워드';
        case ItemDistributionReason.ADMIN_DISTRIBUTION:
            return '어드민 지급';
        case ItemDistributionReason.ETC:
            return '기타';
        default:
            return '지급사유';
    }
};

export const distributionStatusToString = (status: number) => {
    switch (status) {
        case DistributionStatus.SCHEDULED:
            return '예정';
        case DistributionStatus.COMPLETED:
            return '완료';
        case DistributionStatus.CANCELLED:
            return '취소';
        default:
            return '지급상태';
    }
};

export const kickReasonToString = (reason: number) => {
    switch (reason) {
        case KickReason.SERVER_UPDATE:
            return '서버 환경 업데이트';
        case KickReason.MAINTENANCE:
            return '서버 점검';
        case KickReason.ACCOUNT_BAN:
            return '계정 정지';
        default:
            return '강퇴사유';
    }
};

export const kickTypeToString = (kickType: number) => {
    switch (kickType) {
        case KickOption.INDIVIDUAL:
            return '개별강퇴';
        case KickOption.GROUP:
            return '일괄강퇴';
        default:
            return '구분';
    }
};

export const kickStatusToString = (kickStatus: number) => {
    switch (kickStatus) {
        case KickStatus.SCHEDULED:
            return '예정';
        case KickStatus.COMPLETED:
            return '완료';
        case KickStatus.CANCELLED:
            return '취소';
        default:
            return '강퇴상태';
    }
};

export const kickSearchOptionToString = (kickSearchOption: string) => {
    switch (kickSearchOption) {
        case KickSearchOption.EMAIL:
            return `이메일`;
        case KickSearchOption.USER_NAME:
            return `닉네임`;
        case KickSearchOption.MOBILE:
            return `핸드폰 번호`;
    }

    return `검색 옵션`;
};

/* 아이템 지급/회수 History */
export const getItemDistributeInfo = async (
    distributeOption: number,
    itemId: number,
    itemType: number,
    reason: number,
    searchWord: string
) => {
    try {
        const result = await fetch(
            `${ADMIN_API_BASE_URL}/admin/item-distribute-info?distributeOption=${distributeOption}&itemId=${itemId}&itemType=${itemType}&reason=${reason}&searchWord=${searchWord}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await result.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: err.message });
    }
};

export const distributeItem = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeItem: any,
    accountId: string,
    gmAccountId: string,
    reason: ItemDistributionReason,
    isPopup: number,
    itemId: number,
    itemType: number,
    itemCount: number,
    navigate: NavigateFunction
) => {
    if (!accountId || accountId === '') {
        notificationController.error({ message: 'account ID를 입력해주세요 !' });
        return;
    }
    if (!reason) {
        notificationController.error({ message: '사유를 선택해주세요 !' });
        return;
    }
    if (itemId === 0) {
        notificationController.error({ message: '아이템 아이디를 선택해주세요 !' });
        return;
    }
    if (
        (typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL ||
            typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE) &&
        itemType === 0
    ) {
        notificationController.error({ message: '아이템타입을 선택해주세요 !' });
        return;
    }
    if (itemCount < 1) {
        notificationController.error({ message: '수량을 입력해 주세요 !' });
        return;
    }

    if (!(await confirmAlert('아이템을 정말 지급하시겠습니까?'))) return;

    const res = await fetch(`${ADMIN_API_BASE_URL}/admin/item-distribute`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-KEY': API_KEY as string,
            Token: localStorage.getItem('token') as string
        },
        body: JSON.stringify({
            accountId,
            gmAccountId,
            reason,
            isPopup,
            itemId,
            itemType,
            itemCount
        })
    });

    const data = await res.json();

    if (data?.msg === 'Token Expired') {
        localStorage.removeItem('token');
        errorAlert('토큰이 만료되었습니다! ');
    }

    if (data.message && data.message) {
        successAlert('아이템 지급에 성공했습니다.');
        navigate(-1);
    }
};

/* 강퇴 취소 */
export const cancelKick = async (sn: number, gmAccountId: string) => {
    if (!(await confirmAlert('강퇴를 정말로 삭제하시겠습니까?'))) return;
    const res = await fetch(`${ADMIN_API_BASE_URL}/admin/block/cancel`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'API-KEY': API_KEY as string,
            Token: localStorage.getItem('token') as string
        },
        body: JSON.stringify({
            sn,
            gmAccountId
        })
    });
    const data = await res.json();

    if (data?.msg === 'Token Expired') {
        localStorage.removeItem('token');
        errorAlert('토큰이 만료되었습니다! ');
    }

    if (data.message === 'success') {
        successAlert('강퇴 취소가 완료되었습니다.');
    } else {
        notificationController.error({ message: data.message });
    }
};

/* 강퇴 일괄 취소 */
export const cancelKickAll = async (gmAccountId: string) => {
    const res = await fetch(`${ADMIN_API_BASE_URL}/admin/kick/cancel/all`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'API-KEY': API_KEY as string
        },
        body: JSON.stringify({
            gmAccountId
        })
    });
    const data = await res.json();

    if (data?.msg === 'Token Expired') {
        localStorage.removeItem('token');
        errorAlert('토큰이 만료되었습니다! ');
    }

    if (data.message === 'success') {
        successAlert('일괄 강퇴 삭제가 완료되었습니다.');
    } else {
        notificationController.error({ message: data.message });
    }
};

/* 서버점검 */
export const getMaintenanceInfo = async () => {
    const res = await fetch(`${LAUNCHER_API_BASE_URL}/launcher/getConfigs`, {
        method: 'GET',
        headers: {
            'API-KEY': API_KEY as string,
            Token: localStorage.getItem('token') as string
        }
    });
    const data = await res.json();

    return data;
};

export const maintenance = async (
    project: string,
    serverType: string,
    isMaintenance: number,
    maintenanceMsg: string,
    updateWaitMsg: string,
    updateAvailableMsg: string
) => {
    if (serverType === '') {
        notificationController.error({ message: '서버타입을 선택해주세요. ' });
        return;
    }

    if (isMaintenance === -1) {
        notificationController.error({ message: '서버점검인지 해제인지 선택해주세요. ' });
        return;
    }

    if (!(await confirmAlert(`정말로 ${isMaintenance ? `서버점검 처리` : `해제`}하시겠습니까?`)))
        return;

    const res = await fetch(`${LAUNCHER_API_BASE_URL}/launcher/setConfigs`, {
        method: 'PUT',
        headers: {
            'API-KEY': API_KEY as string,
            Token: localStorage.getItem('token') as string
        },
        body: JSON.stringify({
            project,
            serverType,
            isMaintenance,
            maintenanceMsg: isMaintenance ? maintenanceMsg : '',
            updateWaitMsg: updateWaitMsg ? updateWaitMsg : '',
            updateAvailableMsg
        })
    });
    const data = await res.json();

    if (data?.IsMaintenance !== undefined && data?.IsMaintenance === isMaintenance) {
        successAlert(`성공적으로 ${isMaintenance ? `서버점검 처리` : `해제`}하였습니다!`);
    }
};
