import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/AuthPages/LoginPage';
import SignUpPage from '@app/pages/AuthPages/SignUpPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
// import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import RaffleListPage from '@app/pages/RafflePages/RaffleListPage';
import VoucherManagePage from '@app/pages/VoucherPages/VoucherManagePage';
import RaffleDrawPage from '@app/pages/RafflePages/RaffleDrawPage';
import ItemDistributionPage from '@app/pages/MemberPages/ItemDistributionPage';
import MemberListPage from '@app/pages/MemberPages/MemberListPage';
import RaffleEnrollmentPage from '@app/pages/RafflePages/RaffleEnrollment';
import RaffleDrawManagePage from '@app/pages/RafflePages/RaffleDrawManagePage';
import ItemManagePage from '@app/pages/MemberPages/ItemManagePage';
import KickManagePage from '@app/pages/MemberPages/KickManagePage';
import MemberListForItemPage from '@app/pages/MemberPages/MemberListForItemPage';
import IndividualDistributionPage from '@app/pages/MemberPages/IndividualDistributionPage';
import GroupDistributionPage from '@app/pages/MemberPages/GroupDistributionPage';
import GroupKickPage from '@app/pages/MemberPages/GroupKickPage';
import IndividualKickPage from '@app/pages/MemberPages/IndividualKickPage';
import PushNotificationListPage from '@app/pages/PushNotificationPages/PushNotificationListPage';
import BannerListPage from '@app/pages/BannerManagePages/BannerListPage';
import PriorityManagePage from '@app/pages/BannerManagePages/PriorityManagePage';
import PushNotificationEnrollPage from '@app/pages/PushNotificationPages/PushNotificationEnrollPage';
import BannerEnrollPage from '@app/pages/BannerManagePages/BannerEnrollPage';
import PushNotificationDetailPage from '@app/pages/PushNotificationPages/PushNotificationDetailPage';
import BannerDetailPage from '@app/pages/BannerManagePages/BannerDetailPage';
import RaffleManagePage from '@app/pages/RafflePages/RaffleManagePage';
import CheatKeyPage from '@app/pages/TestPages/CheatKeyPage';
import RequireAuth from './RequireAuth';
import MaintenancePage from '@app/pages/MemberPages/MaintenancePage';
import RaffleOfflineRewardPage from '@app/pages/RafflePages/RaffleOfflineRewardPage';
import RaffleMultiEnrollmentPage from '@app/pages/RafflePages/RaffleMultiEnrollment';

export const MAIN_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

// Raffle
export const RAFFLE_LIST_PATH = '/raffle-manage/list';
export const RAFFLE_ENROLLMENT_PATH = '/raffle-manage/list/enrollment';
export const RAFFLE_MULTI_ENROLLMENT_PATH = '/raffle-manage/list/enrollment/multi';
export const RAFFLE_DRAW_PATH = '/raffle-manage/draw';
export const RAFFLE_DRAW_MANAGE_PATH = '/raffle-manage/draw/:raffleIndex';
export const RAFFLE_MANAGE_PATH = '/raffle-manage/:raffleIndex';
export const RAFFLE_OFFLINE_REWARD_PATH = '/raffle-manage/offline-reward';

// Member
export const MEMBER_LIST_PATH = '/member-manage/list';
export const ITEM_PATH = '/member-manage/item-manage';
export const ITEM_MANAGE_PATH = '/member-manage/item-manage/:memberIndex';
export const ITEM_DISTRIBUTION_PATH = '/member-manage/item-distribution';
export const INDIVIDUAL_DISTRIBUTION_PATH = '/member-manage/item-distribution/individual';
export const GROUP_DISTRIBUTION_PATH = '/member-manage/item-distribution/group';
export const KICK_PATH = '/member-manage/kick';
export const INDIVIDUAL_KICK_PATH = '/member-manage/kick/individual';
export const GROUP_KICK_PATH = '/member-manage/kick/group';
export const MAINTENANCE_PATH = '/member-manage/maintenance';

//Voucher
export const VOUCHER_MANAGE_PATH = '/voucher-manage';

// PushNotification
export const PUSH_NOTIFICATION_LIST_PATH = '/push-notification-manage/list';
export const PUSH_NOTIFICATION_ENROLL_PATH = '/push-notification-manage/enrollment';
export const PUSH_NOTIFICATION_DETAIL_PATH =
    '/push-notification-manage/detail/:pushNotificationIndex';

// Banner
export const BANNER_LIST_PATH = '/banner-manage/list';
export const BANNER_ENROLLMENT_PATH = '/banner-manage/list/banner-enrollment';
export const BANNER_DETAIL_PATH = '/banner-manage/list/detail/:bannerIndex';
export const PRIORITY_MANAGE_PATH = '/banner-manage/priority';

// Test
export const CHEAT_KEY_PATH = '/cheat-key';

// Raffle
const RaffleList = withLoading(RaffleListPage);
const RaffleEnrollment = withLoading(RaffleEnrollmentPage);
const RaffleMultiEnrollment = withLoading(RaffleMultiEnrollmentPage);
const RaffleDraw = withLoading(RaffleDrawPage);
const RaffleDrawManage = withLoading(RaffleDrawManagePage);
const RaffleManage = withLoading(RaffleManagePage);
const RaffleOfflineReward = withLoading(RaffleOfflineRewardPage);

// Member
const MemberList = withLoading(MemberListPage);
const ItemManage = withLoading(ItemManagePage);
const Item = withLoading(MemberListForItemPage);
const ItemDistribution = withLoading(ItemDistributionPage);
const IndividualDistribution = withLoading(IndividualDistributionPage);
const GroupDistribution = withLoading(GroupDistributionPage);
const Kick = withLoading(KickManagePage);
const IndividualKick = withLoading(IndividualKickPage);
const GroupKick = withLoading(GroupKickPage);
const Maintenance = withLoading(MaintenancePage);

// Voucher
const VoucherManage = withLoading(VoucherManagePage);

// PushNotification
const PushNotificationList = withLoading(PushNotificationListPage);
const PushNotificationEnroll = withLoading(PushNotificationEnrollPage);
const PushNotificationDetail = withLoading(PushNotificationDetailPage);

// Banner
const BannerList = withLoading(BannerListPage);
const BannerEnroll = withLoading(BannerEnrollPage);
const BannerDetail = withLoading(BannerDetailPage);

// Test
const CheatKey = withLoading(CheatKeyPage);

const PriorityManage = withLoading(PriorityManagePage);

const AuthLayoutFallback = withLoading(AuthLayout);

export const AppRouter: React.FC = () => {
    const protectedLayout = (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route path={MAIN_PATH} element={protectedLayout}>
                    <Route index element={<RaffleList />} />
                    <Route path={RAFFLE_LIST_PATH} element={<RaffleList />} />
                    <Route path={RAFFLE_ENROLLMENT_PATH} element={<RaffleEnrollment />} />
                    <Route
                        path={RAFFLE_MULTI_ENROLLMENT_PATH}
                        element={<RaffleMultiEnrollment />}
                    />
                    <Route path={RAFFLE_DRAW_PATH} element={<RaffleDraw />} />
                    <Route path={RAFFLE_DRAW_MANAGE_PATH} element={<RaffleDrawManage />} />
                    <Route path={RAFFLE_MANAGE_PATH} element={<RaffleManage />} />
                    <Route path={RAFFLE_OFFLINE_REWARD_PATH} element={<RaffleOfflineReward />} />

                    <Route path={MEMBER_LIST_PATH} element={<MemberList />} />
                    <Route path={ITEM_PATH} element={<Item />} />
                    <Route path={ITEM_MANAGE_PATH} element={<ItemManage />} />
                    <Route path={ITEM_DISTRIBUTION_PATH} element={<ItemDistribution />} />
                    <Route
                        path={INDIVIDUAL_DISTRIBUTION_PATH}
                        element={<IndividualDistribution />}
                    />
                    <Route path={GROUP_DISTRIBUTION_PATH} element={<GroupDistribution />} />
                    <Route path={KICK_PATH} element={<Kick />} />
                    <Route path={INDIVIDUAL_KICK_PATH} element={<IndividualKick />} />
                    <Route path={GROUP_KICK_PATH} element={<GroupKick />} />
                    <Route path={MAINTENANCE_PATH} element={<Maintenance />} />

                    <Route path={VOUCHER_MANAGE_PATH} element={<VoucherManage />} />

                    <Route path={PUSH_NOTIFICATION_LIST_PATH} element={<PushNotificationList />} />
                    <Route
                        path={PUSH_NOTIFICATION_ENROLL_PATH}
                        element={<PushNotificationEnroll />}
                    />
                    <Route
                        path={PUSH_NOTIFICATION_DETAIL_PATH}
                        element={<PushNotificationDetail />}
                    />

                    <Route path={BANNER_LIST_PATH} element={<BannerList />} />
                    <Route path={BANNER_ENROLLMENT_PATH} element={<BannerEnroll />} />
                    <Route path={BANNER_DETAIL_PATH} element={<BannerDetail />} />

                    <Route path={PRIORITY_MANAGE_PATH} element={<PriorityManage />} />

                    <Route path={CHEAT_KEY_PATH} element={<CheatKey />} />
                </Route>
                <Route path="/auth" element={<AuthLayoutFallback />}>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="sign-up" element={<SignUpPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
