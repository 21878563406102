import React from 'react';
import { BsFillBoxSeamFill, BsFillPersonFill } from 'react-icons/bs';
import { MdOutlineCropLandscape } from 'react-icons/md';

export interface SidebarNavigationItem {
    title: string;
    key: string;
    url?: string;
    children?: SidebarNavigationItem[];
    icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
    {
        title: 'Member',
        key: 'member-manage',
        icon: <BsFillPersonFill />,
        children: [
            {
                title: 'Member List',
                key: 'member-manage-list',
                url: '/member-manage/list'
            },
            // {
            //     title: 'Item Manage',
            //     key: 'member-manage-item-manage',
            //     url: '/member-manage/item-manage'
            // },
            {
                title: 'Item Distribution',
                key: 'member-manage-item-distribution',
                url: '/member-manage/item-distribution'
            },
            {
                title: 'Kick',
                key: 'member-manage-kick',
                url: '/member-manage/kick'
            },
            {
                title: 'Maintenance',
                key: 'member-manage-maintenance',
                url: '/member-manage/maintenance'
            }
        ]
    },
    {
        title: 'Raffle',
        key: 'raffle-manage',
        icon: <BsFillBoxSeamFill />,
        children: [
            {
                title: 'Raffle List',
                key: 'raffle-manage-list',
                url: '/raffle-manage/list'
            },
            {
                title: 'Raffle Draw',
                key: 'raffle-manage-draw',
                url: '/raffle-manage/draw'
            },
            {
                title: 'Raffle Offline Reward',
                key: 'raffle-manage-offline-reward',
                url: '/raffle-manage/offline-reward'
            }
        ]
    },
    // {
    //     title: 'Voucher',
    //     key: 'voucher-manage',
    //     icon: <MdOutlineCardGiftcard />,
    //     children: [
    //         {
    //             title: 'Voucher Manage',
    //             key: 'voucher-manage-list',
    //             url: '/voucher-manage'
    //         }
    //     ]
    // },
    // {
    //     title: 'Push Notification',
    //     key: 'push-notification-manage',
    //     icon: <IoNotifications />,
    //     children: [
    //         {
    //             title: 'List & Send',
    //             key: 'push-notification-manage-list',
    //             url: '/push-notification-manage/list'
    //         }
    //     ]
    // },
    {
        title: 'Banner',
        key: 'banner-manage',
        icon: <MdOutlineCropLandscape />,
        children: [
            {
                title: 'List & Enrollment',
                key: 'banner-manage-list',
                url: '/banner-manage/list'
            },
            {
                title: 'Priority Manage',
                key: 'banner-manage-priority',
                url: '/banner-manage/priority'
            }
        ]
    }
    // {
    //     title: 'Test',
    //     key: 'test',
    //     icon: <BiTestTube />,
    //     children: [
    //         {
    //             title: 'Cheat Key',
    //             key: 'cheat-key',
    //             url: '/cheat-key'
    //         }
    //     ]
    // }
];
