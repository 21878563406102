import { DATA_MANAGE_BUCKET_NAME, FOLDER_PATH } from '@app/consts/consts';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { metaDataSlice } from '@app/store/slices/metaDataSlice';
import { S3 } from 'aws-sdk';

export const downloadJsonFile = async (
    s3: S3,
    dispatch: ReturnType<typeof useAppDispatch>
): Promise<string | undefined> => {
    try {
        const listParams: S3.ListObjectsV2Request = {
            Bucket: DATA_MANAGE_BUCKET_NAME as string,
            Prefix: FOLDER_PATH as string
        };
        const response = await s3.listObjectsV2(listParams).promise();
        const objects = response.Contents;

        if (!objects) {
            throw new Error('No objects found in the specified folder.');
        }
        for (const object of objects) {
            const key = object.Key;
            if (!key || !key.endsWith('.json')) {
                continue;
            }
            const fileName = key.split('/').pop();
            if (!fileName) {
                continue;
            }

            downloadFile(s3, key, fileName, dispatch);
        }
    } catch (error) {
        console.error('Error:', error);
        return undefined;
    }
};

export const downloadFile = async (
    s3: S3,
    key: string,
    fileName: string,
    dispatch: ReturnType<typeof useAppDispatch>
): Promise<string | undefined> => {
    try {
        const response = await s3
            .getObject({
                Bucket: DATA_MANAGE_BUCKET_NAME as string,
                Key: key as string
            })
            .promise();

        const body = response.Body;
        const stringfiedJson = body?.toString('utf-8');

        store(fileName, stringfiedJson as string, dispatch);
    } catch (err) {
        console.error('Error downloading file', err);
        return undefined;
    }
};

export const store = (
    fileName: string,
    stringfiedJson: string,
    dispatch: ReturnType<typeof useAppDispatch>
) => {
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1)[0];
    switch (fileNameWithoutExtension) {
        case 'animalBase':
            dispatch(metaDataSlice.actions.setAnimalBase(JSON.parse(stringfiedJson)));
            break;
        case 'itemShop':
            dispatch(metaDataSlice.actions.setItemShop(JSON.parse(stringfiedJson)));
            break;
        case 'runRewardGroup':
            dispatch(metaDataSlice.actions.setRunRewardGroup(JSON.parse(stringfiedJson)));
            break;
        case 'seed':
            dispatch(metaDataSlice.actions.setSeed(JSON.parse(stringfiedJson)));
            break;
        case 'shoeBase':
            dispatch(metaDataSlice.actions.setShoeBase(JSON.parse(stringfiedJson)));
            break;
        case 'shoeConvertNFT':
            dispatch(metaDataSlice.actions.setShoeConvertNFT(JSON.parse(stringfiedJson)));
            break;
        case 'shoeLevelUp':
            dispatch(metaDataSlice.actions.setShoeLevelUp(JSON.parse(stringfiedJson)));
            break;
        case 'typeGlobalSetting':
            dispatch(metaDataSlice.actions.setTypeGlobalSetting(JSON.parse(stringfiedJson)));
            break;
        case 'typeItem':
            dispatch(metaDataSlice.actions.setTypeItem(JSON.parse(stringfiedJson)));
            break;
        case 'typeNFT':
            dispatch(metaDataSlice.actions.setTypeNFT(JSON.parse(stringfiedJson)));
            break;
        case 'typeShoeGrade':
            dispatch(metaDataSlice.actions.setTypeShoeGrade(JSON.parse(stringfiedJson)));
            break;
        case 'userInit':
            dispatch(metaDataSlice.actions.setUserInit(JSON.parse(stringfiedJson)));
            break;
        case 'typeOfflineRaffleReward':
            dispatch(metaDataSlice.actions.setTypeOfflineRaffleReward(JSON.parse(stringfiedJson)));
            break;
        default:
            break;
    }
};
