import * as S from './ShowingAccountHeader.styles';

type ShowingAccountHeaderProps = {
    email: string;
};

export const ShowingAccountHeader = ({ email }: ShowingAccountHeaderProps) => {
    return (
        <S.Wrapper>
            <S.Prefix> 🧑🏻‍💻 현재 계정 : </S.Prefix>
            <S.Title>{email}</S.Title>
        </S.Wrapper>
    );
};
