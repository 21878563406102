import { getBannerInfo, setBannerPriority } from '@app/api/pageApi/banner.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Title } from '@app/components/common/Title/Title';
import { PriorityManageTable } from '@app/components/tables/PriorityManageTable/PriorityManageTable';
import { Color } from '@app/styles/themes/light/lightTheme';
import { useEffect, useState } from 'react';

export const PriorityManagePage = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [logs, setLogs] = useState<any[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [firstPriority, setFirstPriority] = useState<string>('');
    const [secondPriority, setSecondPriority] = useState<string>('');
    const [thirdPriority, setThirdPriority] = useState<string>('');
    const gmAccountId = localStorage.getItem('gmAccountId');

    const getLogs = async () => {
        const data = await getBannerInfo(1);
        if (data.msg) {
            setLogs([]);
            return;
        }
        setLogs(data);
    };

    useEffect(() => {
        getLogs();
    }, []);

    const handleSetPriority = async (
        firstPriority: string,
        secondPriority: string,
        thirdPriority: string
    ) => {
        const data = await setBannerPriority(
            gmAccountId as string,
            logs,
            firstPriority,
            secondPriority,
            thirdPriority
        );
        if (data) setIsEdit(false);
    };

    return (
        <div>
            <PageTitle>Priority Manage</PageTitle>
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginBottom: 20
                    }}
                >
                    <Title>우선순위 설정</Title>
                    <Button
                        type="ghost"
                        style={{
                            marginLeft: 10,
                            backgroundColor: Color.white,
                            height: 35
                        }}
                        onClick={() =>
                            isEdit
                                ? handleSetPriority(firstPriority, secondPriority, thirdPriority)
                                : setIsEdit(true)
                        }
                    >
                        {isEdit ? '완료' : '설정하기'}
                    </Button>
                </div>
                <div style={{ display: isEdit ? 'block' : 'none' }}>
                    <Button
                        type="ghost"
                        style={{
                            backgroundColor: Color.white,
                            height: 35
                        }}
                        onClick={() => {
                            setIsEdit(false);
                            setFirstPriority('');
                            setSecondPriority('');
                            setThirdPriority('');
                        }}
                    >
                        취소
                    </Button>
                </div>
            </div>
            <Card>
                <PriorityManageTable
                    logs={logs}
                    isEdit={isEdit}
                    setFirstPriority={setFirstPriority}
                    setSecondPriority={setSecondPriority}
                    setThirdPriority={setThirdPriority}
                    firstPriority={firstPriority}
                    secondPriority={secondPriority}
                    thirdPriority={thirdPriority}
                />
            </Card>
        </div>
    );
};

export default PriorityManagePage;
