import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ItemDistributeInfoTable } from '@app/components/tables/ItemDistributionHistoryTable/ItemDistributeInfoTable';
import { Input } from '@app/components/common/inputs/Input/Input';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { Color } from '@app/styles/themes/light/lightTheme';
import { getItemDistributeInfo, distributionReasonToString } from '@app/api/pageApi/member.api';
import { ItemDistributionReason, TypeItemKey } from '@app/metaData/enum';
import {
    animalBaseItems,
    animalNumberToString,
    typeItemNumberToString,
    typeItemsMenu,
    typeShoeGradeItems,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SearchButton = styled(FaSearch)`
    color: black;
    size: 48px;
    background-color: ${Color.darkMint};
    padding: 13px;
    width: 65px;
    height: 50px;
    border-radius: 10px;
    color: ${Color.white};
    &:hover {
        cursor: pointer;
        background-color: ${Color.mint};
    }
    &:active {
        background-color: ${Color.darkMint};
    }
`;

const FilterButton = ({
    menu,
    child,
    width
}: {
    menu: any;
    child: string | undefined | null;
    width: string;
}) => {
    return (
        <Dropdown
            menu={{
                items: menu
            }}
        >
            <Button
                style={{
                    width: width,
                    marginRight: 10,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {child}
                </div>
                <FaChevronDown />
            </Button>
        </Dropdown>
    );
};

const ItemDistributionPage: React.FC = () => {
    const navigate = useNavigate();
    const [distributeOption, setDistributeOption] = useState(0);
    const [itemId, setItemId] = useState(0);
    const [itemType, setItemType] = useState(0);
    const [reason, setReason] = useState(0);
    const [searchWord, setSearchWord] = useState('');
    const [logs, setLogs] = useState([]);
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);

    const reasonMenu = [
        {
            label: (
                <Row>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        지급사유
                    </div>
                    <FaChevronUp />
                </Row>
            )
        },
        {
            key: 0,
            label: '선택 안 함',
            onClick: () => {
                setReason(0);
            }
        },
        {
            key: ItemDistributionReason.EVENT_REWARD,
            label: distributionReasonToString(ItemDistributionReason.EVENT_REWARD),
            onClick: () => {
                setReason(ItemDistributionReason.EVENT_REWARD);
            }
        },
        {
            key: ItemDistributionReason.GIFT_REWARD,
            label: distributionReasonToString(ItemDistributionReason.ADMIN_DISTRIBUTION),
            onClick: () => {
                setReason(ItemDistributionReason.GIFT_REWARD);
            }
        },
        {
            key: ItemDistributionReason.ADMIN_DISTRIBUTION,
            label: distributionReasonToString(ItemDistributionReason.ADMIN_DISTRIBUTION),
            onClick: () => {
                setReason(ItemDistributionReason.ADMIN_DISTRIBUTION);
            }
        }
    ];

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = async () => {
        const data = await getItemDistributeInfo(
            distributeOption,
            itemId,
            itemType,
            reason,
            searchWord
        );

        if (!data || data.msg) {
            notificationController.error({
                message: '아이템 지급 내역 조회에 실패했거나, 리스트에 없습니다.'
            });
            return;
        }

        setLogs(data);
    };

    return (
        <div>
            <PageTitle>Item Distribution Page</PageTitle>
            <Title>아이템 지급/회수 관리</Title>
            <Card>
                <div
                    style={{
                        marginBottom: 20,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <FilterButton
                        menu={typeItemsMenu(typeItem, setItemId as any)}
                        child={typeItemNumberToString(typeItem, itemId)}
                        width={`20%`}
                    />
                    <FilterButton
                        menu={
                            typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE
                                ? typeShoeGradeItems(typeShoeGrade, setItemType as any)
                                : typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL
                                ? animalBaseItems(animalBase, setItemType as any)
                                : []
                        }
                        child={
                            typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE
                                ? typeShoeGradeNumberToString(typeShoeGrade, itemType)
                                : typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL
                                ? animalNumberToString(itemType)
                                : 'NONE'
                        }
                        width={`20%`}
                    />
                    <FilterButton
                        menu={reasonMenu}
                        child={distributionReasonToString(reason)}
                        width={`20%`}
                    />

                    <Input
                        style={{
                            width: '60%',
                            marginRight: 10
                        }}
                        placeholder="account ID 입력"
                        value={searchWord}
                        onChange={e => {
                            setSearchWord(e.target.value);
                        }}
                    />
                    <SearchButton
                        onClick={() => {
                            getLogs();
                        }}
                    />
                </div>

                <ItemDistributeInfoTable logs={logs} />

                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate('/member-manage/item-distribution/individual');
                        }}
                    >
                        개별지급
                    </Button>
                    {/* <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate('/member-manage/item-distribution/group');
                        }}
                    >
                        일괄지급
                    </Button> */}
                </BottomContainer>
            </Card>
        </div>
    );
};
export default ItemDistributionPage;
