import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FileUploadInput } from '@app/components/common/inputs/FileUploadInput/FileUploadInput';
import { useRef, useState } from 'react';
import { dateToUnixTimeBySeconds } from '@app/utils/utils';
import { ONE_HOUR } from '@app/consts/consts';
import { initBanner } from '@app/api/pageApi/banner.api';
import { notificationController } from '@app/controllers/notificationController';
import { SubTitle } from '@app/components/common/Title/SubTitle';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const BannerEnrollPage = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState<string>('');
    const [postTs, setPostTs] = useState<number>(dateToUnixTimeBySeconds(new Date()));
    const [expireTs, setExpireTs] = useState<number>(dateToUnixTimeBySeconds(new Date()));
    const imgRef = useRef(null);
    const myPageImgRef = useRef(null);
    const [img, setImg] = useState<File>();
    const [myPageImg, setMyPageImg] = useState<File>();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [myPageImageUrl, setMyPageImageUrl] = useState<string>('');

    const [url, setUrl] = useState<string>('');
    const gmAccountId = localStorage.getItem('gmAccountId');

    const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setImg(fileList[0]);
            setImageUrl(URL.createObjectURL(fileList[0]));
        }
    };

    const uploadMyPageImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setMyPageImg(fileList[0]);
            setMyPageImageUrl(URL.createObjectURL(fileList[0]));
        }
    };

    const clickSaveButton = async () => {
        const nowTs = Math.floor(new Date().getTime() / 1000);
        if (!gmAccountId) {
            notificationController.error({ message: '관리자 계정을 인지할 수 없습니다.' });
            return;
        }
        if (!title) {
            notificationController.error({ message: '배너명을 입력해주세요!' });
            return;
        }
        if (postTs < nowTs) {
            notificationController.error({
                message: '배너 게시일은 현재보다 미래여야합니다.'
            });
            return;
        }
        if (nowTs > expireTs) {
            notificationController.error({
                message: '배너 게시 만료일은 현재보다 미래여야합니다.'
            });
            return;
        }
        if (url === '') {
            notificationController.error({
                message: '이동 URL을 입력해주세요!'
            });
            return;
        }

        const dataToSend = {
            gmAccountId,
            title,
            postTs,
            expireTs,
            url
        };

        const formData = new FormData();
        if (img) formData.append('img', img);
        if (myPageImg) formData.append('myPageImg', myPageImg);

        formData.append('data', JSON.stringify(dataToSend));

        await initBanner(formData, navigate);
    };

    return (
        <div>
            <Title>배너 등록</Title>
            <Card
                style={{
                    width: '60%',
                    minWidth: 500
                }}
            >
                <PrefixInput prefix="배너명" type="text" onChange={setTitle} value={title} />
                <PrefixInput
                    prefix="게시 시작일"
                    type="datetime-local"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPostTs(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    value={new Date((postTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <PrefixInput
                    prefix="게시 종료일"
                    type="datetime-local"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setExpireTs(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    value={new Date((expireTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />

                <PrefixInput
                    prefix="이동 URL"
                    onChange={setUrl}
                    value={url}
                    style={{ marginBottom: 10 }}
                />
                <SubTitle>1. 홈 구좌 배너 이미지</SubTitle>
                <FileUploadInput
                    onChange={uploadImage}
                    url={imageUrl}
                    ref={imgRef}
                    style={{ marginBottom: 10 }}
                />
                <SubTitle>2. 마이페이지 구좌 배너 이미지</SubTitle>
                <FileUploadInput
                    onChange={uploadMyPageImage}
                    url={myPageImageUrl}
                    ref={myPageImgRef}
                />

                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록
                    </Button>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            clickSaveButton();
                        }}
                    >
                        저장
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default BannerEnrollPage;
