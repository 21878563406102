import { InCardRankTitle } from '@app/components/common/Title/InCardRankTitle';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Color } from '@app/styles/themes/light/lightTheme';
import { MdOutlineCancel } from 'react-icons/md';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { AiFillPlusCircle, AiTwotoneDelete } from 'react-icons/ai';
import { confirmAlert } from '../../api/pageApi/notificationAlert.api';
import { RewardInfo, entryRewardItemsProps } from './RaffleEnrollment';
import { notificationController } from '@app/controllers/notificationController';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
    animalBaseItems,
    animalNumberToString,
    typeItemNumberToString,
    typeItemsMenu,
    typeOfflineRaffleRewardItems,
    typeOfflineRaffleRewardNumberToString,
    typeShoeGradeItems,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { TypeItemKey } from '@app/metaData/enum';

interface RewardEnrollModalProps {
    isModalOpen: boolean;
    onClick?: () => void;
    index: number;
    onClickAddPart?: () => void;
    deletePart?: () => void;
    onClickCancel?: () => void;
    onClickSave?: (rewardsList: RewardInfo[], entryRewardList: entryRewardItemsProps[]) => void;
    isEditing?: boolean;
    rewardItems?: RewardInfo[];
}

interface RewardProps {
    index: number;
    rewardName: string;
    onChangeRewardName: Dispatch<SetStateAction<string>> | undefined;
    rewardItemId: number;
    onChangeRewardItemId: Dispatch<SetStateAction<number>> | undefined;
    rewardItemType: number;
    onChangeRewardItemType: Dispatch<SetStateAction<number>> | undefined;
    rewardItemCount: string;
    onChangeRewardItemCount: Dispatch<SetStateAction<string>> | undefined;
    winnerCount: number;
    onChangeWinnerCount: Dispatch<SetStateAction<number>> | undefined;
    deleteReward: (idx: number) => void;
}

interface EntryRewardProps {
    entryRewardItemId: number;
    onChangeEntryRewardItemId: Dispatch<SetStateAction<number>> | undefined;
    entryRewardItemType: number;
    onChangeEntryRewardItemType: Dispatch<SetStateAction<number>> | undefined;
    entryRewardItemCount: string;
    onChangeEntryRewardItemCount: Dispatch<SetStateAction<string>> | undefined;
}

interface AddPartProps {
    onClick?: () => void;
}

const RewardBox = styled.div`
    width: 100%;
    border: 1px solid ${Color.gray};
    border-radius: 10px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const DeleteButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
`;

const ModalContainer = styled.div`
    width: 100%;
    border: 1px solid ${Color.darkGray};
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
`;

const AddButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
`;

const Reward = ({
    index,
    rewardName,
    onChangeRewardName,
    rewardItemId,
    onChangeRewardItemId,
    rewardItemType,
    onChangeRewardItemType,
    rewardItemCount,
    onChangeRewardItemCount,
    winnerCount,
    onChangeWinnerCount,
    deleteReward
}: RewardProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);
    const typeNFT = useAppSelector(state => state.metaData.typeNFT);
    const typeOfflineRaffleReward = useAppSelector(state => state.metaData.typeOfflineRaffleReward);

    return (
        <RewardBox>
            <InCardRankTitle>{index}등</InCardRankTitle>
            <PrefixInput
                prefix="상품명"
                type="text"
                onChange={onChangeRewardName}
                value={rewardName}
            />

            <Dropdown
                menu={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    items: typeItemsMenu(typeItem, onChangeRewardItemId as any)
                }}
                placement="bottom"
                arrow
            >
                <PrefixButton prefix="상품 카테고리">
                    {typeItemNumberToString(typeItem, rewardItemId)}
                </PrefixButton>
            </Dropdown>

            <div
                style={{
                    display:
                        typeItemNumberToString(typeItem, rewardItemId) === TypeItemKey.SHOE ||
                        typeItemNumberToString(typeItem, rewardItemId) === TypeItemKey.ANIMAL ||
                        typeItemNumberToString(typeItem, rewardItemId) ===
                            TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? 'block'
                            : 'none'
                }}
            >
                <Dropdown
                    menu={{
                        items:
                            typeItemNumberToString(typeItem, rewardItemId) === TypeItemKey.SHOE
                                ? typeShoeGradeItems(typeShoeGrade, onChangeRewardItemType as any)
                                : typeItemNumberToString(typeItem, rewardItemId) ===
                                  TypeItemKey.ANIMAL
                                ? animalBaseItems(animalBase, onChangeRewardItemType as any)
                                : typeItemNumberToString(typeItem, rewardItemId) ===
                                  TypeItemKey.OFFLINE_RAFFLE_REWARD
                                ? typeOfflineRaffleRewardItems(
                                      typeOfflineRaffleReward,
                                      onChangeRewardItemType as any
                                  )
                                : []
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="상품 세부 타입">
                        {typeItemNumberToString(typeItem, rewardItemId) === TypeItemKey.SHOE
                            ? typeShoeGradeNumberToString(typeShoeGrade, rewardItemType)
                            : typeItemNumberToString(typeItem, rewardItemId) === TypeItemKey.ANIMAL
                            ? animalNumberToString(rewardItemType)
                            : typeItemNumberToString(typeItem, rewardItemId) ===
                              TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? typeOfflineRaffleRewardNumberToString(
                                  typeOfflineRaffleReward,
                                  rewardItemType
                              )
                            : null}
                    </PrefixButton>
                </Dropdown>
            </div>
            <PrefixInput
                prefix="상품 수량"
                type="number"
                onChange={onChangeRewardItemCount}
                value={rewardItemCount === '0' ? '' : rewardItemCount}
            />

            <PrefixInput
                prefix="당첨 인원"
                type="number"
                onChange={onChangeWinnerCount}
                value={winnerCount === 0 ? '' : winnerCount}
            />
            <DeleteButtonContainer>
                <div
                    style={{
                        cursor: 'pointer',
                        borderRadius: 7,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor: Color.pink,
                        padding: 3
                    }}
                    onClick={() => deleteReward(index)}
                >
                    <AiTwotoneDelete size="23" color={Color.white} />
                </div>
            </DeleteButtonContainer>
        </RewardBox>
    );
};

const EntryReward = ({
    entryRewardItemId,
    onChangeEntryRewardItemId,
    entryRewardItemType,
    onChangeEntryRewardItemType,
    entryRewardItemCount,
    onChangeEntryRewardItemCount
}: EntryRewardProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);
    const typeOfflineRaffleReward = useAppSelector(state => state.metaData.typeOfflineRaffleReward);

    return (
        <RewardBox>
            <InCardRankTitle>낙첨자 지급 상품 ( 총량 ) </InCardRankTitle>
            <SubTitle>AWM, USDT Money, USDT Point만 선택해주세요 </SubTitle>
            <Dropdown
                menu={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    items: typeItemsMenu(typeItem, onChangeEntryRewardItemId as any)
                }}
                placement="bottom"
                arrow
            >
                <PrefixButton prefix="상품 카테고리">
                    {typeItemNumberToString(typeItem, entryRewardItemId)}
                </PrefixButton>
            </Dropdown>

            <div
                style={{
                    display:
                        typeItemNumberToString(typeItem, entryRewardItemId) === TypeItemKey.SHOE ||
                        typeItemNumberToString(typeItem, entryRewardItemId) ===
                            TypeItemKey.ANIMAL ||
                        typeItemNumberToString(typeItem, entryRewardItemId) ===
                            TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? 'block'
                            : 'none'
                }}
            >
                <Dropdown
                    menu={{
                        items:
                            typeItemNumberToString(typeItem, entryRewardItemId) === TypeItemKey.SHOE
                                ? typeShoeGradeItems(
                                      typeShoeGrade,
                                      onChangeEntryRewardItemType as any
                                  )
                                : typeItemNumberToString(typeItem, entryRewardItemId) ===
                                  TypeItemKey.ANIMAL
                                ? animalBaseItems(animalBase, onChangeEntryRewardItemType as any)
                                : typeItemNumberToString(typeItem, entryRewardItemId) ===
                                  TypeItemKey.OFFLINE_RAFFLE_REWARD
                                ? typeOfflineRaffleRewardItems(
                                      typeOfflineRaffleReward,
                                      onChangeEntryRewardItemType as any
                                  )
                                : []
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="상품 세부 타입">
                        {typeItemNumberToString(typeItem, entryRewardItemId) === TypeItemKey.SHOE
                            ? typeShoeGradeNumberToString(typeShoeGrade, entryRewardItemType)
                            : typeItemNumberToString(typeItem, entryRewardItemId) ===
                              TypeItemKey.ANIMAL
                            ? animalNumberToString(entryRewardItemType)
                            : typeItemNumberToString(typeItem, entryRewardItemId) ===
                              TypeItemKey.OFFLINE_RAFFLE_REWARD
                            ? typeOfflineRaffleRewardNumberToString(
                                  typeOfflineRaffleReward,
                                  entryRewardItemType
                              )
                            : null}
                    </PrefixButton>
                </Dropdown>
            </div>
            <PrefixInput
                prefix="Total Pool"
                type="number"
                onChange={onChangeEntryRewardItemCount}
                value={entryRewardItemCount === '0' ? '' : entryRewardItemCount}
            />
        </RewardBox>
    );
};

const SaveButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center;
`;

export const AddPart = ({ onClick }: AddPartProps) => {
    return (
        <AddButtonContainer onClick={onClick}>
            <AiFillPlusCircle size="40" color={Color.mint} />
            <text
                style={{
                    marginLeft: 5,
                    fontSize: 16,
                    fontWeight: 'bold'
                }}
            >
                상품 추가하기
            </text>
        </AddButtonContainer>
    );
};

export const RewardEnrollModal = ({
    isModalOpen,
    index,
    onClickCancel,
    onClickAddPart,
    deletePart,
    onClickSave,
    isEditing,
    rewardItems
}: RewardEnrollModalProps) => {
    const [entryRewardItemId, setEntryRewardItemId] = useState<number>(0);
    const [entryRewardItemType, setEntryRewardItemType] = useState<number>(0);
    const [entryRewardItemCount, setEntryRewardItemCount] = useState<string>('');

    const [firstRewardName, setFirstRewardName] = useState<string>('');
    const [firstItemId, setFirstItemId] = useState<number>(0);
    const [firstItemType, setFirstItemType] = useState<number>(0);
    const [firstItemCount, setFirstItemCount] = useState<string>('0');
    const [firstWinnerCount, setFirstWinnerCount] = useState<number>(0);

    const [secondRewardName, setSecondRewardName] = useState<string>('');
    const [secondItemId, setSecondItemId] = useState<number>(0);
    const [secondItemType, setSecondItemType] = useState<number>(0);
    const [secondItemCount, setSecondItemCount] = useState<string>('0');
    const [secondWinnerCount, setSecondWinnerCount] = useState<number>(0);

    const [thirdRewardName, setThirdRewardName] = useState<string>('');
    const [thirdItemId, setThirdItemId] = useState<number>(0);
    const [thirdItemType, setThirdItemType] = useState<number>(0);
    const [thirdItemCount, setThirdItemCount] = useState<string>('0');
    const [thirdWinnerCount, setThirdWinnerCount] = useState<number>(0);

    const [fourthRewardName, setFourthRewardName] = useState<string>('');
    const [fourthItemId, setFourthItemId] = useState<number>(0);
    const [fourthWinnerCount, setFourthWinnerCount] = useState<number>(0);
    const [fourthItemType, setFourthItemType] = useState<number>(0);
    const [fourthItemCount, setFourthItemCount] = useState<string>('0');

    useEffect(() => {
        if (!rewardItems || rewardItems.length < 1) return;
        setFirstRewardName(rewardItems[0].name as string);
        setFirstItemId(rewardItems[0].id as number);
        setFirstItemType(rewardItems[0].type as number);
        setFirstItemCount((rewardItems[0].count as string).toString());
        setFirstWinnerCount(rewardItems[0].winningUserCount as number);

        if (rewardItems.length < 2) return;
        setSecondRewardName(rewardItems[1].name as string);
        setSecondItemId(rewardItems[1].id as number);
        setSecondItemType(rewardItems[1].type as number);
        setSecondItemCount((rewardItems[1].count as string).toString());
        setSecondWinnerCount(rewardItems[1].winningUserCount as number);

        if (rewardItems.length < 3) return;
        setThirdRewardName(rewardItems[2].name as string);
        setThirdItemId(rewardItems[2].id as number);
        setThirdItemType(rewardItems[2].type as number);
        setThirdItemCount((rewardItems[2].count as string).toString());
        setThirdWinnerCount(rewardItems[2].winningUserCount as number);

        if (rewardItems.length < 4) return;
        setFourthRewardName(rewardItems[3].name as string);
        setFourthItemId(rewardItems[3].id as number);
        setFourthItemType(rewardItems[3].type as number);
        setFourthItemCount((rewardItems[3].count as string).toString());
        setFourthWinnerCount(rewardItems[3].winningUserCount as number);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    const indexList = (index: number) => {
        const list = [];

        for (let i = 1; i <= index; i++) {
            list.push(i);
        }

        return list;
    };

    const matchRewardName = (index: number): string => {
        switch (index) {
            case 1:
                return firstRewardName;
            case 2:
                return secondRewardName;
            case 3:
                return thirdRewardName;
            case 4:
                return fourthRewardName;
        }
        return '';
    };

    const matchRewardItemId = (index: number): number => {
        switch (index) {
            case 1:
                return firstItemId;
            case 2:
                return secondItemId;
            case 3:
                return thirdItemId;
            case 4:
                return fourthItemId;
        }
        return 0;
    };

    const matchWinnerCount = (index: number): number => {
        switch (index) {
            case 1:
                return firstWinnerCount;
            case 2:
                return secondWinnerCount;
            case 3:
                return thirdWinnerCount;
            case 4:
                return fourthWinnerCount;
        }
        return 0;
    };

    const matchRewardItemType = (index: number): number => {
        switch (index) {
            case 1:
                return firstItemType;
            case 2:
                return secondItemType;
            case 3:
                return thirdItemType;
            case 4:
                return fourthItemType;
        }
        return 0;
    };

    const matchRewardItemCount = (index: number): string => {
        switch (index) {
            case 1:
                return firstItemCount;
            case 2:
                return secondItemCount;
            case 3:
                return thirdItemCount;
            case 4:
                return fourthItemCount;
        }
        return '0';
    };

    const matchSetRewardName = (index: number) => {
        switch (index) {
            case 1:
                return setFirstRewardName;
            case 2:
                return setSecondRewardName;
            case 3:
                return setThirdRewardName;
            case 4:
                return setFourthRewardName;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetRewardItemId = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemId;
            case 2:
                return setSecondItemId;
            case 3:
                return setThirdItemId;
            case 4:
                return setFourthItemId;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetWinnerCount = (index: number) => {
        switch (index) {
            case 1:
                return setFirstWinnerCount;
            case 2:
                return setSecondWinnerCount;
            case 3:
                return setThirdWinnerCount;
            case 4:
                return setFourthWinnerCount;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetRewardItemType = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemType;
            case 2:
                return setSecondItemType;
            case 3:
                return setThirdItemType;
            case 4:
                return setFourthItemType;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const matchSetRewardItemCount = (index: number) => {
        switch (index) {
            case 1:
                return setFirstItemCount;
            case 2:
                return setSecondItemCount;
            case 3:
                return setThirdItemCount;
            case 4:
                return setFourthItemCount;
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    };

    const deleteReward = (idx: number) => {
        if (!deletePart) return;

        for (let i = idx; i < index; i++) {
            matchSetRewardName(i)(matchRewardName(i + 1));
            matchSetRewardItemCount(i)(matchRewardItemCount(i + 1));
            matchSetRewardItemType(i)(matchRewardItemType(i + 1));
            matchSetWinnerCount(i)(matchWinnerCount(i + 1));
        }
        matchRewardName(index) && matchSetRewardName(index)('');
        matchWinnerCount(index) && matchSetWinnerCount(index)(0);
        matchRewardItemType(index) && matchSetRewardItemType(index)(0);
        matchRewardItemCount(index) && matchSetRewardItemCount(index)('0');

        deletePart();
    };

    const onClickCancelButton = async () => {
        if (!(await confirmAlert('래플 상품정보 입력을 정말로 취소하시겠습니까?'))) return;

        if (!isEditing)
            for (let i = 1; i <= index; i++) {
                matchSetRewardName(i)('');
                matchSetWinnerCount(i)(0);
                matchSetRewardItemId(i)(0);
                matchSetRewardItemType(i)(0);
                matchSetRewardItemCount(i)('0');
            }

        onClickCancel && onClickCancel();
    };

    const clickSaveButton = async () => {
        const entryReward = [];

        entryReward.push({
            id: entryRewardItemId,
            type: entryRewardItemType,
            count: entryRewardItemCount
        });

        const rewardList = [];
        for (let i = 1; i <= index; i++) {
            if (matchRewardName(i) === '') {
                notificationController.error({
                    message: '상품명을 입력해주세요.'
                });
                return;
            }

            if (+matchRewardItemId(i) === 0) {
                notificationController.error({
                    message: '상품 카테고리를 선택해주세요.'
                });
                return;
            }

            if (
                +matchRewardItemType(i) === 0 &&
                (typeItemNumberToString(
                    +matchRewardItemType(i),
                    +matchRewardItemId(i) as number
                ) === TypeItemKey.SHOE ||
                    typeItemNumberToString(
                        +matchRewardItemType(i),
                        +matchRewardItemId(i) as number
                    ) === TypeItemKey.ANIMAL ||
                    typeItemNumberToString(
                        +matchRewardItemType(i),
                        +matchRewardItemId(i) as number
                    ) === TypeItemKey.OFFLINE_RAFFLE_REWARD)
            ) {
                notificationController.error({
                    message: '상품 세부 타입을 선택해주세요.'
                });
                return;
            }

            if (+matchRewardItemCount(i) === 0) {
                notificationController.error({
                    message: '상품 수량을 입력해주세요.'
                });
                return;
            }

            if (+matchWinnerCount(i) === 0) {
                notificationController.error({
                    message: '당첨 인원을 입력해주세요.'
                });
                return;
            }

            rewardList.push({
                rank: i,
                name: matchRewardName(i),
                id: matchRewardItemId(i),
                type: matchRewardItemType(i),
                count: matchRewardItemCount(i),
                winningUserCount: Number(matchWinnerCount(i))
            });
        }

        if (!(await confirmAlert('래플 상품정보를 저장하시겠습니까?'))) return;

        onClickSave && onClickSave(rewardList, entryReward);
    };

    return (
        <ModalContainer style={{ display: isModalOpen ? 'flex' : 'none' }}>
            <div
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    display: 'flex',
                    width: '100%'
                }}
            >
                <InCardTitle>래플 상품정보를 입력해주세요 !</InCardTitle>
                <MdOutlineCancel size="25" onClick={onClickCancelButton} />
            </div>
            <SubTitle>( * 고객에게 노출되는 페이지는 아니며, 추첨 시 이용됩니다 )</SubTitle>
            <SubTitle>
                <div style={{ color: Color.pink }}>
                    주의사항 : AWM, KlaytnUsdtMoney, KlaytnUsdtPoint의 경우, 10^18 곱하여 입력할 것
                </div>
            </SubTitle>
            <EntryReward
                entryRewardItemId={entryRewardItemId}
                onChangeEntryRewardItemId={setEntryRewardItemId}
                entryRewardItemType={entryRewardItemType}
                onChangeEntryRewardItemType={setEntryRewardItemType}
                entryRewardItemCount={entryRewardItemCount}
                onChangeEntryRewardItemCount={setEntryRewardItemCount}
            />

            {indexList(index).map(i => (
                <Reward
                    key={i}
                    index={i}
                    rewardName={matchRewardName(i)}
                    onChangeRewardName={matchSetRewardName(i)}
                    rewardItemId={matchRewardItemId(i)}
                    onChangeRewardItemId={matchSetRewardItemId(i)}
                    rewardItemType={matchRewardItemType(i)}
                    onChangeRewardItemType={matchSetRewardItemType(i)}
                    rewardItemCount={matchRewardItemCount(i)}
                    onChangeRewardItemCount={matchSetRewardItemCount(i)}
                    winnerCount={matchWinnerCount(i)}
                    onChangeWinnerCount={matchSetWinnerCount(i)}
                    deleteReward={(i: number) => deleteReward(i)}
                />
            ))}
            <AddPart onClick={onClickAddPart as () => void} />
            <SaveButtonContainer>
                <Button
                    style={{
                        width: 200
                    }}
                    type="ghost"
                    onClick={clickSaveButton}
                >
                    저장
                </Button>
            </SaveButtonContainer>
        </ModalContainer>
    );
};
