import { getBannerInfo } from '@app/api/pageApi/banner.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Title } from '@app/components/common/Title/Title';
import { BannerListTable } from '@app/components/tables/BannerListTable/BannerListTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
`;

export const BannerListPage = () => {
    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = async () => {
        const data = await getBannerInfo(0);
        if (!data || data.msg) {
            return;
        }
        setLogs(data);
    };

    return (
        <div>
            <PageTitle>Banner List</PageTitle>
            <Title>배너 리스트</Title>
            <Card>
                <BannerListTable logs={logs} />
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(`/banner-manage/list/banner-enrollment`);
                        }}
                    >
                        등록
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default BannerListPage;
