import React from 'react';
import { Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';
import * as S from './TextArea.styles';
import { TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea';

export interface InputProps extends AntInputProps {
    className?: string;
    onChange?: any;
}

export const TextAreaInput = React.forwardRef<TextAreaRef, TextAreaProps>(
    ({ className, onChange, children, ...props }, ref) => (
        <S.TextAreaInput onChange={onChange} ref={ref} className={className} {...props}>
            {children}
        </S.TextAreaInput>
    )
);
