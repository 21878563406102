import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, errorAlert, successAlert } from '@app/api/pageApi/notificationAlert.api';
import { RewardInfo, entryItemsProps } from '@app/pages/RafflePages/RaffleEnrollment';
import { NavigateFunction } from 'react-router-dom';

export const initRaffle = async (navigate: NavigateFunction, formData: FormData) => {
    try {
        if (!(await confirmAlert('정말로 래플을 등록하시겠습니까?'))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles`, {
            method: 'POST',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: formData
        });
        const data = await res.json();
        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }
        if (data.raffleId) {
            await successAlert('래플을 성공적으로 생성하였습니다.');
            navigate(-1);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `raffle 등록에 실패했습니다. : ${err.message}`
        });
    }
};

export const draw = async (gmAccountId: string, raffleId: string) => {
    try {
        if (!(await confirmAlert('정말 추첨을 진행하시겠습니까?'))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/draw`, {
            method: 'POST',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                gmAccountId,
                raffleId
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data.winners) {
            notificationController.success({ message: '추첨에 성공했습니다.' });
            return data.winners;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `추첨에 실패했습니다. : ${err.message}`
        });
    }
};

export const result = async (raffleId: string) => {
    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/result?raffleId=${raffleId}`, {
            method: 'GET',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            }
        });

        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `래플 당첨자 조회에 실패했습니다. : ${err.message}`
        });
    }
};

export const resultOpen = async (gmAccountId: string, raffleId: string) => {
    try {
        if (!(await confirmAlert('결과를 정말 노출하시겠습니까?'))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/result/open`, {
            method: 'PUT',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                gmAccountId,
                raffleId
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data?.msg === 'success') {
            successAlert('당첨자 노출에 성공했습니다.');
        } else {
            notificationController.error({
                message: '당첨자 노출에 실패했습니다.'
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `당첨자 노출에 실패했습니다. : ${err.message}`
        });
    }
};

export const deleteRaffle = async (gmAccountId: string, raffleId: string) => {
    if (!(await confirmAlert('래플을 삭제하시겠습니까?'))) {
        return;
    }

    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles`, {
            method: 'DELETE',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                raffleId,
                gmAccountId
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }
        if (data?.msg === 'success') {
            successAlert('래플을 삭제했습니다.');
            return true;
        } else {
            notificationController.error({
                message: '래플 삭제에 실패했습니다.'
            });
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `래플 삭제에 실패했습니다. : ${err.message}`
        });
    }
};

export const updateRaffle = async (
    gmAccountId: string,
    raffleId: string,
    description: string,
    entryItems: entryItemsProps[],
    rewardItems: RewardInfo[],
    startTs: number,
    endTs: number
) => {
    if (!(await confirmAlert('래플을 수정하시겠습니까?'))) {
        return;
    }

    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/update`, {
            method: 'PUT',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                gmAccountId,
                raffleId,
                description,
                entryItems,
                rewardItems,
                startTs,
                endTs
            })
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (data?.message === 'success') {
            successAlert('래플을 삭제했습니다.');
            return true;
        } else {
            notificationController.error({
                message: '래플 삭제에 실패했습니다.'
            });
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `래플 삭제에 실패했습니다. : ${err.message}`
        });
    }
};

export const dateOptionToString = (dateOption: number) => {
    switch (dateOption) {
        case 1:
            return '응모 시작일';
        case 2:
            return '응모 종료일';
        case 3:
            return '추첨일';
        default:
            return '날짜 검색 옵션';
    }
};

export const isWinningExposeToString = (isWinningExpose: number) => {
    switch (isWinningExpose) {
        case 1:
            return '공개';
        case 0:
            return '비공개';
        default:
            return '당첨자 공개 여부';
    }
};

export const getOfflineReward = async (isRewarded: number) => {
    try {
        const res = await fetch(
            `${ADMIN_API_BASE_URL}/admin/raffles/offline-reward?isRewarded=${isRewarded}`,
            {
                method: 'GET',
                headers: {
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            }
        );
        const data = await res.json();
        return data;
    } catch (err: any) {
        notificationController.error({ message: '오프라인 리워드 조회에 실패했습니다.' });
    }
};

export const offlineReward = async (id: number, gmAccountId: string) => {
    if (
        !(await confirmAlert(
            '정말로 지급처리를 하시겠습니까? 버튼을 누르기 전, 제대로 발송처리하였는지 확인해주세요. '
        ))
    )
        return;

    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/offline-reward`, {
            method: 'PUT',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: JSON.stringify({
                id,
                gmAccountId
            })
        });
        const data = await res.json();
        if (data && data?.message === 'success') {
            await successAlert('지급처리를 완료했습니다.');
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: `리워드 지급 처리에 실패하였습니다. : ${err}` });
    }
};

export const uploadImg = async (formData: FormData) => {
    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/img`, {
            method: 'POST',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            },
            body: formData
        });
        const data = await res.json();
        if (!data?.msg) {
            successAlert('이미지 등록을 성공하였습니다!');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: '래플 업로드 중, 에러가 발생했습니다.' });
    }
};

export const getImgs = async () => {
    try {
        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles/img`, {
            method: 'GET',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            }
        });
        const data = await res.json();
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({ message: '래플 업로드 중, 에러가 발생했습니다.' });
    }
};
