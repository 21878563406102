import { Table } from 'components/common/Table/Table';
import {
    Pagination,
    getTableData,
    ItemManageTableRow as MemberListForItemTableRow
} from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

const TouchableWrapper = styled.div`
    cursor: pointer;
`;

interface MemberListForItemTableProps {
    members: MemberListForItemTableRow[];
}

export const MemberListForItemTable = ({ members }: MemberListForItemTableProps) => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<MemberListForItemTableRow> = [
        {
            title: '#',
            dataIndex: 'memberIndex',
            width: '3%',
            render: (text, record) => (
                <TouchableWrapper
                    onClick={() =>
                        navigate(`/member-manage/item-manage/${record.memberIndex}`, {
                            state: record
                        })
                    }
                >
                    {text}
                </TouchableWrapper>
            )
        },
        {
            title: '닉네임',
            dataIndex: 'nickName',
            width: '4%',
            render: (text, record) => (
                <TouchableWrapper
                    onClick={() =>
                        navigate(`/member-manage/item-manage/${record.memberIndex}`, {
                            state: record
                        })
                    }
                >
                    {text}
                </TouchableWrapper>
            )
        },
        {
            title: '핸드폰번호',
            dataIndex: 'phone',
            width: '6%',
            render: (text, record) => (
                <TouchableWrapper
                    onClick={() =>
                        navigate(`/member-manage/item-manage/${record.memberIndex}`, {
                            state: record
                        })
                    }
                >
                    {text}
                </TouchableWrapper>
            )
        },
        {
            title: '계정',
            dataIndex: 'eMail',
            width: '8%',
            render: (text, record) => (
                <TouchableWrapper
                    onClick={() =>
                        navigate(`/member-manage/item-manage/${record.memberIndex}`, {
                            state: record
                        })
                    }
                >
                    {text}
                </TouchableWrapper>
            )
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={members}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
