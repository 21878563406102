import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { kickReasonToString } from '@app/api/pageApi/member.api';
import { ADMIN_API_BASE_URL, API_KEY, ONE_HOUR } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert, successAlert } from '../../api/pageApi/notificationAlert.api';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;
const GroupKickPage: React.FC = () => {
    const gmAccountId = localStorage.getItem('gmAccountId') || '';
    const navigate = useNavigate();
    const [expireTs, setExpireTs] = useState<number>(Math.floor(new Date().getTime() / 1000));
    const [cause, setCause] = useState<number>(0);
    const [isPopup, setIsPopup] = useState<boolean>(false);

    const reasonMenu = [
        {
            key: 1,
            label: '접속 환경 업데이트',
            onClick: () => {
                setCause(1);
            }
        },
        {
            key: 2,
            label: '서버 점검',
            onClick: () => {
                setCause(2);
            }
        },
        {
            key: 3,
            label: '계정 정지',
            onClick: () => {
                setCause(3);
            }
        }
    ];

    const isMessageMenu = [
        {
            key: 1,
            label: '있음',
            onClick: () => {
                setIsPopup(true);
            }
        },
        {
            key: 2,
            label: '없음',
            onClick: () => {
                setIsPopup(false);
            }
        }
    ];

    const groupKick = async () => {
        const nowTs = Math.floor(new Date().getTime() / 1000);
        if (expireTs < nowTs) {
            notificationController.error({
                message: '강퇴 종료일을 현재 시간 이후로 설정해주세요'
            });
            return;
        }
        if (cause === 0) {
            notificationController.error({ message: '강퇴 사유를 선택해주세요' });
            return;
        }
        const message = `
        <strong>전체유저</strong>을 강퇴합니다. <br>
        <strong>✓ 강퇴 종료일</strong>: ${new Date((expireTs + 9 * ONE_HOUR) * 1000)
            .toISOString()
            .slice(0, -8)} <br>
        <strong>✓ 강퇴 사유</strong>: ${kickReasonToString(cause)} <br>
        <strong>✓ 팝업 메시지</strong>: ${isPopup ? '있음' : '없음'} <br>
        <strong>정말로 실행하시겠습니까?</strong>
        `;
        if (!(await confirmAlert(message))) return;

        const res = await fetch(`${ADMIN_API_BASE_URL}/admin/kick/all`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-KEY': API_KEY as string
            },
            body: JSON.stringify({
                expireTs: expireTs,
                cause: cause,
                isPopup: isPopup,
                gmAccountId
            })
        });
        const data = await res.json();
        if (data.msg) {
            notificationController.error({ message: data.msg });
        } else {
            successAlert('강퇴 등록이 완료되었습니다.');
            navigate(-1);
        }
    };

    return (
        <div>
            <PageTitle>Kick Manage</PageTitle>
            <Title>개별 강퇴</Title>
            <SubTitle>Manage Kick</SubTitle>
            <Card style={{ width: '60%' }}>
                <PrefixInput
                    prefix="강퇴 종료일"
                    type="datetime-local"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setExpireTs(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    value={new Date((expireTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <Dropdown
                    menu={{
                        items: reasonMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="강퇴 사유">{kickReasonToString(cause)}</PrefixButton>
                </Dropdown>
                <Dropdown
                    menu={{
                        items: isMessageMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="팝업 메시지">{isPopup ? '있음' : '없음'}</PrefixButton>
                </Dropdown>

                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            groupKick();
                        }}
                    >
                        강퇴 등록
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default GroupKickPage;
