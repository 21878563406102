import { getOfflineReward } from '@app/api/pageApi/raffle.api';
import { Card } from '@app/components/common/Card/Card';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { Title } from '@app/components/common/Title/Title';
import { RaffleOfflineRewardTable } from '@app/components/tables/RaffleOfflineRewardTable/RaffleOfflineRewardTable';
import React, { useEffect, useState } from 'react';

const RaffleOfflineRewardPage: React.FC = () => {
    const [list, setList] = useState<any>([]);

    useEffect(() => {
        handleGetFunction();
    }, []);

    const handleGetFunction = async () => {
        const data = await getOfflineReward(0);
        if (data && !data.msg) {
            setList(data);
        }
    };

    return (
        <div>
            <Title>오프라인 상품 관리</Title>
            <Card>
                <InCardTitle>1. 기본 정보</InCardTitle>
                <RaffleOfflineRewardTable raffleOfflineReward={list} />
            </Card>
        </div>
    );
};

export default RaffleOfflineRewardPage;
