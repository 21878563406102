import React, { useEffect, useRef, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { MemberListForItemTable } from '@app/components/tables/ItemManageTable/ItemManageTable';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { getMemberListWithEmail } from '@app/api/pageApi/member.api';

const members = [
    {
        memberIndex: 1,
        nickName: 'test',
        eMail: 'andy@anotherworld.game',
        phone: '010-1234-5678'
    },
    {
        memberIndex: 2,
        nickName: 'test',
        eMail: 'joy@anotherworld.game',
        phone: '010-1234-5678'
    }
];

const MemberListForItemPage: React.FC = () => {
    const [searchWord, setSearchWord] = useState<string>('');

    return (
        <div>
            <PageTitle>Item Manage</PageTitle>
            <Title>아이템 관리</Title>
            <SubTitle>( 클릭하여, 아이템을 확인해주세요 )</SubTitle>
            <Card>
                <SearchInput
                    prefix="검색"
                    placeholder="닉네임 / 핸드폰번호 / 이메일"
                    value={searchWord}
                    onChange={setSearchWord}
                    onClick={() => {
                        getMemberListWithEmail(searchWord);
                    }}
                />
                <MemberListForItemTable members={members} />
            </Card>
        </div>
    );
};
export default MemberListForItemPage;
