import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { dateToUnixTimeBySeconds } from '@app/utils/utils';
import {
    itemIdToString,
    rankToString,
    distributionReasonToString
} from '@app/api/pageApi/member.api';
import { ONE_HOUR } from '@app/consts/consts';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const GroupDistributionPage: React.FC = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState<number>(dateToUnixTimeBySeconds(new Date()));
    const [type, setType] = useState<number>(0);
    const [reason, setReason] = useState<number>(0);
    const [itemId, setItemId] = useState<number>(0);
    const [rank, setRank] = useState<number>(0);
    const [isMessage, setIsMessage] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);

    const typeMenu = [
        {
            key: 1,
            label: '개별지급',
            onClick: () => {
                setType(1);
            }
        },
        {
            key: 2,
            label: '일괄지급',
            onClick: () => {
                setType(2);
            }
        }
    ];

    const reasonMenu = [
        {
            key: 1,
            label: '사유1',
            onClick: () => {
                setReason(1);
            }
        },
        {
            key: 2,
            label: '사유2',
            onClick: () => {
                setReason(2);
            }
        },
        {
            key: 3,
            label: '사유3',
            onClick: () => {
                setReason(3);
            }
        }
    ];

    const isMessageMenu = [
        {
            key: 1,
            label: '있음',
            onClick: () => {
                setIsMessage(true);
            }
        },
        {
            key: 2,
            label: '없음',
            onClick: () => {
                setIsMessage(false);
            }
        }
    ];

    const itemIdMenu = [
        {
            key: 1,
            label: '아이템1',
            onClick: () => {
                setItemId(1);
            }
        },
        {
            key: 2,
            label: '아이템2',
            onClick: () => {
                setItemId(2);
            }
        }
    ];

    const rankMenu = [
        {
            key: 1,
            label: '등급1',
            onClick: () => {
                setRank(1);
            }
        },
        {
            key: 2,
            label: '등급2',
            onClick: () => {
                setRank(2);
            }
        }
    ];

    return (
        <div>
            <PageTitle>Item Distribution Page</PageTitle>
            <Title>개별 지급/회수</Title>
            <Card
                style={{
                    width: '60%',
                    minWidth: 900
                }}
            >
                {/* 1. 기본정보 입력란 */}
                <InCardTitle>1. 기본 정보</InCardTitle>
                <PrefixInput
                    prefix={`지급 예정 날짜`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDate(Math.floor(new Date(e as unknown as number).getTime() / 1000));
                    }}
                    type="datetime-local"
                    value={new Date((date + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />

                <Dropdown
                    menu={{
                        items: reasonMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="지급 사유">
                        {distributionReasonToString(reason)}
                    </PrefixButton>
                </Dropdown>

                <Dropdown
                    menu={{
                        items: isMessageMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="팝업 메시지">{isMessage ? '있음' : '없음'}</PrefixButton>
                </Dropdown>

                <div style={{ height: 25 }} />

                {/* 2. 상품정보 입력란 */}
                <InCardTitle>2. 지급 아이템</InCardTitle>
                <Dropdown
                    menu={{
                        items: itemIdMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="품목">{itemIdToString(itemId)}</PrefixButton>
                </Dropdown>

                <Dropdown
                    menu={{
                        items: rankMenu
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="등급">{rankToString(rank)}</PrefixButton>
                </Dropdown>

                <PrefixInput
                    prefix={`수량`}
                    onChange={setCount}
                    type={`number`}
                    placeholder={`수량을 입력해주세요`}
                    value={count > 0 ? count : ''}
                />

                {/* 3. 등록하기 버튼 */}
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            width: 120
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick={() => {}}
                    >
                        지급
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default GroupDistributionPage;
