import {
    addItem,
    modifyDurability,
    modifyEnergy,
    modifyRun,
    newShoe
} from '@app/api/pageApi/test.api';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import { Title } from '@app/components/common/Title/Title';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TypeItemKey } from '@app/metaData/enum';
import {
    animalNumberToString,
    typeItemNumberToString,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { useState } from 'react';
import styled from 'styled-components';

const MarginBottom = styled.div`
    margin-bottom: 35px;
`;

const CheatKeyPage = () => {
    const [itemId, setItemId] = useState<number>(0);
    const [itemType, setItemType] = useState<number>(0);
    const [itemCount, setItemCount] = useState<number>(0);
    const [energy, setEnergy] = useState<number>(0);
    const [durability, setDurability] = useState<number>(0);
    const [shoeCount, setShoeCount] = useState<number>(0);
    const [distance, setDistance] = useState<number>(0);
    const [shoeGrade, setShoeGrade] = useState<number>(0);
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);
    const [accountId, setAccountId] = useState<string>('');

    const idItems = () => {
        const items = [];
        if (!typeItem) return;
        for (const key in typeItem) {
            const value = typeItem[key];
            items.push({
                key: value,
                label: typeItemNumberToString(typeItem, value),
                onClick: () => {
                    setItemId(value);
                    setItemType(0);
                }
            });
        }
        return items;
    };

    const typeGradeItems = () => {
        const items = [];
        if (!typeShoeGrade) return;
        for (const key in typeShoeGrade) {
            const value = typeShoeGrade[key];
            items.push({
                key: value,
                label: typeItemNumberToString(typeShoeGrade, value),
                onClick: () => {
                    setItemType(value);
                }
            });
        }
        return items;
    };

    const typeShoeGradeItems = () => {
        const items = [];
        if (!typeShoeGrade) return;
        for (const key in typeShoeGrade) {
            const value = typeShoeGrade[key];
            items.push({
                key: value,
                label: typeItemNumberToString(typeShoeGrade, value),
                onClick: () => {
                    setShoeGrade(value);
                }
            });
        }
        return items;
    };

    const animalBaseItems = () => {
        const items = [];
        if (!animalBase) return;
        for (const key in animalBase) {
            const value = animalBase[key];
            items.push({
                key: value.ID,
                label: animalNumberToString(value.ID),
                onClick: () => {
                    setItemType(value.ID);
                }
            });
        }
        return items;
    };

    // const nftItems = () => {
    //     const items = [];
    //     if (!typeNFT) return;
    //     for (const key in typeNFT) {
    //         const value = typeNFT[key];
    //         items.push({
    //             key: value,
    //             label: typeNFTNumberToString(typeNFT, value),
    //             onClick: () => {
    //                 setOrigin(value);
    //             }
    //         });
    //     }
    //     return items;
    // };

    return (
        <div>
            <Title>치트키 관리</Title>
            <Card style={{ width: '60%' }}>
                {/* 1. 거리 증가 */}
                <InCardTitle>1. 거리 증가</InCardTitle>
                <PrefixInput
                    prefix={`계정`}
                    onChange={setAccountId}
                    type={`text`}
                    value={accountId}
                />
                <PrefixInput
                    prefix={`거리`}
                    onChange={setDistance}
                    type={`number`}
                    value={distance}
                />
                <Button
                    type="primary"
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={() => {
                        modifyRun(accountId, distance);
                    }}
                >
                    적용
                </Button>
                <MarginBottom />

                {/* 2. 아이템 부여 */}
                <InCardTitle>2. 아이템 부여</InCardTitle>
                <PrefixInput
                    prefix={`계정`}
                    onChange={setAccountId}
                    type={`text`}
                    value={accountId}
                />
                <Dropdown
                    menu={{
                        items: idItems()
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="아이템">
                        {typeItemNumberToString(typeItem, itemId)}
                    </PrefixButton>
                </Dropdown>
                {typeItemNumberToString(typeItem, itemId) === TypeItemKey.SHOE ? (
                    <Dropdown menu={{ items: typeGradeItems() }} placement="bottom" arrow>
                        <PrefixButton prefix="등급">
                            {typeShoeGradeNumberToString(typeShoeGrade, itemType)}
                        </PrefixButton>
                    </Dropdown>
                ) : typeItemNumberToString(typeItem, itemId) === TypeItemKey.ANIMAL ? (
                    <Dropdown menu={{ items: animalBaseItems() }} placement="bottom" arrow>
                        <PrefixButton prefix="등급">{animalNumberToString(itemType)}</PrefixButton>
                    </Dropdown>
                ) : null}
                <PrefixInput
                    prefix={`개수`}
                    onChange={setItemCount}
                    type={`number`}
                    value={itemCount}
                />
                <Button
                    type="primary"
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={() => {
                        let incr = 1;
                        if (+itemCount < 0) {
                            incr = 0;
                        }
                        const count = Math.abs(itemCount).toString();
                        addItem(accountId, itemId, itemType, count, incr);
                    }}
                >
                    적용
                </Button>
                <MarginBottom />

                {/* 3. 에너지 변경 */}
                <InCardTitle>3. 에너지 변경</InCardTitle>
                <PrefixInput
                    prefix={`계정`}
                    onChange={setAccountId}
                    type={`text`}
                    value={accountId}
                />
                <PrefixInput
                    prefix={`에너지`}
                    onChange={setEnergy}
                    type={`number`}
                    value={energy}
                />
                <Button
                    type="primary"
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={() => modifyEnergy(accountId, energy)}
                >
                    적용
                </Button>
                <MarginBottom />

                {/* 4. 내구도 조정 */}
                <InCardTitle>4. 내구도 조정</InCardTitle>
                <PrefixInput
                    prefix={`계정`}
                    onChange={setAccountId}
                    type={`text`}
                    value={accountId}
                />
                <PrefixInput
                    prefix={`내구도`}
                    onChange={setDurability}
                    type={`number`}
                    value={durability}
                />
                <Button
                    type="primary"
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={() => modifyDurability(accountId, durability)}
                >
                    적용
                </Button>
                <MarginBottom />

                {/* 5. 기록 변경 */}
                {/* <InCardTitle>5. 기록 변경</InCardTitle>
                <PrefixInput prefix={`거리`} onChange={setEnergy} type={`number`} />
                <Button type="primary" style={{ width: '100%', marginTop: 5 }}>
                    적용
                </Button>
                <MarginBottom /> */}

                {/* 5. 운동화 추가 */}
                <InCardTitle>5. 운동화 추가</InCardTitle>
                <PrefixInput
                    prefix={`계정`}
                    onChange={setAccountId}
                    type={`text`}
                    value={accountId}
                />
                <Dropdown menu={{ items: typeShoeGradeItems() }} placement="bottom" arrow>
                    <PrefixButton prefix="등급">
                        {typeShoeGradeNumberToString(typeShoeGrade, shoeGrade)}
                    </PrefixButton>
                </Dropdown>
                {/* <Dropdown
                    menu={{
                        items: nftItems()
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="오리진">
                        {typeNFTNumberToString(typeNFT, origin)}
                    </PrefixButton>
                </Dropdown> */}
                <PrefixInput
                    prefix={`운동화 수량`}
                    onChange={setShoeCount}
                    type={`number`}
                    value={shoeCount}
                />
                <Button
                    type="primary"
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={() => newShoe(accountId, shoeGrade, shoeCount)}
                >
                    적용
                </Button>
            </Card>
        </div>
    );
};

export default CheatKeyPage;
