import { notificationController } from '@app/controllers/notificationController';
import { confirmAlert } from '@app/api/pageApi/notificationAlert.api';
import { AM_API_BASE_URL, API_KEY, SIGN_API_BASE_URL } from '@app/consts/consts';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { authService } from '@app/firebaseConfig';

type loginParams = {
    accountId: string;
    project: number;
    platform: number;
    os: number;
    email: string;
    accessToken: string;
    isAdmin: boolean;
};

export const loginWithGoogle = async (): Promise<loginParams | (loginParams & void)> => {
    try {
        const provider = new GoogleAuthProvider();
        const data = await signInWithPopup(authService, provider);

        return {
            accountId: data.user.uid,
            project: 1,
            platform: 1,
            os: 1,
            email: data.user.email ? data.user.email : '',
            accessToken: await data.user.getIdToken(),
            isAdmin: true
        };
    } catch (error) {
        notificationController.error({
            message: `로그인에 실패했습니다. : ${error}`
        });
    }
};

export const login = async (): Promise<void | boolean> => {
    try {
        const { accountId, project, platform, os, email, accessToken } = await loginWithGoogle();
        localStorage.setItem('gmEmail', email);
        localStorage.setItem('gmAccountId', accountId);

        const res = await fetch(`${SIGN_API_BASE_URL}/account/login`, {
            method: 'PUT',
            headers: {
                'API-KEY': API_KEY as string,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                accountId,
                project,
                platform,
                os,
                email,
                accessToken
            })
        });

        const token = res.headers.get('Token');

        if (token) {
            localStorage.setItem('token', token);
        }

        const data = await res.json();

        if (data.accountId) {
            localStorage.setItem('gmAccountId', data.accountId);
            return true;
        } else {
            notificationController.error({
                message: '로그인에 실패했습니다.'
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `로그인에 실패했습니다. : ${err.message}`
        });
    }
};

/* Log Out */
export const logOut = async (): Promise<boolean | void> => {
    if (!(await confirmAlert('로그아웃 하시겠습니까?'))) return;
    localStorage.removeItem('token');
    localStorage.removeItem('gmEmail');
    localStorage.removeItem('gmAccountId');
    return true;
};

/* Sign Up */
export const signUp = async (
    email: string,
    password: string,
    code: string
): Promise<void | boolean> => {
    try {
        if (!email.includes('@anotherworld.game')) {
            notificationController.error({
                message: 'anotherworld.game 이메일만 가입이 가능합니다'
            });
            return false;
        }
        if (password.length < 6 || password.length > 12) {
            notificationController.error({
                message: '비밀번호는 6자 이상 12자 이하로 설정해주세요'
            });
            return false;
        }

        await fetch(`${AM_API_BASE_URL}/admin/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, code })
        })
            .then(res => res.json())
            .then(data => {
                switch (data['message']) {
                    case '어드민 가입이 완료되었습니다':
                        notificationController.success({
                            message: data['message']
                        });
                        return true;
                    default:
                        notificationController.error({
                            message: data['message']
                        });
                        return false;
                }
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        notificationController.error({
            message: `회원가입에 실패했습니다. : ${err.message}`
        });
    }
};
