import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, RaffleTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { unixTimeToString } from '@app/utils/utils';
import { isWinningExposeToString } from '@app/api/pageApi/raffle.api';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

const TouchableWrapper = styled.div`
    cursor: pointer;
`;

interface RaffleTableProps {
    raffles: RaffleTableRow[];
    isDraw: boolean;
}

export const RaffleTable = ({ raffles, isDraw }: RaffleTableProps) => {
    const navigate = useNavigate();

    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const clickRow = (record: RaffleTableRow) => {
        if (isDraw) {
            navigate(`/raffle-manage/draw/${record.raffleId}`, {
                state: record
            });
        } else {
            navigate(`/raffle-manage/${record.raffleId}`, {
                state: record
            });
        }
    };

    const columns: ColumnsType<RaffleTableRow> = [
        {
            title: '진행상태',
            dataIndex: 'endTs',
            width: '4%',
            render: (value, record) => {
                if (record.startTs && record.endTs)
                    return (
                        <TouchableWrapper onClick={() => clickRow(record)}>
                            {new Date().getTime() / 1000 > record.endTs
                                ? '완료'
                                : new Date().getTime() / 1000 < record.startTs
                                ? '예정'
                                : '진행중'}
                        </TouchableWrapper>
                    );
            }
        },
        {
            title: '래플명',
            dataIndex: 'title',
            width: '12%',
            render: (value, record) => {
                return (
                    <TouchableWrapper onClick={() => clickRow(record)}>{value}</TouchableWrapper>
                );
            }
        },
        {
            title: '응모시작일',
            dataIndex: 'startTs',
            width: '9%',
            render: (value, record) => {
                return (
                    <TouchableWrapper onClick={() => clickRow(record)}>
                        {unixTimeToString(value)}
                    </TouchableWrapper>
                );
            }
        },
        {
            title: '응모마감일',
            dataIndex: 'endTs',
            width: '9%',
            render: (value, record) => {
                return (
                    <TouchableWrapper onClick={() => clickRow(record)}>
                        {unixTimeToString(value)}
                    </TouchableWrapper>
                );
            }
        },
        {
            title: '노출상태',
            dataIndex: 'isWinningExpose',
            width: '4%',
            render: (value, record) => {
                return (
                    <TouchableWrapper onClick={() => clickRow(record)}>
                        {isWinningExposeToString(value)}
                    </TouchableWrapper>
                );
            }
        },
        {
            title: '작성일',
            dataIndex: 'regTs',
            width: '6%',
            render: (value, record) => {
                return (
                    <TouchableWrapper onClick={() => clickRow(record)}>
                        {unixTimeToString(value)}
                    </TouchableWrapper>
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={raffles}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
