import React, { useEffect, useRef, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useNavigate } from 'react-router-dom';
import { Color } from '@app/styles/themes/light/lightTheme';
import { RaffleTable } from '@app/components/tables/RaffleTable/RaffleTable';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { ADMIN_API_BASE_URL, API_KEY, POWER_OF_EIGHTTEEN } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { expiredAlert } from '@app/api/pageApi/notificationAlert.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { typeItemNumberToString } from '@app/metaData/metaData';
import { TypeItemKey } from '@app/metaData/enum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterButton = ({ menu, child, width }: { menu: any; child: string; width: string }) => {
    return (
        <Dropdown
            menu={{
                items: menu
            }}
        >
            <Button
                style={{
                    width: width,
                    marginRight: 10,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {child}
                </div>
                <FaChevronDown />
            </Button>
        </Dropdown>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SearchButton = styled(FaSearch)`
    color: black;
    size: 48px;
    background-color: ${Color.darkMint};
    padding: 13px;
    width: 75px;
    height: 50px;
    border-radius: 10px;
    color: ${Color.white};
    &:hover {
        cursor: pointer;
        background-color: ${Color.mint};
    }
    &:active {
        background-color: ${Color.darkMint};
    }
`;

const RaffleListPage: React.FC = () => {
    const navigate = useNavigate();
    const [processStatus, setProcessStatus] = useState<number>(0);
    const [startDate, setStartDate] = useState<number>(0);
    const [endDate, setEndDate] = useState<number>(0);
    const [dateOption, setDateOption] = useState<number>(0);
    const [searchWord, setSearchWord] = useState<string>('');
    const [isWinningExpose, setIsWinningExpose] = useState<number>(0);
    const [raffles, setRaffles] = useState<any[]>([]);
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const [awm, setAwm] = useState(0);
    const [usdt, setUsdt] = useState(0);

    useEffect(() => {
        getRaffleList();
    }, []);

    const getRaffleList = async () => {
        try {
            const res = await fetch(`${ADMIN_API_BASE_URL}/admin/raffles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'API-KEY': API_KEY as string,
                    Token: localStorage.getItem('token') as string
                }
            });
            const data = await res.json();

            if (data?.msg === 'Token Expired') {
                await expiredAlert('로그인이 만료되었습니다. 다시 로그인해주세요. ', navigate);
            }

            setRaffles(data);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `래플리스트 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    const clickEnrollOneRaffleButton = () => {
        navigate('/raffle-manage/list/enrollment');
    };

    const clickEnrollMultiRaffleButton = () => {
        navigate('/raffle-manage/list/enrollment/multi');
    };

    const calculateCost = async (list: any) => {
        let awmCost = 0;
        let usdtCost = 0;

        for (const raffle of list) {
            const rewardItems = raffle.rewardItems;
            for (const rewardItem of rewardItems) {
                if (typeItemNumberToString(typeItem, rewardItem.id) === TypeItemKey.AWM) {
                    awmCost = awmCost + rewardItem.count * rewardItem.winningUserCount;
                } else if (
                    typeItemNumberToString(typeItem, rewardItem.id) ===
                    TypeItemKey.KLAYTN_USDT_MONEY
                ) {
                    usdtCost = usdtCost + rewardItem.count * rewardItem.winningUserCount;
                }
            }
        }
        setAwm(600000 - awmCost / POWER_OF_EIGHTTEEN);
        setUsdt(18000 - usdtCost / POWER_OF_EIGHTTEEN);
    };

    useEffect(() => {
        calculateCost(raffles);
    });

    const statusMenu = [
        {
            label: (
                <Row>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        진행상태
                    </div>
                    <FaChevronUp />
                </Row>
            )
        },
        {
            key: 1,
            label: '예정',
            onClick: () => {
                setProcessStatus(1);
            }
        },
        {
            key: 2,
            label: '진행 중',
            onClick: () => {
                setProcessStatus(2);
            }
        },
        {
            key: 3,
            label: '완료',
            onClick: () => {
                setProcessStatus(3);
            }
        }
    ];

    const isWinningExposeMenu = [
        {
            label: (
                <Row>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        노출상태
                    </div>
                    <FaChevronUp />
                </Row>
            )
        },
        {
            key: 1,
            label: '예정',
            onClick: () => {
                setIsWinningExpose(1);
            }
        },
        {
            key: 2,
            label: '진행',
            onClick: () => {
                setIsWinningExpose(2);
            }
        },
        {
            key: 3,
            label: '완료',
            onClick: () => {
                setIsWinningExpose(3);
            }
        }
    ];

    const dateOptionMenu = [
        {
            label: (
                <Row>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        날짜 검색 옵션
                    </div>
                    <FaChevronUp />
                </Row>
            )
        },
        {
            key: 1,
            label: '응모 시작일',
            onClick: () => {
                setDateOption(1);
            }
        },
        {
            key: 2,
            label: '응모 마감일',
            onClick: () => {
                setDateOption(2);
            }
        },
        {
            key: 3,
            label: '추첨일',
            onClick: () => {
                setDateOption(3);
            }
        }
    ];

    const getRaffleListWithFilter = async () => {
        try {
            let url: string;
            if (dateOption === 0) {
                url =
                    searchWord === ''
                        ? `${ADMIN_API_BASE_URL}/admin/raffles?processStatus=${processStatus}`
                        : `${ADMIN_API_BASE_URL}/admin/raffles?processStatus=${processStatus}&searchWord=${searchWord}`;
            } else {
                url =
                    searchWord === ''
                        ? `${ADMIN_API_BASE_URL}/admin/raffles?processStatus=${processStatus}&startDate=${startDate}&endDate=${endDate}&dateOption=${dateOption}`
                        : `${ADMIN_API_BASE_URL}/admin/raffles?processStatus=${processStatus}&startDate=${startDate}&endDate=${endDate}&dateOption=${dateOption}&searchWord=${searchWord}`;
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'API-KEY': API_KEY as string
                }
            });
            const data = await res.json();
            setRaffles(data);
        } catch (err: any) {
            notificationController.error({
                message: `래플리스트 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    return (
        <div>
            <PageTitle>Raffle Manage</PageTitle>
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginBottom: 20
                }}
            >
                <Title>목록</Title>
                <Button
                    type="ghost"
                    style={{ marginLeft: 10, height: 35, backgroundColor: Color.white }}
                    onClick={() => clickEnrollOneRaffleButton()}
                >
                    1건 등록
                </Button>
                <Button
                    type="ghost"
                    style={{ marginLeft: 10, height: 35, backgroundColor: Color.white }}
                    onClick={() => clickEnrollMultiRaffleButton()}
                >
                    2건 이상 등록
                </Button>
                <div
                    style={{
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    (현재 예산 : AWM {awm} / 600000, oUSDT {usdt} / 18000 )
                </div>
            </div>
            <Card>
                {/* <div
                    style={{
                        marginBottom: 20,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <FilterButton
                        menu={statusMenu}
                        child={namedStatusToString(processStatus, '진행상태')}
                        width={`20%`}
                    />
                    <FilterButton
                        menu={isWinningExposeMenu}
                        child={namedStatusToString(isWinningExpose, '노출상태')}
                        width={`20%`}
                    />
                    <FilterButton
                        menu={dateOptionMenu}
                        child={dateOptionToString(dateOption)}
                        width={`20%`}
                    />
                    <DayjsDatePicker.RangePicker
                        style={{ width: '100%', marginRight: 8 }}
                        format="YYYY-MM-DD"
                        onChange={value => {
                            if (!value?.[0] || !value?.[1]) return;

                            const start = getYYYYMMDDBySeconds(new Date(value[0].unix() * 1_000));
                            const end = getYYYYMMDDBySeconds(new Date(value[1].unix() * 1_000));

                            setStartDate(start);
                            setEndDate(end);
                        }}
                    />

                    <Input
                        style={{
                            width: '60%',
                            marginRight: 10
                        }}
                        placeholder="래플명 / 상세설명 입력"
                        value={searchWord}
                        onChange={e => {
                            setSearchWord(e.target.value);
                        }}
                    />
                    <SearchButton
                        onClick={() => {
                            getRaffleListWithFilter();
                        }}
                    />
                </div> */}

                <RaffleTable raffles={raffles} isDraw={false} />
            </Card>
        </div>
    );
};

export default RaffleListPage;
