import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, RaffleOfflineRewardTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
    animalNumberToString,
    typeItemNumberToString,
    typeOfflineRaffleRewardNumberToString,
    typeShoeGradeNumberToString
} from '@app/metaData/metaData';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TypeItemKey } from '@app/metaData/enum';
import { useNavigate } from 'react-router-dom';
import { unixTimeToString } from '@app/utils/utils';
import { Button } from '@app/components/common/buttons/Button/Button';
import { offlineReward } from '@app/api/pageApi/raffle.api';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface RaffleOfflineRewardTableProps {
    raffleOfflineReward: RaffleOfflineRewardTableRow[];
}

export const RaffleOfflineRewardTable = ({
    raffleOfflineReward
}: RaffleOfflineRewardTableProps) => {
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const typeShoeGrade = useAppSelector(state => state.metaData.typeShoeGrade);
    const animalBase = useAppSelector(state => state.metaData.animalBase);
    const typeNFT = useAppSelector(state => state.metaData.typeNFT);
    const typeOfflineRaffleReward = useAppSelector(state => state.metaData.typeOfflineRaffleReward);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();
    const gmAccountId = localStorage.getItem('gmAccountId');

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<RaffleOfflineRewardTableRow> = [
        {
            title: '#',
            dataIndex: 'id',
            width: '5%'
        },
        {
            title: '래플번호',
            dataIndex: 'raffleId',
            width: '10%'
        },
        {
            title: '계정 (Account ID)',
            dataIndex: 'accountId',
            width: '10%'
        },
        {
            title: '아이템',
            dataIndex: 'itemId',
            width: '5%',
            render: (itemId, record) => {
                return typeItemNumberToString(typeItem, itemId);
            }
        },
        {
            title: '세부 타입',
            dataIndex: 'itemType',
            width: '5%',
            render: (itemType, record) => {
                switch (typeItemNumberToString(typeItem, record.itemId as number)) {
                    case TypeItemKey.SHOE:
                        return typeShoeGradeNumberToString(typeShoeGrade, itemType as number);
                    case TypeItemKey.ANIMAL:
                        return animalNumberToString(itemType as number);
                    case TypeItemKey.OFFLINE_RAFFLE_REWARD:
                        return typeOfflineRaffleRewardNumberToString(
                            typeOfflineRaffleReward,
                            record.itemType as number
                        );
                    default:
                        return '-';
                }
            }
        },
        {
            title: '수량',
            dataIndex: 'itemCount',
            width: '5%'
        },
        {
            title: '확인 일자',
            width: '5%',
            render: record => (record.userCheckTs ? unixTimeToString(record.userCheckTs) : '')
        },
        {
            title: '지급 일자',
            width: '5%',
            render: record => (record.rewardTs ? unixTimeToString(record.rewardTs) : '')
        },
        {
            title: '',
            width: '5%',
            render: record => {
                return (
                    <div>
                        <Button
                            onClick={() => {
                                offlineReward(record.id, gmAccountId as string);
                            }}
                        >
                            {' '}
                            지급 완료
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={raffleOfflineReward}
            loading={tableData.loading}
            scroll={{ x: 800 }}
            pagination={false}
        />
    );
};
