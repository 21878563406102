import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, BannerListTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { unixTimeToString } from '@app/utils/utils';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

const TouchableWrapper = styled.div`
    cursor: pointer;
`;

interface BannerListTableProps {
    logs: BannerListTableRow[];
}

export const BannerListTable = ({ logs }: BannerListTableProps) => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<{
        data: unknown;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<BannerListTableRow> = [
        {
            title: '#',
            width: '3%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.bannerId}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.bannerId}
                </TouchableWrapper>
            )
        },
        {
            title: '배너명',
            width: '5%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.id}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.title}
                </TouchableWrapper>
            )
        },
        {
            title: '게시일',
            width: '5%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.bannerId}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.postTs ? unixTimeToString(record.postTs) : '미등록'}
                </TouchableWrapper>
            )
        },
        {
            title: '종료일',
            width: '5%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.bannerId}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.expireTs ? unixTimeToString(record.expireTs) : '미등록'}
                </TouchableWrapper>
            )
        },
        {
            title: '노출옵션',
            width: '5%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.bannerId}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.isOpen > 0 ? '노출' : '비노출'}
                </TouchableWrapper>
            )
        },
        {
            title: '클릭수',
            width: '5%',
            render: record => (
                <TouchableWrapper
                    onClick={() => {
                        navigate(`/banner-manage/list/detail/${record.bannerId}`, {
                            state: {
                                record
                            }
                        });
                    }}
                >
                    {record.click}
                </TouchableWrapper>
            )
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
