import React from 'react';
import { Input, InputProps } from '../Input/Input';
import * as S from './SearchInput.styles';
import { FaSearch } from 'react-icons/fa';

export interface SearchInputProps extends InputProps {
    prefix: React.ReactNode;
    onChange?: any;
    isOpenPrefix?: boolean;
    type?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
    prefix,
    isOpenPrefix = true,
    onClick,
    ...props
}) => (
    <>
        <S.Row>
            <Input
                prefix={<S.Prefix isOpen={isOpenPrefix}>{prefix}</S.Prefix>}
                {...props}
                onChange={e => {
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
                type={props.type}
            />
            <S.SearchIconWrapper onClick={onClick}>
                <FaSearch size="20" />
            </S.SearchIconWrapper>
        </S.Row>
    </>
);
