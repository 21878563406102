import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { Severity } from '@app/interfaces/interfaces';
import * as S from './Button.styles';

export const { Group: ButtonGroup } = AntdButton;

export interface ButtonProps extends AntButtonProps {
    className?: string;
    prefix?: string;
}

export const PrefixButton = React.forwardRef<HTMLElement, ButtonProps>(
    ({ onChange, className, prefix, children, ...props }, ref) => (
        <div
            style={{
                paddingTop: 5,
                paddingBottom: 5
            }}
        >
            <S.Wrapper>
                <S.Prefix>{prefix}</S.Prefix>
                <S.DropdownButton onChange={onChange} ref={ref} className={className} {...props}>
                    {children}
                </S.DropdownButton>
            </S.Wrapper>
        </div>
    )
);
