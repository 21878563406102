import { TypeAnimal } from './enum';

const typeAnimal = {
    1: TypeAnimal.PANDA,
    2: TypeAnimal.POLAR_BEAR,
    3: TypeAnimal.SEA_TURTLE,
    4: TypeAnimal.BENGAL_TIGER,
    5: TypeAnimal.WHALE_SHARK,
    99: TypeAnimal.SHABBY_BOX
};

// 파싱용
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeItemNumberToString = (typeItem: any, itemId: number) => {
    if (itemId === 0) return '아이템';

    const typeItemKey = Object.keys(typeItem).find(key => typeItem[key] === itemId);
    return typeItemKey;
};

export const animalNumberToString = (animalId: number) => {
    if (animalId === 0) return '동물 등급';

    return typeAnimal[animalId as keyof typeof typeAnimal];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeShoeGradeNumberToString = (typeShoeGrade: any, typeShoeGradeId: number) => {
    if (typeShoeGradeId === 0) return '신발 등급';

    const typeShoeGradeKey = Object.keys(typeShoeGrade).find(
        key => typeShoeGrade[key] === typeShoeGradeId
    );
    return typeShoeGradeKey;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeNFTNumberToString = (typeNFT: any, typeNFTId: number) => {
    if (typeNFTId === 0) return '오리진';

    const typeNFTKey = Object.keys(typeNFT).find(key => typeNFT[key] === typeNFTId);
    return typeNFTKey;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeOfflineRaffleRewardNumberToString = (
    typeOfflineRaffleReward: any,
    itemType: number
) => {
    if (itemType === 0) return '아이템';

    const typeOfflineRaffleRewardKey = Object.keys(typeOfflineRaffleReward).find(
        key => typeOfflineRaffleReward[key] === itemType
    );
    return typeOfflineRaffleRewardKey;
};

// 드롭다운용 메뉴
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeItemsMenu = (typeItems: any, handler: any) => {
    const items = [];
    items.push({
        key: 0,
        label: `선택 안 함`,
        onClick: () => {
            handler(0);
        }
    });

    if (!typeItems) return;
    for (const key in typeItems) {
        const value = typeItems[key];

        items.push({
            key: value,
            label: typeItemNumberToString(typeItems, value),
            onClick: () => {
                handler(value);
            }
        });
    }
    return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeShoeGradeItems = (typeShoeGrade: any, handler: any) => {
    const items = [];
    if (!typeShoeGrade) return;
    for (const key in typeShoeGrade) {
        const value = typeShoeGrade[key];
        items.push({
            key: value,
            label: typeItemNumberToString(typeShoeGrade, value),
            onClick: () => {
                handler(value);
            }
        });
    }
    return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const animalBaseItems = (animalBase: any, handler: any) => {
    const items = [];
    if (!animalBase) return;
    for (const key in animalBase) {
        const value = animalBase[key];
        items.push({
            key: value.ID,
            label: animalNumberToString(value.ID),
            onClick: () => {
                handler(value.ID);
            }
        });
    }
    return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nftItems = (typeNFT: any, handler: any) => {
    const items = [];
    if (!typeNFT) return;
    for (const key in typeNFT) {
        const value = typeNFT[key];

        items.push({
            key: value,
            label: typeNFTNumberToString(typeNFT, value),
            onClick: () => {
                handler(value);
            }
        });
    }
    return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeOfflineRaffleRewardItems = (typeOfflineRaffleReward: any, handler: any) => {
    const items = [];
    if (!typeOfflineRaffleReward) return;
    for (const key in typeOfflineRaffleReward) {
        const value = typeOfflineRaffleReward[key];
        items.push({
            key: value,
            label: typeOfflineRaffleRewardNumberToString(typeOfflineRaffleReward, value),
            onClick: () => {
                handler(value);
            }
        });
    }
    return items;
};
