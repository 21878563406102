import { SliderSingleProps } from 'antd';
import * as S from './Clipboard.styles';
import { notificationController } from '@app/controllers/notificationController';

export const Clipboard: React.FC<SliderSingleProps> = props => {
    return (
        <S.Clipboard
            text={props.children as string}
            onCopy={() => notificationController.success({ message: 'Copied' })}
        >
            <div>{props.children}</div>
        </S.Clipboard>
    );
};
