import React, { useEffect, useRef, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from '@app/styles/themes/light/lightTheme';
import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { InCardTitle } from '@app/components/common/Title/Title.styles';
import { RaffleParticipantsTableRow } from '@app/api/table.api';
import { RaffleRewardInfoTable } from '@app/components/tables/RaffleRewardInfoTable/RaffleRewardInfoTable';
import { ADMIN_API_BASE_URL, API_KEY, ONE_HOUR } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { deleteRaffle } from '@app/api/pageApi/raffle.api';
import { RewardInfo, entryItemsProps, entryRewardItemsProps } from './RaffleEnrollment';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { FileUploadInput } from '@app/components/common/inputs/FileUploadInput/FileUploadInput';
import { TextAreaInput } from '@app/components/common/inputs/TextAreaInput/TextAreaInput';
import { RewardEnrollModal } from './RewardEnrollModal';
import { RaffleEntryInfoTable } from '@app/components/tables/RaffleEntryInfoTable/RaffleEntryInfoTable';
import { EntryEnrollModal } from './EntryEnrollModal';
import { errorAlert, expiredAlert } from '@app/api/pageApi/notificationAlert.api';
import { typeItemNumberToString } from '@app/metaData/metaData';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface RaffleInfoProps {
    raffleId?: number;
    title?: string;
    startTs?: number;
    endTs?: number;
}

interface RaffleRowProps {
    prefix: string;
    description: string | number | undefined;
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const FlexEndRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`;

const RowBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
`;

const Prefix = styled.div`
    border: 1px solid ${Color.darkGray};
    margin-right: 5px;
    padding: 10px;
    border-radius: 7px;
    width: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: ${FONT_SIZE.md};
`;

const Description = styled.div`
    border: 1px solid ${Color.gray};
    padding: 10px;
    border-radius: 7px;
    width: 100%;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const RaffleRow = ({ prefix, description }: RaffleRowProps) => {
    return (
        <RowBox>
            <Prefix>{prefix}</Prefix>
            <Description>{description}</Description>
        </RowBox>
    );
};

const RaffleManagePage: React.FC = () => {
    const gmAccountId = localStorage.getItem('gmAccountId');

    const navigate = useNavigate();
    const {
        state: { raffleId }
    } = useLocation();

    const [i, setI] = useState<number>(1);
    const [isViewingParticipants, setIsViewingParticipants] = useState<boolean>(true);
    const [participantsList, setParticipantsList] = useState<RaffleParticipantsTableRow[]>([]);

    const [desc, setDesc] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [startTs, setStartTs] = useState<number>(0);
    const [endTs, setEndTs] = useState<number>(0);
    const [rewardItems, setRewardItems] = useState<RewardInfo[]>([]);
    const [isWinning, setIsWinning] = useState<boolean>(false);
    const [isWinningExpose, setIsWinningExpose] = useState<boolean>(false);
    const imgRef = useRef(null);
    const coverImgRef = useRef(null);
    const [imgUrl, setImgUrl] = useState<string>('');
    const [coverImgUrl, setCoverImgUrl] = useState<string>('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [coverImageFile, setCoverImageFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEntryModalOpen, setIsEntryModalOpen] = useState<boolean>(false);
    const [entryItems, setEntryItems] = useState<entryItemsProps[]>([]);
    const [winningExposeTs, setWinningExposeTs] = useState<number>(0);
    const typeItem = useAppSelector(state => state.metaData.typeItem);
    const [entryRewardItems, setEntryRewardItems] = useState<entryRewardItemsProps[]>([]);

    const clickParticipateManageButton = () => {
        setIsViewingParticipants(true);
    };

    const clickDrawManageButton = () => {
        setIsViewingParticipants(false);
    };

    const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setImageFile(fileList[0]);
            setImgUrl(URL.createObjectURL(fileList[0]));
        }

        if (!fileList || fileList.length === 0) {
            notificationController.error({ message: '이미지 파일이 없습니다.' });
            return;
        }
    };

    const uploadCoverImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (fileList && fileList[0]) {
            setCoverImageFile(fileList[0]);
            setCoverImgUrl(URL.createObjectURL(fileList[0]));
        }

        if (!fileList || fileList.length === 0) {
            notificationController.error({ message: '이미지 파일이 없습니다.' });
            return;
        }
    };

    const getParticipantsInfo = async () => {
        try {
            const res = await fetch(
                `${ADMIN_API_BASE_URL}/admin/raffles/participants-info?raffleId=${raffleId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-KEY': API_KEY as string,
                        Token: localStorage.getItem('token') as string
                    }
                }
            );
            const data = await res.json();

            if (data?.msg === 'Token Expired') {
                localStorage.removeItem('token');
                errorAlert('토큰이 만료되었습니다! ');
            }

            if (!data) {
                return;
            }
            if (data?.msg === 'Token Expired') {
                await expiredAlert('로그인이 만료되었습니다. 다시 로그인해주세요. ', navigate);
            }

            setParticipantsList(data);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `참여자 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    const getRaffleByRaffleId = async (raffleId: string) => {
        try {
            const res = await fetch(
                `${ADMIN_API_BASE_URL}/admin/raffles/detail?raffleId=${raffleId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-KEY': API_KEY as string,
                        Token: localStorage.getItem('token') as string
                    }
                }
            );
            const data = await res.json();

            if (data?.msg === 'Token Expired') {
                localStorage.removeItem('token');
                errorAlert('토큰이 만료되었습니다! ');
            }

            data.desc ? setDesc(data.desc) : null;
            data.title ? setTitle(data.title) : null;
            data.startTs ? setStartTs(data.startTs) : null;
            data.endTs ? setEndTs(data.endTs) : null;
            data.rewardItems ? setRewardItems(data.rewardItems) : null;
            data.isWinning ? setIsWinning(data.isWinning) : null;
            data.isWinningExpose ? setIsWinningExpose(data.isWinningExpose) : null;
            data.rewardItems ? setI(data.rewardItems.length) : null;
            data.img ? setImgUrl(data.img) : null;
            data.coverImg ? setCoverImgUrl(data.coverImg) : null;
            data.entryItems ? setEntryItems(data.entryItems) : null;
            data.entryRewardItems ? setEntryRewardItems(data.entryRewardItems) : null;
            data.winningExposeTs ? setWinningExposeTs(data.winningExposeTs) : null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `참여자 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };
    const initIndex = () => {
        if (i !== 1) {
            setI(1);
        }
    };
    //
    const raiseIndex = () => {
        if (i < 4) {
            setI(i + 1);
        } else {
            return notificationController.error({
                message: '상품 등록은 최대 4개까지 가능합니다.'
            });
        }
    };

    const lowerIndex = () => {
        if (i > 1) {
            setI(i - 1);
        } else {
            return notificationController.error({
                message: '상품은 최소 1개 이상이어야 합니다.'
            });
        }
    };

    const onClickCancel = () => {
        setIsModalOpen(false);
        if (rewardItems.length < 1) initIndex();
    };

    const onClickEntryCancel = () => {
        setIsEntryModalOpen(false);
        if (entryItems.length < 1) initIndex();
    };

    useEffect(() => {
        handleGet(raffleId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGet = async (raffleId: string) => {
        await getParticipantsInfo();
        getRaffleByRaffleId(raffleId);
    };

    const onClickRewardInfoSave = (rewardsList: RewardInfo[]) => {
        setIsModalOpen(false);
        setRewardItems(rewardsList);
    };

    const onClickEntryInfoSave = (entryList: entryItemsProps[]) => {
        setIsEntryModalOpen(false);
        setEntryItems(entryList);
    };

    return (
        <div>
            <Title>참여﹒추첨 관리</Title>
            <Card>
                <InCardTitle>1. 기본 정보</InCardTitle>
                <PrefixInput
                    prefix={`래플명`}
                    type={`text`}
                    placeholder={`래플명을 입력해주세요`}
                    value={title}
                />
                <PrefixInput
                    prefix={`응모 시작`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type={`datetime-local`}
                    value={new Date((startTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, 16)}
                />
                <PrefixInput
                    prefix={`응모 마감`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type={`datetime-local`}
                    value={new Date((endTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, 16)}
                />
                <PrefixInput
                    prefix={`당첨 발표`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type={`datetime-local`}
                    value={new Date((winningExposeTs + 9 * ONE_HOUR) * 1000)
                        .toISOString()
                        .slice(0, 16)}
                />

                <div style={{ height: 25 }} />

                {/* 2. 상품정보 입력란 */}
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}
                >
                    <InCardTitle>2. 상품 정보</InCardTitle>
                    <Button
                        style={{
                            height: 40,
                            display: isModalOpen ? 'none' : 'block'
                        }}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        수정하기
                    </Button>
                </div>
                <div style={{ display: isModalOpen ? 'none' : 'block' }}>
                    <PrefixInput
                        prefix={`낙첨자 상품`}
                        value={`${
                            typeItemNumberToString(typeItem, entryRewardItems[0]?.id as number)
                                ? typeItemNumberToString(
                                      typeItem,
                                      entryRewardItems[0]?.id as number
                                  )
                                : ''
                        } ${entryRewardItems[0]?.count ? entryRewardItems[0]?.count : ''}`}
                    />
                    <RaffleRewardInfoTable rewardItems={rewardItems} />
                </div>

                {/* 2.2 상품 정보 등록 모달창 */}
                <RewardEnrollModal
                    isModalOpen={isModalOpen}
                    index={i}
                    onClickCancel={() => onClickCancel()}
                    onClickAddPart={() => raiseIndex()}
                    deletePart={() => lowerIndex()}
                    onClickSave={rewardsList => onClickRewardInfoSave(rewardsList)}
                    isEditing={rewardItems.length > 0 ? true : false}
                    rewardItems={rewardItems}
                />

                <div style={{ height: 25 }} />

                {/* 3. 래플 이미지 */}
                <InCardTitle>3. 래플 이미지</InCardTitle>
                <SubTitle>( * 미등록 시, 이미지가 존재하지 않은 상태로 앱에 노출됩니다 )</SubTitle>
                <FileUploadInput ref={imgRef} url={imgUrl} onChange={uploadImage} />
                <FileUploadInput ref={coverImgRef} url={coverImgUrl} onChange={uploadCoverImage} />
                <div style={{ height: 25, borderRadius: 40 }} />

                {/* 4. 상품 설명 */}
                <InCardTitle>4. 상품 설명</InCardTitle>
                <TextAreaInput value={desc} />

                <div style={{ height: 25 }} />

                {/* 5. 응모 아이템 */}
                <InCardTitle>5. 응모 아이템</InCardTitle>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10
                    }}
                >
                    <SubTitle>( * 응모 아이템은 래플 응모 시, 사용되는 아이템입니다 )</SubTitle>
                    <Button
                        style={{
                            height: 40,
                            display: isEntryModalOpen ? 'none' : 'block'
                        }}
                        onClick={() => setIsEntryModalOpen(true)}
                    >
                        {entryItems.length > 0 ? `수정하기` : `등록하기`}
                    </Button>
                </div>

                <div style={{ display: isEntryModalOpen ? 'none' : 'block' }}>
                    <RaffleEntryInfoTable entryItems={entryItems} />
                </div>
                <EntryEnrollModal
                    isModalOpen={isEntryModalOpen}
                    index={i}
                    onClickCancel={() => onClickEntryCancel()}
                    onClickAddPart={() => raiseIndex()}
                    deletePart={() => lowerIndex()}
                    onClickSave={entryList => onClickEntryInfoSave(entryList)}
                    isEditing={entryItems.length > 0 ? true : false}
                    entryItems={entryItems}
                />
                <div style={{ height: 25 }} />

                {/* 6.하단 버튼 */}
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <FlexEndRow>
                        {/* <Button
                            type="ghost"
                            style={{
                                width: 120,
                                marginRight: 5
                            }}
                            disabled={startTs < Math.floor(new Date().getTime() / 1000)}
                        >
                            수정
                        </Button> */}
                        <Button
                            type="ghost"
                            style={{
                                width: 120,
                                marginRight: 5
                            }}
                            onClick={async () => {
                                if (await deleteRaffle(gmAccountId as string, raffleId))
                                    navigate(-1);
                            }}
                            disabled={startTs < Math.floor(new Date().getTime() / 1000)}
                        >
                            삭제
                        </Button>
                    </FlexEndRow>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default RaffleManagePage;
