import { Table } from 'components/common/Table/Table';
import { Pagination, getTableData, RaffleParticipantsTableRow } from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface RaffleParticipantsTableProps {
    raffleParticipants: RaffleParticipantsTableRow[];
}

export const RaffleParticipantsTable = ({ raffleParticipants }: RaffleParticipantsTableProps) => {
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<RaffleParticipantsTableRow> = [
        {
            title: '닉네임',
            dataIndex: 'username',
            width: '10%'
        },
        {
            title: '핸드폰 번호',
            dataIndex: 'mobile',
            width: '10%'
        },
        {
            title: '계정',
            dataIndex: 'email',
            width: '10%'
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={raffleParticipants}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
