import React from 'react';
import { InputProps } from '../Input/Input';
import * as S from './FileUploadInput.styles';
import { InputRef } from 'antd';
import { Button } from '../../buttons/Button/Button';
import styled from 'styled-components';

export interface FileUploadInputProps extends InputProps {
    onClick?: any;
    onChange?: any;
    ref?: any;
    title?: string;
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ExcelFileUploadInput = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { onClick, onChange, title, ...props }: any,
    ref: React.Ref<InputRef> | undefined
) => {
    return (
        <>
            <Row>
                <S.FileUploadInput type="file" onChange={onChange} ref={ref} {...props} />
                <Button type="ghost" onClick={onClick} style={{ marginLeft: 10, height: 55 }}>
                    {title}
                </Button>
            </Row>
        </>
    );
};
