import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
    animalBase?: any[];
    itemShop?: any[];
    runRewardGroup?: any[];
    seed?: any[];
    shoeBase?: any[];
    shoeConvertNFT?: any[];
    shoeLevelUp?: any[];
    typeGlobalSetting?: any[];
    typeItem?: any[];
    typeNFT?: any[];
    typeShoeGrade?: any[];
    userInit?: any[];
    typeOfflineRaffleReward?: any[];
};

const initialState: InitialState = {
    animalBase: [],
    itemShop: [],
    runRewardGroup: [],
    seed: [],
    shoeBase: [],
    shoeConvertNFT: [],
    shoeLevelUp: [],
    typeGlobalSetting: [],
    typeItem: [],
    typeNFT: [],
    typeShoeGrade: [],
    userInit: [],
    typeOfflineRaffleReward: []
};

export const metaDataSlice = createSlice({
    name: 'metaData',
    initialState,
    reducers: {
        setAnimalBase: (state, action) => {
            state.animalBase = action.payload;
        },
        setItemShop: (state, action) => {
            state.itemShop = action.payload;
        },
        setRunRewardGroup: (state, action) => {
            state.runRewardGroup = action.payload;
        },
        setSeed: (state, action) => {
            state.seed = action.payload;
        },
        setShoeBase: (state, action) => {
            state.shoeBase = action.payload;
        },
        setShoeConvertNFT: (state, action) => {
            state.shoeConvertNFT = action.payload;
        },
        setShoeLevelUp: (state, action) => {
            state.shoeLevelUp = action.payload;
        },
        setTypeGlobalSetting: (state, action) => {
            state.typeGlobalSetting = action.payload;
        },
        setTypeItem: (state, action) => {
            state.typeItem = action.payload;
        },
        setTypeNFT: (state, action) => {
            state.typeNFT = action.payload;
        },
        setTypeShoeGrade: (state, action) => {
            state.typeShoeGrade = action.payload;
        },
        setUserInit: (state, action) => {
            state.userInit = action.payload;
        },
        setTypeOfflineRaffleReward: (state, action) => {
            state.typeOfflineRaffleReward = action.payload;
        }
    }
});

export const {
    setAnimalBase,
    setItemShop,
    setRunRewardGroup,
    setSeed,
    setShoeBase,
    setShoeConvertNFT,
    setShoeLevelUp,
    setTypeGlobalSetting,
    setTypeItem,
    setTypeNFT,
    setTypeShoeGrade,
    setUserInit,
    setTypeOfflineRaffleReward: setOfflineRaffleReward
} = metaDataSlice.actions;

export default metaDataSlice.reducer;
