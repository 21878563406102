import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { login } from '@app/api/pageApi/sign.api';

interface LoginFormData {
    email: string;
    password: string;
    rememberMe?: boolean;
}

export const LoginForm: React.FC = () => {
    const initValues: LoginFormData = {
        email: localStorage.getItem('email') || '',
        password: localStorage.getItem('password') || ''
    };

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (values: LoginFormData) => {
        if (values.rememberMe) {
            localStorage.setItem('email', values.email);
        } else {
            localStorage.removeItem('email');
        }

        setLoading(true);
        const result = await login();
        setLoading(false);

        if (result) navigate('/');
    };

    return (
        <Auth.FormWrapper>
            <BaseForm
                layout="vertical"
                onFinish={values => handleSubmit(values)}
                requiredMark="optional"
                initialValues={initValues}
            >
                <Auth.FormTitle>{'Log in'}</Auth.FormTitle>
                <S.LoginDescription>
                    {'버튼을 눌러 anotherworld 계정으로 로그인합니다!'}
                </S.LoginDescription>

                <BaseForm.Item noStyle>
                    <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                        {'Log in'}
                    </Auth.SubmitButton>
                </BaseForm.Item>
            </BaseForm>
        </Auth.FormWrapper>
    );
};
