import { Table } from 'components/common/Table/Table';
import {
    Pagination,
    getTableData,
    PriorityManageTableRow,
    BannerListTableRow
} from 'api/table.api';
import { useMounted } from '@app/hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { unixTimeToString } from '@app/utils/utils';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';

const initialPagination: Pagination = {
    current: 1,
    pageSize: 20
};

interface PriorityManageTableProps {
    logs: PriorityManageTableRow[];
    isEdit: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFirstPriority: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSecondPriority: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setThirdPriority: any;
    firstPriority: string;
    secondPriority: string;
    thirdPriority: string;
}

export const PriorityManageTable = ({
    logs,
    isEdit,
    setFirstPriority,
    setSecondPriority,
    setThirdPriority,
    firstPriority,
    secondPriority,
    thirdPriority
}: PriorityManageTableProps) => {
    const [tableData, setTableData] = useState<{
        data: any;
        pagination: Pagination;
        loading: boolean;
    }>({
        data: [],
        pagination: initialPagination,
        loading: false
    });
    const { isMounted } = useMounted();

    const fetch = useCallback(
        (pagination: Pagination) => {
            setTableData(tableData => ({ ...tableData, loading: true }));
            getTableData(pagination).then(res => {
                if (isMounted.current) {
                    setTableData({ data: res.data, pagination: res.pagination, loading: false });
                }
            });
        },
        [isMounted]
    );

    useEffect(() => {
        fetch(initialPagination);
    }, [fetch]);

    const columns: ColumnsType<BannerListTableRow> = [
        {
            title: '#',
            dataIndex: 'bannerId',
            width: '3%'
        },
        {
            title: '배너명',
            dataIndex: 'title',
            width: '5%'
        },
        {
            title: '게시 시작일',
            dataIndex: 'postTs',
            width: '4%',
            render: value => {
                return value ? unixTimeToString(value) : '미등록';
            }
        },
        {
            title: '게시 종료일',
            dataIndex: 'expireTs',
            width: '4%',
            render: value => {
                return value ? unixTimeToString(value) : '미등록';
            }
        },
        {
            title: '노출상태',
            dataIndex: 'isOpen',
            width: `3%`,
            render: value => {
                return value > 0 ? '노출' : '비노출';
            }
        },
        {
            title: '우선순위',
            dataIndex: 'priority',
            width: isEdit ? `7%` : `4%`,
            render: (value, record) => {
                return isEdit ? (
                    <div style={{ display: 'flex', justifyContent: 'row' }}>
                        <Checkbox
                            onChange={e => {
                                if (e.target.checked) {
                                    setFirstPriority(record.bannerId);
                                } else {
                                    setFirstPriority('');
                                }
                            }}
                            checked={record.bannerId === firstPriority ? true : false}
                        >
                            1순위
                        </Checkbox>
                        <Checkbox
                            onChange={e => {
                                if (e.target.checked) {
                                    setSecondPriority(record.bannerId);
                                } else {
                                    setSecondPriority('');
                                }
                            }}
                            checked={record.bannerId === secondPriority ? true : false}
                        >
                            2순위
                        </Checkbox>
                        <Checkbox
                            onChange={e => {
                                if (e.target.checked) {
                                    setThirdPriority(record.bannerId);
                                } else {
                                    setThirdPriority('');
                                }
                            }}
                            checked={record.bannerId === thirdPriority ? true : false}
                        >
                            3순위
                        </Checkbox>
                    </div>
                ) : value > 0 ? (
                    `${value}위`
                ) : (
                    ``
                );
            }
        }
    ];

    return (
        <Table
            columns={columns}
            dataSource={logs}
            loading={tableData.loading}
            scroll={{ x: 800 }}
        />
    );
};
