import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Title } from '@app/components/common/Title/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from '@app/styles/themes/light/lightTheme';
import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { InCardTitle } from '@app/components/common/Title/Title.styles';
import { RaffleParticipantsTable } from '@app/components/tables/RaffleParticipantsTable/RaffleParticipantsTable';
import { RaffleRewardInfoTable } from '@app/components/tables/RaffleRewardInfoTable/RaffleRewardInfoTable';
import { unixTimeToString } from '@app/utils/utils';
import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { notificationController } from '@app/controllers/notificationController';
import { draw, result, resultOpen } from '@app/api/pageApi/raffle.api';
import { RewardInfo } from './RaffleEnrollment';
import { errorAlert, successAlert } from '@app/api/pageApi/notificationAlert.api';

interface RaffleInfoProps {
    raffleId?: number;
    title?: string;
    startTs?: number;
    endTs?: number;
    winningExposeTs: number;
}

interface RaffleRowProps {
    prefix: string;
    description: string | number | undefined;
}

interface WinnersProps {
    rank: string;
    accountId: string;
    email: string;
    mobile: string;
    username: string;
}

interface ParticipantsListProps {
    accountId: string;
    email: string;
    mobile: string;
    username: string;
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const FlexEndRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`;

const RowBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 5px;
`;

const Prefix = styled.div`
    border: 1px solid ${Color.darkGray};
    margin-right: 5px;
    padding: 10px;
    border-radius: 7px;
    width: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    font-size: ${FONT_SIZE.md};
`;

const Description = styled.div`
    border: 1px solid ${Color.gray};
    padding: 10px;
    border-radius: 7px;
    width: 100%;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const ResultBox = styled.div`
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    background-color: ${Color.paleGray};
    width: 100%;
    padding: 20px;
`;

const Rank = styled.div`
    border-radius: 10px;
    display: flex;
    background-color: ${Color.darkMint};
    width: 70px;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${Color.white};
    margin-right: 10px;
`;

const Desc = styled.div`
    border-radius: 10px;
    display: flex;
    background-color: ${Color.white};
    height: 40px;
    align-items: center;
    justify-content: center;
    color: ${Color.black};
    padding: 10px;
`;

const RankBox = styled.div`
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: row;
`;

interface WinnerRowProps {
    winner: WinnersProps;
}

const WinnerRow = ({ winner }: WinnerRowProps) => {
    return (
        <RankBox>
            <Rank>{winner.rank}위</Rank>
            <Desc>
                {winner.username ? winner.username : `___`} / {winner.email ? winner.email : `___`}{' '}
                / {winner.mobile ? winner.mobile : `___`}
            </Desc>
        </RankBox>
    );
};

const RaffleRow = ({ prefix, description }: RaffleRowProps) => {
    return (
        <RowBox>
            <Prefix>{prefix}</Prefix>
            <Description>{description}</Description>
        </RowBox>
    );
};

const RaffleInfo = ({ raffleId, title, startTs, endTs, winningExposeTs }: RaffleInfoProps) => {
    return (
        <div style={{ marginTop: 10 }}>
            <RaffleRow prefix="래플 번호" description={raffleId} />
            <RaffleRow prefix="래플명" description={title} />
            <RaffleRow prefix="응모 시작일" description={unixTimeToString(startTs as number)} />
            <RaffleRow prefix="응모 마감일" description={unixTimeToString(endTs as number)} />
            <RaffleRow
                prefix="당첨 발표일"
                description={unixTimeToString(winningExposeTs as number)}
            />
        </div>
    );
};

const RaffleDrawManagePage: React.FC = () => {
    const navigate = useNavigate();
    const gmAccountId = localStorage.getItem('gmAccountId');
    const {
        state: { raffleId }
    } = useLocation();
    const [i, setI] = useState<number>(1);
    const [isViewingParticipants, setIsViewingParticipants] = useState<boolean>(true);
    const [participantsList, setParticipantsList] = useState<ParticipantsListProps[]>([]);
    const [desc, setDesc] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [startTs, setStartTs] = useState<number>(0);
    const [endTs, setEndTs] = useState<number>(0);
    const [rewardItems, setRewardItems] = useState<RewardInfo[]>([]);
    const [isWinning, setIsWinning] = useState<boolean>(false);
    const [isWinningExpose, setIsWinningExpose] = useState<boolean>(false);
    const [winners, setWinners] = useState<WinnersProps[]>([]);
    const [winningExposeTs, setWinningExposeTs] = useState<number>(0);

    const clickParticipateManageButton = () => {
        setIsViewingParticipants(true);
    };

    const clickDrawManageButton = () => {
        setIsViewingParticipants(false);
    };

    const getParticipantsInfo = async () => {
        try {
            const res = await fetch(
                `${ADMIN_API_BASE_URL}/admin/raffles/participants-info?raffleId=${raffleId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-KEY': API_KEY as string,
                        Token: localStorage.getItem('token') as string
                    }
                }
            );
            const data = await res.json();

            if (data?.msg === 'Token Expired') {
                localStorage.removeItem('token');
                errorAlert('토큰이 만료되었습니다! ');
            }

            setParticipantsList(data);
            return data;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `참여자 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    const getRaffleByRaffleId = async (raffleId: number) => {
        try {
            const res = await fetch(
                `${ADMIN_API_BASE_URL}/admin/raffles/detail?raffleId=${raffleId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-KEY': API_KEY as string,
                        Token: localStorage.getItem('token') as string
                    }
                }
            );
            const data = await res.json();

            if (!data.title) {
                notificationController.error({
                    message: `래플 조회에 실패했습니다.`
                });
                return;
            }
            data.desc ? setDesc(data.desc) : null;
            data.title ? setTitle(data.title) : null;
            data.startTs ? setStartTs(data.startTs) : null;
            data.endTs ? setEndTs(data.endTs) : null;
            data.winningExposeTs ? setWinningExposeTs(data.winningExposeTs) : null;
            data.rewardItems ? setRewardItems(data.rewardItems) : null;
            data.isWinning ? setIsWinning(data.isWinning) : null;
            data.isWinningExpose ? setIsWinningExpose(data.isWinningExpose) : null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({
                message: `래플 조회에 실패했습니다.. : ${err.message}`
            });
        }
    };

    useEffect(() => {
        handleGetFunction();
    }, []);

    const handleGetFunction = async () => {
        const participantsList = await getParticipantsInfo();
        await getRaffleByRaffleId(raffleId);
        const data = await result(raffleId);

        if (data.msg) {
            return;
        }

        if (data.length < 1) return;
        const winnersAccountIds = data.winners;

        if (!winnersAccountIds) return;

        const winnersList = [];
        for (const rankList of winnersAccountIds) {
            for (const accountId of rankList.accountIds) {
                for (const participant of participantsList) {
                    if (participant && participant.accountId === accountId) {
                        const winner: WinnersProps = {
                            rank: rankList.rank,
                            accountId: participant.accountId,
                            email: participant.email,
                            mobile: participant.mobile,
                            username: participant.username
                        };
                        winnersList.push(winner);
                        break;
                    }
                }
            }
        }
        setWinners(winnersList);
    };

    const clickDrawButton = async () => {
        const nowTs = Math.floor(new Date().getTime() / 1000);
        if (nowTs < endTs) {
            notificationController.error({
                message: '아직 추첨 시간이 아닙니다.'
            });
            return;
        }
        const gmAccountId = localStorage.getItem('gmAccountId') || '';
        const data = await draw(gmAccountId, raffleId);
        if (!data || !data?.[0]) {
            notificationController.error({ message: '참여자가 없습니다.' });
            setWinners([]);
            return;
        }
        const winnersAccountIds = data[0].accountIds;
        if (
            !participantsList ||
            data.msg ||
            data.length === 0 ||
            !winnersAccountIds ||
            winnersAccountIds === undefined
        )
            return;
        const winnersList = [];
        for (const winnersAccountId of winnersAccountIds) {
            for (const participant of participantsList) {
                if (participant && participant.accountId === winnersAccountId.accountId) {
                    const winner: WinnersProps = {
                        rank: winnersAccountId.rank,
                        accountId: participant.accountId,
                        email: participant.email,
                        mobile: participant.mobile,
                        username: participant.username
                    };
                    winnersList.push(winner);
                    break;
                }
            }
        }
        setWinners(winnersList);
        successAlert('추첨에 성공하여 새로고침합니다!');
    };

    return (
        <div>
            <Title>참여﹒추첨 관리</Title>
            <Card
                style={{
                    width: '60%'
                }}
            >
                {/* 1. 래플 정보 */}
                <InCardTitle> 래플 정보</InCardTitle>
                <RaffleInfo
                    raffleId={raffleId}
                    title={title}
                    startTs={startTs}
                    endTs={endTs}
                    winningExposeTs={winningExposeTs}
                />

                {/* 2. 참여 / 추첨 관리 버튼 */}
                <Row
                    style={{
                        marginTop: 40
                    }}
                >
                    <Button
                        type={isViewingParticipants ? 'ghost' : 'default'}
                        style={{ marginRight: 5 }}
                        onClick={clickParticipateManageButton}
                    >
                        참여관리
                    </Button>
                    <Button
                        type={isViewingParticipants ? 'default' : 'ghost'}
                        onClick={clickDrawManageButton}
                    >
                        추첨관리
                    </Button>
                </Row>

                {/* 3. 참여자 목록 */}
                <div
                    style={{
                        marginTop: 15,
                        display: isViewingParticipants ? 'block' : 'none'
                    }}
                >
                    <RaffleParticipantsTable raffleParticipants={participantsList} />
                </div>

                {/* 4. 추첨 정보 입력 */}
                <div
                    style={{
                        marginTop: 15,
                        display: isViewingParticipants ? 'none' : 'block'
                    }}
                >
                    <RaffleRewardInfoTable rewardItems={rewardItems} />
                </div>

                {/* 5. 추첨 결과 */}

                <ResultBox style={{ display: winners.length < 1 ? 'none' : 'block' }}>
                    {winners.map(winner => {
                        return <WinnerRow key={i} winner={winner} />;
                    })}
                </ResultBox>

                {/* 7.하단 버튼 */}
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록으로
                    </Button>
                    <FlexEndRow>
                        {/* <Button
                            type="ghost"
                            style={{
                                width: 120,
                                display: isViewingParticipants || !isWinning ? 'none' : 'block',
                                marginRight: 5
                            }}
                            disabled={isWinningExpose}
                        >
                            미리보기
                        </Button> */}
                        {/* <Button
                            type="ghost"
                            style={{
                                width: 120,
                                display: isViewingParticipants || !isWinning ? 'none' : 'block',
                                marginRight: 5
                            }}
                            disabled={isWinningExpose}
                        >
                            수정
                        </Button> */}
                        <Button
                            type="primary"
                            style={{
                                width: 120,
                                display: isViewingParticipants ? 'none' : 'block'
                            }}
                            onClick={() => {
                                isWinning
                                    ? resultOpen(gmAccountId as string, raffleId)
                                    : clickDrawButton();
                            }}
                            disabled={isWinningExpose}
                        >
                            {isWinning ? '결과노출승인' : '추첨하기'}
                        </Button>
                    </FlexEndRow>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default RaffleDrawManagePage;
