export const FIVE_MINUTES = 60 * 5;
export const ONE_HOUR = 60 * 60;
export const ONE_DAY = 60 * 60 * 24;

export const POWER_OF_EIGHTTEEN = 10 ** 18;

export const AM_API_BASE_URL = process.env.REACT_APP_AM_SERVER_HOST;
export const SIGN_API_BASE_URL = process.env.REACT_APP_SIGN_SERVER_HOST;
export const ADMIN_API_BASE_URL = process.env.REACT_APP_ADMIN_SERVER_HOST;
export const LAUNCHER_API_BASE_URL = process.env.REACT_APP_LAUNCHER_SERVER_HOST;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;

export const DATA_MANAGE_BUCKET_NAME = process.env.REACT_APP_DATA_MANAGE_BUCKET_NAME;
export const FOLDER_PATH = process.env.REACT_APP_FOLDER_PATH;

export const IMAGE_BUCKET_NAME = process.env.REACT_APP_IMAGE_BUCKET_NAME;

export const API_KEY = process.env.REACT_APP_API_KEY;
