import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { SubTitle } from '@app/components/common/Title/SubTitle';
import { KickHistoryTable } from '@app/components/tables/KickHistoryTable/KickHistoryTable';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Color } from '@app/styles/themes/light/lightTheme';
import { ADMIN_API_BASE_URL, API_KEY } from '@app/consts/consts';
import { KickSearchOption } from '@app/metaData/enum';
import { kickSearchOptionToString } from '@app/api/pageApi/member.api';
import { notificationController } from '@app/controllers/notificationController';
import { errorAlert } from '@app/api/pageApi/notificationAlert.api';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SearchButton = styled(FaSearch)`
    color: black;
    size: 48px;
    background-color: ${Color.darkMint};
    padding: 13px;
    width: 65px;
    height: 50px;
    border-radius: 10px;
    color: ${Color.white};
    &:hover {
        cursor: pointer;
        background-color: ${Color.mint};
    }
    &:active {
        background-color: ${Color.darkMint};
    }
`;

const FilterButton = ({ menu, child, width }: { menu: any; child: string; width: string }) => {
    return (
        <Dropdown
            menu={{
                items: menu
            }}
        >
            <Button
                style={{
                    width: width,
                    marginRight: 10,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    {child}
                </div>
                <FaChevronDown />
            </Button>
        </Dropdown>
    );
};

const KickManagePage: React.FC = () => {
    const gmAccountId = localStorage.getItem('gmAccountId');
    const navigate = useNavigate();
    const [type, setType] = useState<string>('');
    const [searchWord, setSearchWord] = useState<string>('');
    const [logs, setLogs] = useState<any[]>([]);

    const getKickInfo = async () => {
        if (type === '') {
            notificationController.error({ message: `검색 타입을 선택해주세요` });
            return;
        }
        if (searchWord === '') {
            notificationController.error({ message: `검색어를 입력해주세요` });
            return;
        }

        const encodedType = encodeURIComponent(type);
        const encodedSearchWord = encodeURIComponent(searchWord);
        const url = `${ADMIN_API_BASE_URL}/admin/block?searchWord=${encodedSearchWord}&type=${encodedType}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'API-KEY': API_KEY as string,
                Token: localStorage.getItem('token') as string
            }
        });
        const data = await res.json();

        if (data?.msg === 'Token Expired') {
            localStorage.removeItem('token');
            errorAlert('토큰이 만료되었습니다! ');
        }

        if (!data) {
            notificationController.error({ message: '조건에 맞는 강퇴 내역이 없습니다.' });
            return;
        }

        if (data?.msg) {
            notificationController.error({ message: data.msg });
            return;
        }

        setLogs(data);
    };

    const typeMenu = [
        {
            label: (
                <Row>
                    <div
                        style={{
                            width: '100%'
                        }}
                    >
                        구분 (닉네임, 이메일, 핸드폰번호)
                    </div>
                    <FaChevronUp />
                </Row>
            )
        },
        {
            key: KickSearchOption.EMAIL,
            label: kickSearchOptionToString(KickSearchOption.EMAIL),
            onClick: () => {
                return setType(KickSearchOption.EMAIL);
            }
        },
        {
            key: KickSearchOption.MOBILE,
            label: kickSearchOptionToString(KickSearchOption.MOBILE),
            onClick: () => {
                return setType(KickSearchOption.MOBILE);
            }
        },
        {
            key: KickSearchOption.USER_NAME,
            label: kickSearchOptionToString(KickSearchOption.USER_NAME),
            onClick: () => {
                return setType(KickSearchOption.USER_NAME);
            }
        }
    ];
    return (
        <div>
            <PageTitle>Kick Manage</PageTitle>
            <Title>강퇴</Title>
            <SubTitle>Manage Kick</SubTitle>
            <Card>
                <div
                    style={{
                        marginBottom: 20,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <FilterButton
                        menu={typeMenu}
                        child={kickSearchOptionToString(type)}
                        width={`20%`}
                    />
                    <Input
                        style={{
                            width: '80%',
                            marginRight: 10
                        }}
                        placeholder="닉네임 / 핸드폰번호 / 계정 입력"
                        onChange={e => {
                            setSearchWord(e.target.value);
                        }}
                    />
                    <SearchButton
                        onClick={() => {
                            getKickInfo();
                        }}
                    />
                </div>
                <KickHistoryTable logs={logs} />
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate('/member-manage/kick/individual');
                        }}
                    >
                        개별강퇴
                    </Button>
                    {/* <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate('/member-manage/kick/group');
                        }}
                    >
                        일괄강퇴
                    </Button>
                    <Button
                        type="ghost"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            cancelKickAll(gmAccountId as string);
                        }}
                    >
                        강퇴 일괄 삭제
                    </Button> */}
                </BottomContainer>
            </Card>
        </div>
    );
};
export default KickManagePage;
