import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PrefixInput } from '@app/components/common/inputs/PrefixInput/PrefixInput';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ADMIN_API_BASE_URL, API_KEY, ONE_HOUR } from '@app/consts/consts';
import { useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { deleteBanner, openBanner } from '@app/api/pageApi/banner.api';
import { errorAlert } from '@app/api/pageApi/notificationAlert.api';

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
`;

const BannerDetailPage = () => {
    const { state } = useLocation();
    const { bannerId } = state.record;
    const [title, setTitle] = useState<string>('');
    const [postTs, setPostTs] = useState<number>(0);
    const [expireTs, setExpireTs] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<number>(0);
    const [url, setUrl] = useState<string>('');
    const [img, setImg] = useState<string>();
    const [myPageImg, setMyPageImg] = useState<string>();
    const gmAccountId = localStorage.getItem('gmAccountId');

    const getBannerInfoById = async (bannerId: string) => {
        try {
            const res = await fetch(
                `${ADMIN_API_BASE_URL}/admin/banner/detail?bannerId=${bannerId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-KEY': API_KEY as string,
                        Token: localStorage.getItem('token') as string
                    }
                }
            );
            const data = await res.json();
            if (data?.msg === 'Token Expired') {
                localStorage.removeItem('token');
                errorAlert('토큰이 만료되었습니다! ');
            }
            setTitle(data[0].title);
            setPostTs(data[0].postTs);
            setExpireTs(data[0].expireTs);
            setIsOpen(data[0].isOpen);
            setUrl(data[0].url);
            setImg(data[0].img);
            setMyPageImg(data[0].myPageImg);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            notificationController.error({ message: '배너 상세 조회를 실패했습니다.' });
        }
    };

    useEffect(() => {
        getBannerInfoById(bannerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    return (
        <div>
            <Title>배너 상세 정보</Title>
            <Card
                style={{
                    width: '60%',
                    minWidth: 500
                }}
            >
                <PrefixInput prefix="배너명" type="text" value={title} />
                <PrefixInput
                    prefix="게시 시작일"
                    type="datetime-local"
                    value={new Date((postTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <PrefixInput
                    prefix="게시 종료일"
                    type="datetime-local"
                    value={new Date((expireTs + 9 * ONE_HOUR) * 1000).toISOString().slice(0, -8)}
                />
                <PrefixInput prefix="이동 URL" value={url} />
                <img
                    src={img as string}
                    style={{ width: '100%', marginTop: 5, borderRadius: 10 }}
                />
                <img
                    src={myPageImg as string}
                    style={{ width: '100%', marginTop: 5, borderRadius: 10 }}
                />
                <BottomContainer>
                    <Button
                        type="ghost"
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        목록
                    </Button>
                    <div style={{ display: 'flex', justifyContent: 'row' }}>
                        <Button
                            disabled={isOpen > 0}
                            type="ghost"
                            style={{
                                width: 120,
                                marginRight: 10
                            }}
                            onClick={() => {
                                openBanner(bannerId, gmAccountId as string);
                            }}
                        >
                            노출
                        </Button>
                        <Button
                            type="ghost"
                            style={{
                                width: 120
                            }}
                            onClick={() => {
                                deleteBanner(bannerId, gmAccountId as string, navigate);
                            }}
                        >
                            삭제
                        </Button>
                    </div>
                </BottomContainer>
            </Card>
        </div>
    );
};

export default BannerDetailPage;
