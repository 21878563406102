import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { MemberListTable } from '@app/components/tables/MemberTable/MemberListTable';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { getMemberListWithEmail } from '@app/api/pageApi/member.api';
import { notificationController } from '@app/controllers/notificationController';

const MemberListPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [list, setList] = useState([]);

    const handleGetList = async () => {
        const list = await getMemberListWithEmail(email);
        if (!list || list?.msg) {
            notificationController.error({ message: '검색어를 입력해주세요!' });
            return;
        }
        setList(list);
    };

    return (
        <div>
            <PageTitle>Raffle Manage</PageTitle>
            <Title>목록</Title>
            <Card>
                <SearchInput
                    prefix="검색"
                    placeholder="이메일"
                    value={email}
                    onChange={setEmail}
                    onClick={async () => {
                        handleGetList();
                    }}
                />
                <MemberListTable members={list} />
            </Card>
        </div>
    );
};
export default MemberListPage;
