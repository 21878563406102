import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from '@app/components/common/Card/Card';
import { Title } from '@app/components/common/Title/Title';
import { TextAreaInput } from '@app/components/common/inputs/TextAreaInput/TextAreaInput';
import { InCardTitle } from '@app/components/common/Title/InCardTitle';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';
import { getMaintenanceInfo, maintenance } from '@app/api/pageApi/member.api';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { PrefixButton } from '@app/components/common/buttons/Button/PrefixButton';

const Margin = styled.div`
    height: 10px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;
`;

const MaintenancePage: React.FC = () => {
    const [maintenanceMsg, setMaintenanceMsg] = useState<string>('');
    const [updateWaitMsg, setUpdateWaitMsg] = useState<string>('');
    const [updateAvailableMsg, setUpdateAvailableMsg] = useState<string>('');
    const project = 'AM';
    const [serverType, setServerType] = useState<string>('');
    const [isMaintenance, setIsMaintenance] = useState<number>(-1);

    const handleGetConfigs = async () => {
        const maintenanceInfo = await getMaintenanceInfo();

        if (maintenanceInfo.MaintenanceMsg !== undefined)
            setMaintenanceMsg(maintenanceInfo.MaintenanceMsg);
        if (maintenanceInfo.UpdateWaitMsg !== undefined)
            setUpdateWaitMsg(maintenanceInfo.UpdateWaitMsg);
        if (maintenanceInfo.UpdateAvailableMsg !== undefined)
            setUpdateAvailableMsg(maintenanceInfo.UpdateAvailableMsg);
    };

    useEffect(() => {
        handleGetConfigs();
    }, []);

    const serverTypeItems = [
        {
            key: '',
            label: '서버 타입',
            onClick: () => {
                setServerType('');
            }
        },
        {
            key: 'LIVE',
            label: 'LIVE',
            onClick: () => {
                setServerType('LIVE');
            }
        },
        {
            key: 'DEV',
            label: 'DEV',
            onClick: () => {
                setServerType('DEV');
            }
        },
        {
            key: 'LOCAL',
            label: 'LOCAL',
            onClick: () => {
                setServerType('LOCAL');
            }
        }
    ];

    const isMaintenanceItems = [
        {
            key: '',
            label: '서버점검/해제',
            onClick: () => {
                setIsMaintenance(-1);
            }
        },
        {
            key: '1',
            label: '서버점검',
            onClick: () => {
                setIsMaintenance(1);
            }
        },
        {
            key: '0',
            label: '해제',
            onClick: () => {
                setIsMaintenance(0);
            }
        }
    ];

    const isMaintenanceNumberToString = (isMaintenance: number) => {
        switch (isMaintenance) {
            case -1:
                return '서버점검/해제';
            case 0:
                return '해제';
            case 1:
                return '서버 점검';
            default:
                return '';
        }
    };

    return (
        <div>
            <PageTitle>Raffle Manage</PageTitle>
            <Title>서버 점검</Title>
            <Card>
                <Margin />

                <Dropdown
                    menu={{
                        items: serverTypeItems
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="서버 타입">
                        {serverType === '' ? '서버타입' : serverType}
                    </PrefixButton>
                </Dropdown>
                <Dropdown
                    menu={{
                        items: isMaintenanceItems
                    }}
                    placement="bottom"
                    arrow
                >
                    <PrefixButton prefix="서버점검/해제">
                        {isMaintenanceNumberToString(isMaintenance)}
                    </PrefixButton>
                </Dropdown>

                <div style={{ display: isMaintenance === 1 ? `block` : `none` }}>
                    <Margin />
                    <InCardTitle>1. 서버 점검 메시지</InCardTitle>
                    <TextAreaInput
                        value={maintenanceMsg}
                        onChange={e => setMaintenanceMsg(e.target.value)}
                    />
                    <Margin />
                    <InCardTitle>2. 서버점검 대기 중 메시지</InCardTitle>
                    <TextAreaInput
                        value={updateWaitMsg}
                        onChange={e => setUpdateWaitMsg(e.target.value)}
                    />
                    <Margin />
                    <InCardTitle>3. 업데이트 가능 메시지</InCardTitle>
                    <TextAreaInput
                        value={updateAvailableMsg}
                        onChange={e => setUpdateAvailableMsg(e.target.value)}
                    />
                </div>
                <BottomContainer>
                    <Button
                        type="primary"
                        style={{
                            width: 120
                        }}
                        onClick={() =>
                            maintenance(
                                project,
                                serverType,
                                isMaintenance,
                                maintenanceMsg,
                                updateWaitMsg,
                                updateAvailableMsg
                            )
                        }
                    >
                        적용
                    </Button>
                </BottomContainer>
            </Card>
        </div>
    );
};
export default MaintenancePage;
